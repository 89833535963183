import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import type { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { ClientInfo } from '../../common/interfaces/UserInfo.types';
import ContentBody from '../../components/ContentBody/ContentBody';
import { AuthContext } from '../../common/authContext';
import { ACCOUNT_DETAILS_ACCOUNTS_PAGE } from '../../routing/ROUTES';
import { useNavigate } from 'react-router-dom';

const MENU_OPTIONS = ['My clients', 'All clients'];

const RelationshipManager = () => {
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);
  const { authState, setAuthState, userInfo } = useContext(AuthContext);
  const [clients, setClients] = useState<Array<ClientInfo>>(userInfo.myClients);
  const navigate = useNavigate();

  const handleLoadClient = (clientId: number, clientName: string): void => {
    setAuthState({ ...authState, clientId: clientId, clientName: clientName });
    navigate(ACCOUNT_DETAILS_ACCOUNTS_PAGE);
  };

  const viewClient = (e: any) => {
    const clientData = clients.filter((client) => client.id === parseInt(e.target.id));
    const { id, name } = clientData[0];
    handleLoadClient(id, name);
  };

  const handleChange = (event: SelectChangeEvent) => {
    const nextIndex = +event.target.value;
    if (MENU_OPTIONS[nextIndex] == 'All clients') {
      setClients(userInfo.allClients);
    } else {
      setClients(userInfo.myClients);
    }
    setIndex(nextIndex);
  };

  return (
    <ContentBody>
      <Stack
        spacing={1}
        sx={{
          width: '1100px',
          marginTop: '10px',
          marginLeft: '45px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography
            sx={{
              fontSize: '45px',
              color: 'primary.main'
            }}
          >
            {t('relationshipManager.title', { username: userInfo.userName })}
          </Typography>

          <Typography
            sx={{
              fontSize: '18px',
              color: 'text.primary',
              marginTop: '10px'
            }}
          >
            {t('relationshipManager.message')}
          </Typography>

          <Typography
            sx={{
              fontSize: '18px',
              color: 'text.primary',
              fontWeight: 'bold',
              marginTop: '50px'
            }}
          >
            {t('relationshipManager.viewClientsHeader')}
          </Typography>

          <Select
            labelId="label"
            id="select"
            value={String(index)}
            onChange={handleChange}
            sx={{
              width: '31%',
              height: '40px',
              backgroundColor: 'primary.light',
              borderBlockColor: 'secondary.main',
              marginTop: '10px',
              marginBottom: '50px'
            }}
          >
            {MENU_OPTIONS.map((option, i) => (
              <MenuItem value={i} key={`MI-${i}`}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: '16px',
                      color: 'text.primary',
                      fontWeight: 'bold'
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: '16px',
                      color: 'text.primary',
                      fontWeight: 'bold'
                    }}
                  >
                    {''}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clients.map((row, i) => (
                  <TableRow
                    key={`TR-${i}`}
                    sx={{
                      backgroundColor: '#fdfdfd',
                      padding: '2px',
                      '&:last-child td, &:last-child th': {
                        border: 0
                      }
                    }}
                  >
                    <TableCell
                      key={`TC-${i}`}
                      sx={{
                        fontSize: '16px',
                        color: 'text.primary'
                      }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      key={row.id}
                      sx={{
                        fontSize: '16px',
                        color: 'primary.main'
                      }}
                    >
                      <Typography
                        id={`${row.id}`}
                        onClick={viewClient}
                        sx={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                          textAlign: 'right'
                        }}
                      >
                        {'View'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Stack>
    </ContentBody>
  );
};

export default RelationshipManager;
