import React, { useContext } from 'react';
import PaperSection from '../../../../components/PaperSection/PaperSection';
import { Stack } from '@mui/material';
import LiquidityBreakdownTable from './LiquidityBreakdownTable';
import { ReportContext } from '../../../../common/reportContext';

const LiquidityBreakdown = () => {
  const { report } = useContext(ReportContext);

  return (
    <PaperSection title="Liquidity Breakdown">
      <Stack
        sx={{
          width: '650px'
        }}
      >
        <LiquidityBreakdownTable
          type="All accounts"
          accounts={report.cashBoard.liquidityBreakdown.allAccounts.accounts}
          interest={report.cashBoard.liquidityBreakdown.allAccounts.periodInterest}
          yield={report.cashBoard.liquidityBreakdown.allAccounts.yieldPercent}
          balances={report.cashBoard.liquidityBreakdown.allAccounts.averageBalance}
          funds={report.cashBoard.liquidityBreakdown.allAccounts.percentOffunds}
        />

        <LiquidityBreakdownTable
          type="No Yield- Liquid Dollars"
          accounts={report.cashBoard.liquidityBreakdown.noYieldLiquidDollars.accounts}
          interest={report.cashBoard.liquidityBreakdown.noYieldLiquidDollars.periodInterest}
          yield={report.cashBoard.liquidityBreakdown.noYieldLiquidDollars.yieldPercent}
          balances={report.cashBoard.liquidityBreakdown.noYieldLiquidDollars.averageBalance}
          funds={report.cashBoard.liquidityBreakdown.noYieldLiquidDollars.percentOffunds}
        />

        <LiquidityBreakdownTable
          type="Liquid Dollars"
          accounts={report.cashBoard.liquidityBreakdown.liquidDollars.accounts}
          interest={report.cashBoard.liquidityBreakdown.liquidDollars.periodInterest}
          yield={report.cashBoard.liquidityBreakdown.liquidDollars.yieldPercent}
          balances={report.cashBoard.liquidityBreakdown.liquidDollars.averageBalance}
          funds={report.cashBoard.liquidityBreakdown.liquidDollars.percentOffunds}
        />

        <LiquidityBreakdownTable
          type="Fixed Income"
          accounts={report.cashBoard.liquidityBreakdown.fixedIncome.accounts}
          interest={report.cashBoard.liquidityBreakdown.fixedIncome.periodInterest}
          yield={report.cashBoard.liquidityBreakdown.fixedIncome.yieldPercent}
          balances={report.cashBoard.liquidityBreakdown.fixedIncome.averageBalance}
          funds={report.cashBoard.liquidityBreakdown.fixedIncome.percentOffunds}
        />
      </Stack>
    </PaperSection>
  );
};

export default LiquidityBreakdown;
