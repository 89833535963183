import React, { useContext } from 'react';
import { AuthContext } from '../common/authContext';
import { RouteProps } from './types';

const ClientRoute = ({ children }: RouteProps) => {
  const { authState } = useContext(AuthContext);

  if (authState.elevatedUser) {
    return <div />;
  } else {
    return children;
  }
};

export default ClientRoute;
