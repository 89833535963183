import { getToken } from '../../../auth/authenticator';
import { AuthState } from '../../../common/AuthContext.types';
import axiosClient from '../../axios-api';
import { AccountsInvestment } from './AccountsInvestment.types';
import DEVELOPMENT_FLAGS from '../../../developmentFlags';
import { Investment } from '../../../components/AddInvestmentDrawer';
import responseAccountsInvestments from '../../../mock/accounts/investments/responseAccountsInvestments.json';
import responseAccountNames from '../../../mock/accounts/investments/responseAccountNames.json';
import responseInvestmentTypes from '../../../mock/accounts/investments/responseInvestmentTypes.json';
import { InvestmentType } from './InvestmentType.types';

export const POST_INVESTMENTS_ACCEPTED_STATUS_CODE = 202;

type AccountNames = string[];
type InvestmentTypes = InvestmentType[];

export async function getAccountInvestments(
  authState: AuthState
): Promise<AccountsInvestment[] | {}> {
  if (DEVELOPMENT_FLAGS.isMock === 'true') return responseAccountsInvestments;

  let finalResponse = {};

  const apiUrl = `/investments?c_id=${authState.clientId}`;

  await axiosClient({
    url: apiUrl,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${await getToken()}`
    }
  })
    .then((response) => {
      finalResponse = response.data;
    })
    .catch((error) =>
      console.log('An error has occured when fetching investments - ', error?.message)
    );
  return finalResponse;
}

export async function postInvestments(authState: AuthState, data: Investment[]): Promise<boolean> {
  if (DEVELOPMENT_FLAGS.isMock === 'true')
    return new Promise((resolve) => {
      setTimeout(() => resolve(true), 2000);
    });

  let finalResponse = false;

  const apiUrl = `investments?c_id=${authState.clientId}`;

  await axiosClient({
    url: apiUrl,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getToken()}`
    },
    data
  })
    .then((_) => {
      finalResponse = true;
    })
    .catch((error) =>
      console.log('An error has occured when posting investments - ', error?.message)
    );

  return finalResponse;
}
export async function getAccountNames(userSession: AuthState): Promise<AccountNames | null> {
  if (DEVELOPMENT_FLAGS.isMock === 'true') return responseAccountNames;

  let finalResponse = null;

  const apiUrl = `investments/accounts?c_id=${userSession.clientId}`;

  await axiosClient({
    url: apiUrl,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${await getToken()}`
    }
  })
    .then((response) => {
      finalResponse = response.data;
    })
    .catch((error) =>
      console.log('An error has occured when fetching the list of account names- ', error?.message)
    );

  return finalResponse;
}

export async function getInvestmentTypes(userSession: AuthState): Promise<InvestmentTypes | null> {
  if (DEVELOPMENT_FLAGS.isMock === 'true') return responseInvestmentTypes;

  let finalResponse = null;

  const apiUrl = `investments/types?c_id=${userSession.clientId}`;

  await axiosClient({
    url: apiUrl,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${await getToken()}`
    }
  }).then((response) => {
    finalResponse = response.data;
  });
  return finalResponse;
}
