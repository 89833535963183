import { Box } from '@mui/material';
import React, { ReactElement } from 'react';

interface TPOPaperProps {
  color: string;
  children: ReactElement;
}

const TPOPaper = (props: TPOPaperProps) => {
  return (
    <Box
      sx={{
        backgroundColor: '#ffffff',
        boxShadow: `2px 2px 5px 0px ${props.color}`,
        borderRadius: '2px',
        flexGrow: 1
      }}
    >
      {props.children}
    </Box>
  );
};

export default TPOPaper;
