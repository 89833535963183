import { Typography } from '@mui/material';
import React from 'react';

interface AnalysisSummaryProps {
  body: string;
  heading: string;
}
const AnalysisSummary = (props: AnalysisSummaryProps) => {
  return (
    <section>
      <Typography
        sx={{
          fontSize: '18px',
          color: 'text.primary',
          fontWeight: 550
        }}
      >
        {props.heading}
      </Typography>
      <Typography
        sx={{
          fontSize: '18px',
          color: 'text.primary'
        }}
        gutterBottom
      >
        {props.body}
      </Typography>
    </section>
  );
};

export default AnalysisSummary;
