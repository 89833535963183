import { stripPathParams } from '../utils/routing';

export const ROUTES = {
  AccountDetails: '/account-details/:page?',
  Analyses: '/analyses',
  RelationshipManager: '/relationship-manager',
  ForgottenPassword: '/forgotten-password',
  Profile: '/user',
  SetupPassword: '/forgot-password/:username/:passcode',
  SetupMFA: '/setup-mfa/:username/:password',
  ResetPassword: '/reset-password/:username/:passcode',
  ResetPasswordSuccess: '/reset-password-success',
  Logout: '/'
} as const;

export const routesList = Object.values(ROUTES);

const privateRoutes = [
  ROUTES.AccountDetails,
  ROUTES.RelationshipManager,
  ROUTES.Analyses,
  ROUTES.Profile
] as const;
export const publicRoutes = routesList.filter((route) => !privateRoutes.find((r) => r === route));

export const isRealRoute = (maybeRoute: string): boolean => {
  const stillMaybeRoute = routesList.find(
    (route) => stripPathParams(route) === stripPathParams(maybeRoute)
  );
  return !!stillMaybeRoute;
};

export const isPrivateRoute = (route: string): boolean => {
  return !!privateRoutes.find((r) => stripPathParams(r) === stripPathParams(route));
};

export const ACCOUNT_DETAILS_ACCOUNTS_PAGE = stripPathParams(ROUTES.AccountDetails);
export const ACCOUNT_DETAILS_INVESTMENTS_PAGE = `${stripPathParams(
  ROUTES.AccountDetails
)}/investments`;
export const PROFILE_PAGE = stripPathParams(ROUTES.Profile);
