import React from 'react';
import { IconButton, Stack } from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import TPOTooltip from '../TPOTooltip/TPOTooltip';

interface TPOTableHeaderProps {
  label: string;
  showToolTip: boolean;
  toolTipHeader?: string | null;
  toolTipText?: string | null;
}

const TPOTableHeader = ({
  label,
  showToolTip,
  toolTipHeader,
  toolTipText
}: TPOTableHeaderProps) => {
  return (
    <>
      {!showToolTip && label}
      {showToolTip && (
        <Stack direction="row" alignItems="right" spacing={0}>
          {label}
          <TPOTooltip title={toolTipHeader || ''} content={toolTipText || ''}>
            <IconButton sx={{ color: 'common.white', backgroundColor: 'primary.main' }}>
              <QuestionMarkIcon sx={{ width: '16px', height: '16px' }} />
            </IconButton>
          </TPOTooltip>
        </Stack>
      )}
    </>
  );
};

export default TPOTableHeader;
