import React, { useMemo, useEffect } from 'react';
import Session from './common/Session.types';
import { UserInfo } from './common/interfaces/UserInfo.types';
import { AuthContext, EMPTY_AUTH_STATE, EMPTY_USER_INFO } from './common/authContext';
import { AuthState } from './common/AuthContext.types';
import useStateWithSessionStorage from './common/sessionStorageState';
import './styles/core.scss';
import RouterConfig from './routing/RouterConfig';
import Layout from './components/Layout/Layout';
import './utils/chartjs/setupChartjs';
import { RoutingHistoryContext, useSaveLandingURL } from './common/routingHistoryContext';
import analytics from './utils/analytics/analytics';
import useGoogleAnalytics from './hooks/useGoogleAnalytics';

const App = () => {
  const [authState, setAuthState] = useStateWithSessionStorage<AuthState>(
    'authState',
    EMPTY_AUTH_STATE
  );
  const [session, setSession] = useStateWithSessionStorage('userSession', Session.LoggedOut);
  const [userInfo, setUserInfo] = useStateWithSessionStorage<UserInfo>('userRole', EMPTY_USER_INFO);

  const authContext = useMemo(() => {
    const resetAuthState = () => {
      setAuthState(EMPTY_AUTH_STATE);
    };

    return {
      authState,
      setAuthState,
      resetAuthState,
      userInfo,
      setUserInfo,
      session,
      setSession
    };
  }, [authState, session, setAuthState, setSession, setUserInfo, userInfo]);

  const routingHistory = useSaveLandingURL();
  useGoogleAnalytics();

  useEffect(() => {
    if (session !== Session.LoggedOut) {
      analytics.sendLoginEvent(userInfo);
    }
  }, [session, userInfo]);

  return (
    <AuthContext.Provider value={authContext}>
      <RoutingHistoryContext.Provider value={routingHistory}>
        <Layout>
          <RouterConfig />
        </Layout>
      </RoutingHistoryContext.Provider>
    </AuthContext.Provider>
  );
};

export default App;
