import React, { useState, useContext } from 'react';
import DropdownSummary from '../DropdownSummary/DropdownSummary';
import BaseSummary from '../BaseSummary/BaseSummary';
import Stack from '@mui/material/Stack';
import LiquidityForecast from './LiquidityForecast/LiquidityForecast';
import NetChange from './NetChange/NetChange';
import Variance from './Variance/Variance';
import { useTranslation } from 'react-i18next';
import { CommentsContext } from '../../../common/commentsContext';
import { ReportContext } from '../../../common/reportContext';

const NearTermLiquidity = () => {
  const { t } = useTranslation();

  const { report } = useContext(ReportContext);

  const options = report.nearTermLiquidity.nearTermLiquidity.map((obj) => obj.accountGroup);

  const [index, setIndex] = useState(0);

  const { comments, setComments } = useContext(CommentsContext);

  return (
    <Stack spacing={3}>
      <DropdownSummary
        title="Select account group"
        options={options}
        index={index}
        setIndex={setIndex}
      >
        <BaseSummary
          title={t('cashVestAnalyses.shortTermLiquidity.title')}
          content={t('cashVestAnalyses.shortTermLiquidity.summary')}
          setContent={(content) => {
            const newComments = { ...comments };
            newComments.nearTermLiquidity.summary = content;
            setComments(newComments);
          }}
        />
      </DropdownSummary>
      <LiquidityForecast selectedAccountGroupIndex={index} />
      <NetChange selectedAccountGroupIndex={index} />
      <Variance selectedAccountGroupIndex={index} />
    </Stack>
  );
};

export default NearTermLiquidity;
