import React from 'react';
import { Grid, Select, TextField, Typography, Box, FormHelperText, Tooltip } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { FormInvestment } from '../AddInvestmentDrawer.types';
import type { FormikProps } from 'formik';
import { InvestmentType } from '../../../networking/accounts/investments/InvestmentType.types';

export const ACCOUNT_DROPDOWN_DATA_TESTID = 'ACCOUNT_DROPDOWN_DATA_TESTID';
export const INVESTMENT_TYPE_DROPDOWN_DATA_TESTID = 'INVESTMENT_TYPE_DROPDOWN_DATA_TESTID';
export const PURCHASE_AMOUNT_DATA_TESTID = 'PURCHASE_AMOUNT_DATA_TESTID';
export const INTEREST_RATE_DATA_TESTID = 'INTEREST_RATE_DATA_TESTID';
export const FACE_VALUE_DATA_TESTID = 'FACE_VALUE_DATA_TESTID';
export const PURCHASE_DATE_DATA_TESTID = 'PURCHASE_DATE_DATA_TESTID';
export const MATURITY_DATE_DATA_TESTID = 'MATURITY_DATE_DATA_TESTID';
export const CALL_DATE_DATA_TESTID = 'CALL_DATE_DATA_TESTID';

interface FormLabelProps {
  label: string;
  optional?: boolean;
  tooltipText?: string | null;
}

const FormLabel = ({ label, optional = false, tooltipText = null }: FormLabelProps) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        fontSize: '16px',
        color: 'text.primary',
        paddingTop: '35px',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '5px'
      }}
    >
      <Typography
        display="inline"
        sx={{
          fontWeight: '600',
          marginRight: '10px'
        }}
      >
        {label}
      </Typography>
      {tooltipText && (
        <Tooltip title={tooltipText} color={'primary'} placement="top">
          <HelpIcon />
        </Tooltip>
      )}
      {optional && (
        <Typography display="inline" fontStyle="italic">
          ({t('investments.addInvestment.investmentForm.optional')})
        </Typography>
      )}
    </Box>
  );
};

export const calculateInterestRate = (values: FormInvestment) => {
  if (!values.maturityDate || !values.purchaseDate) return;
  const profit = +values.faceValue - +values.purchaseAmount;
  const maturityMs =
    values.maturityDate.toDate().getTime() - values.purchaseDate.toDate().getTime();
  const maturityDays = Math.round(Math.abs(maturityMs / (1000 * 3600 * 24)));
  const interestRate = (profit * 360 * 100) / maturityDays / +values.purchaseAmount;

  return interestRate.toFixed(6);
};

export type AccountNames = string[];
export type InvestmentTypes = InvestmentType[];
export enum InvestmentGroup {
  ONE_CALCULATE_INTEREST_RATE,
  TWO_DISABLE_FACE_VALUE,
  THREE_DEFAULT
}

interface InvestmentFormProps {
  formik: FormikProps<FormInvestment>;
  accountNames: AccountNames;
  investmentTypes: InvestmentTypes;
  investmentTypesGroup2: number[];
  investmentTypesGroup3: number[];
}

const InvestmentForm = ({
  formik,
  accountNames,
  investmentTypes,
  investmentTypesGroup2,
  investmentTypesGroup3
}: InvestmentFormProps) => {
  const { t } = useTranslation();

  const getInvestmentGroup = (investmentType: number) => {
    if (investmentTypesGroup2.includes(investmentType)) {
      return InvestmentGroup.TWO_DISABLE_FACE_VALUE;
    } else if (investmentTypesGroup3.includes(investmentType)) {
      return InvestmentGroup.THREE_DEFAULT;
    }
    return InvestmentGroup.ONE_CALCULATE_INTEREST_RATE;
  };

  const updateInterestRate = (currentValues: FormInvestment) =>
    formik.setFieldValue('interestRate', calculateInterestRate(currentValues));

  const handleInvestmentTypeChange = (event: SelectChangeEvent<string>) => {
    formik.handleChange(event);
    if (getInvestmentGroup(+event.target.value) === InvestmentGroup.ONE_CALCULATE_INTEREST_RATE)
      updateInterestRate(formik.values);
    if (getInvestmentGroup(+event.target.value) === InvestmentGroup.TWO_DISABLE_FACE_VALUE)
      formik.setFieldValue('faceValue', formik.values.purchaseAmount);
  };

  const handleChangePurchaseAmount = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    formik.handleChange(event);
    if (
      getInvestmentGroup(+formik.values.investmentTypeId) ===
      InvestmentGroup.ONE_CALCULATE_INTEREST_RATE
    )
      updateInterestRate({ ...formik.values, purchaseAmount: event.target.value });
    if (
      getInvestmentGroup(+formik.values.investmentTypeId) === InvestmentGroup.TWO_DISABLE_FACE_VALUE
    )
      formik.setFieldValue('faceValue', event.target.value);
  };

  const handleChangeFaceValue = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    formik.handleChange(event);
    if (
      getInvestmentGroup(+formik.values.investmentTypeId) ===
      InvestmentGroup.ONE_CALCULATE_INTEREST_RATE
    )
      updateInterestRate({ ...formik.values, faceValue: event.target.value });
  };

  const handleChangePurchaseDate = (value: Dayjs | null) => {
    formik.setFieldValue('purchaseDate', value);
    if (
      getInvestmentGroup(+formik.values.investmentTypeId) ===
      InvestmentGroup.ONE_CALCULATE_INTEREST_RATE
    )
      updateInterestRate({ ...formik.values, purchaseDate: value });
  };

  const handleChangeMaturityDate = (value: Dayjs | null) => {
    formik.setFieldValue('maturityDate', value);
    if (
      getInvestmentGroup(+formik.values.investmentTypeId) ===
      InvestmentGroup.ONE_CALCULATE_INTEREST_RATE
    )
      updateInterestRate({ ...formik.values, maturityDate: value });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormLabel
        label={t('investments.addInvestment.investmentForm.account')}
        tooltipText={t('investments.addInvestment.investmentForm.accountTooltip')}
      />
      <Select
        fullWidth
        variant="outlined"
        id="accountNumber"
        name="accountNumber"
        native={true}
        value={formik.values.accountNumber}
        onChange={formik.handleChange}
        error={formik.touched.accountNumber && !!formik.errors.accountNumber}
        inputProps={{ 'data-testid': ACCOUNT_DROPDOWN_DATA_TESTID }}
      >
        {accountNames.map((option, i) => (
          <option value={i} key={`Account${i}`}>
            {option}
          </option>
        ))}
      </Select>
      <FormHelperText sx={{ marginLeft: '14px', color: 'common.error' }}>
        {formik.touched.accountNumber && formik.errors.accountNumber}
      </FormHelperText>
      <FormLabel label={t('investments.addInvestment.investmentForm.investmentType')} />
      <Select
        fullWidth
        variant="outlined"
        id="investmentTypeId"
        name="investmentTypeId"
        native={true}
        value={formik.values.investmentTypeId.toString()}
        onChange={handleInvestmentTypeChange}
        error={formik.touched.investmentTypeId && !!formik.errors.investmentTypeId}
        inputProps={{ 'data-testid': INVESTMENT_TYPE_DROPDOWN_DATA_TESTID }}
      >
        {investmentTypes.map((option, i) => (
          <option value={option.typeId} key={`investmentType${i}`}>
            {option.typeName}
          </option>
        ))}
      </Select>
      <FormHelperText sx={{ marginLeft: '14px', color: 'common.error' }}>
        {formik.touched.investmentTypeId && formik.errors.investmentTypeId}
      </FormHelperText>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <FormLabel label={t('investments.addInvestment.investmentForm.purchaseAmount')} />
          <TextField
            InputProps={{
              startAdornment: '$'
            }}
            inputProps={{ 'data-testid': PURCHASE_AMOUNT_DATA_TESTID }}
            fullWidth
            variant="outlined"
            id="purchaseAmount"
            name="purchaseAmount"
            value={formik.values.purchaseAmount}
            onChange={handleChangePurchaseAmount}
            error={formik.touched.purchaseAmount && !!formik.errors.purchaseAmount}
            helperText={formik.touched.purchaseAmount && formik.errors.purchaseAmount}
          />
        </Grid>
        <Grid item xs={4}>
          <FormLabel
            label={t('investments.addInvestment.investmentForm.interestRate')}
            tooltipText={t('investments.addInvestment.investmentForm.interestRateTooltip')}
          />
          <TextField
            fullWidth
            variant="outlined"
            id="interestRate"
            name="interestRate"
            value={formik.values.interestRate}
            onChange={formik.handleChange}
            disabled={
              getInvestmentGroup(+formik.values.investmentTypeId) ===
              InvestmentGroup.ONE_CALCULATE_INTEREST_RATE
            }
            InputProps={{
              endAdornment: '%'
            }}
            error={formik.touched.interestRate && !!formik.errors.interestRate}
            helperText={formik.touched.interestRate && formik.errors.interestRate}
            inputProps={{ 'data-testid': INTEREST_RATE_DATA_TESTID }}
          />
        </Grid>
      </Grid>
      <FormLabel
        label={t('investments.addInvestment.investmentForm.faceValue')}
        tooltipText={t('investments.addInvestment.investmentForm.faceValueTooltip')}
      />
      <TextField
        fullWidth
        variant="outlined"
        id="faceValue"
        name="faceValue"
        InputProps={{
          startAdornment: '$'
        }}
        value={formik.values.faceValue}
        onChange={handleChangeFaceValue}
        error={formik.touched.faceValue && !!formik.errors.faceValue}
        helperText={formik.touched.faceValue && formik.errors.faceValue}
        disabled={
          getInvestmentGroup(+formik.values.investmentTypeId) ===
          InvestmentGroup.TWO_DISABLE_FACE_VALUE
        }
        inputProps={{ 'data-testid': FACE_VALUE_DATA_TESTID }}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormLabel
              label={t('investments.addInvestment.investmentForm.purchaseDate')}
              tooltipText={t('investments.addInvestment.investmentForm.purchaseDateTooltip')}
            />
            <DatePicker
              value={formik.values.purchaseDate}
              onChange={handleChangePurchaseDate}
              views={['month', 'day', 'year']}
              slotProps={{
                textField: {
                  helperText: formik.touched.purchaseDate && formik.errors.purchaseDate,
                  error: formik.touched.purchaseDate && !!formik.errors.purchaseDate,
                  inputProps: {
                    'data-testid': PURCHASE_DATE_DATA_TESTID
                  }
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel label={t('investments.addInvestment.investmentForm.maturityDate')} />
            <DatePicker
              value={formik.values.maturityDate}
              onChange={handleChangeMaturityDate}
              views={['month', 'day', 'year']}
              slotProps={{
                textField: {
                  error: formik.touched.maturityDate && !!formik.errors.maturityDate,
                  helperText: formik.touched.maturityDate && formik.errors.maturityDate,
                  inputProps: {
                    'data-testid': MATURITY_DATE_DATA_TESTID
                  }
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormLabel
              label={t('investments.addInvestment.investmentForm.callDate')}
              tooltipText={t('investments.addInvestment.investmentForm.callDateTooltip')}
              optional
            />
            <DatePicker
              value={formik.values.callDate}
              onChange={(value) => formik.setFieldValue('callDate', value)}
              views={['month', 'day', 'year']}
              slotProps={{
                textField: {
                  error: formik.touched.callDate && !!formik.errors.callDate,
                  helperText: formik.touched.callDate && formik.errors.callDate,
                  inputProps: {
                    'data-testid': CALL_DATE_DATA_TESTID
                  }
                }
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <FormLabel
              label={t('investments.addInvestment.investmentForm.couponRate')}
              tooltipText={t('investments.addInvestment.investmentForm.couponRateTooltip')}
              optional
            />
            <TextField
              fullWidth
              variant="outlined"
              id="couponRate"
              name="couponRate"
              InputProps={{
                endAdornment: '%'
              }}
              value={formik.values.couponRate}
              onChange={formik.handleChange}
              error={formik.touched.couponRate && !!formik.errors.couponRate}
              helperText={formik.touched.couponRate && formik.errors.couponRate}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>
      <FormLabel label={t('investments.addInvestment.investmentForm.investmentId')} optional />
      <TextField
        fullWidth
        variant="outlined"
        id="investmentId"
        name="investmentId"
        value={formik.values.investmentId}
        onChange={formik.handleChange}
        error={formik.touched.investmentId && !!formik.errors.investmentId}
        helperText={formik.touched.investmentId && formik.errors.investmentId}
      />
      <FormLabel label={t('investments.addInvestment.investmentForm.fund')} optional />
      <TextField
        fullWidth
        variant="outlined"
        id="fund"
        name="fund"
        value={formik.values.fund}
        onChange={formik.handleChange}
        error={formik.touched.fund && !!formik.errors.fund}
        helperText={formik.touched.fund && formik.errors.fund}
      />
    </form>
  );
};

export default InvestmentForm;
