import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography, Tabs, Tab } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import styles from './Components.module.scss';

export const CLOSE_ICON_DATA_TESTID = 'CloseIcon';
export const ADDED_TAB_ICON_DATA_TESTID = 'TabIcon';

export enum Section {
  NEW,
  ADDED
}

export type HandleCloseDrawer = () => void;

interface TabIconProps {
  addedInvestmentsListCount: number;
}

const TabIcon = ({ addedInvestmentsListCount }: TabIconProps) => {
  return (
    <div className={styles.TabIcon} data-testid={ADDED_TAB_ICON_DATA_TESTID}>
      {addedInvestmentsListCount}
    </div>
  );
};

interface NavigationProps {
  onClickCloseIcon: HandleCloseDrawer;
  currentSection: Section;
  handleSectionChange: (_event: React.SyntheticEvent, newValue: number) => void;
  addedInvestmentsListCount: number;
  handleTabOnClick: () => void;
}

const Navigation = ({
  onClickCloseIcon,
  currentSection,
  handleSectionChange,
  addedInvestmentsListCount,
  handleTabOnClick
}: NavigationProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Box className={styles.Header}>
        <CloseIcon
          onClick={onClickCloseIcon}
          className={styles.closeIcon}
          fontSize="large"
          data-testid={CLOSE_ICON_DATA_TESTID}
        />
      </Box>
      <Box className={styles.TitleBox}>
        <Typography variant="h4">{t('investments.addInvestment.navigation.title')}</Typography>
      </Box>
      <Tabs
        value={currentSection}
        onChange={handleSectionChange}
        indicatorColor="primary"
        textColor="inherit"
        variant="fullWidth"
      >
        <Tab
          className={styles.Tab}
          label={t('investments.addInvestment.navigation.newTab')}
          onClick={handleTabOnClick}
        />
        <Tab
          label={t('investments.addInvestment.navigation.addedTab')}
          className={[styles.TabAdded, styles.Tab].join(' ')}
          icon={
            addedInvestmentsListCount === 0 ? undefined : (
              <TabIcon addedInvestmentsListCount={addedInvestmentsListCount} />
            )
          }
          onClick={handleTabOnClick}
        />
      </Tabs>
    </>
  );
};

export default Navigation;
