import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { currencyFormatting, rateFormatting } from '../../../../common/helpers';

interface LiquidityBreakdownTableProps {
  type: string;
  accounts: number;
  interest: number;
  yield: number;
  balances: number;
  funds: number;
}

const LiquidityBreakdownTable = (props: LiquidityBreakdownTableProps) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              fontSize: '20px',
              color: 'text.primary',
              width: '25%',
              fontWeight: 500,
              maxWidth: 137,
              paddingBottom: 0
            }}
          >
            {props.type}
          </TableCell>
          <TableCell
            sx={{
              fontSize: '16px',
              color: 'text.primary',
              paddingBottom: 0
            }}
          >
            Period interest
          </TableCell>
          <TableCell
            sx={{
              fontSize: '16px',
              color: 'text.primary',
              paddingBottom: 0
            }}
          >
            Yield
          </TableCell>
          <TableCell
            sx={{
              fontSize: '16px',
              color: 'text.primary',
              paddingBottom: 0
            }}
          >
            Avg balances
          </TableCell>
          <TableCell
            sx={{
              fontSize: '16px',
              color: 'text.primary',
              paddingBottom: 0
            }}
          >
            % of funds
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow
          sx={{
            '&:last-child td, &:last-child th': {
              border: 0,
              paddingTop: '8px'
            }
          }}
        >
          <TableCell
            sx={{
              fontSize: '16px',
              color: 'text.primary'
            }}
          >
            {`Accounts: ${props.accounts}`}
          </TableCell>
          <TableCell
            sx={{
              fontSize: '16px',
              color: 'text.primary'
            }}
          >
            {currencyFormatting(props.interest)}
          </TableCell>
          <TableCell
            sx={{
              fontSize: '16px',
              color: 'text.primary'
            }}
          >
            {rateFormatting(props.yield * 100, 2)}
          </TableCell>
          <TableCell
            sx={{
              fontSize: '16px',
              color: 'text.primary',
              textAlign: 'right'
            }}
          >
            {currencyFormatting(props.balances)}
          </TableCell>
          <TableCell
            sx={{
              paddingTop: 'none',
              fontSize: '16px',
              color: 'text.primary',
              textAlign: 'right'
            }}
          >
            {rateFormatting(props.funds * 100, 0, 1)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default LiquidityBreakdownTable;
