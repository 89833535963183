import { Container } from '@mui/material';
import React from 'react';

type NoBreakProps = {
  children: React.ReactNode;
};

const NoBreak: Function = (props: NoBreakProps) => {
  // only works when parent component is not a flex component!
  return (
    <Container disableGutters maxWidth={false} sx={{ marginTop: '1.5rem' }}>
      <section className="nobreak">{props.children}</section>
    </Container>
  );
};

export default NoBreak;
