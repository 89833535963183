import { Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import SetupPhoneNumberMFA from './SetupPhoneNumber';
import ValidateMFA from './ValidateMFA';
import ViewState from './ViewState';
import { UserAttributes } from '../../networking/userInfo/User.types';
import { requestOneTimeCode, verifyAttributes } from '../../auth/authenticator';
import ContentBody from '../../components/ContentBody/ContentBody';
import SetupMFASuccess from './SetupMFASuccess';

const SetupMFA = () => {
  const { t } = useTranslation();

  const { username, password } = useParams<{
    username: string;
    password: string;
  }>();
  const [errorMessage, setErrorMessage] = useState('');
  const [viewState, setViewState] = useState(ViewState.SetupPhoneNumber);

  const onPhoneNumberComplete = async (phoneNumber: string): Promise<void> => {
    const attributtes: UserAttributes = { phone_number: phoneNumber };
    const result = await requestOneTimeCode(username as string, password as string, attributtes);
    if (result.isSuccess) {
      setViewState(ViewState.VerifyMFA);
    } else {
      setErrorMessage(t('error.failedPhoneNumber'));
    }
  };

  const onMFAComplete = async (value: string): Promise<void> => {
    const attributtes: UserAttributes = { phone_number: value };
    const result = await verifyAttributes(attributtes);
    if (result.isSuccess) {
      setViewState(ViewState.MFASuccess);
    }
  };

  const onBack = (): void => {
    setViewState(ViewState.SetupPhoneNumber);
  };

  return (
    <ContentBody>
      <Stack
        spacing={1}
        sx={{
          width: '750px',
          marginTop: '10px',
          marginLeft: '45px',
          marginBottom: '120px'
        }}
      >
        <Typography
          sx={{
            fontSize: '45px',
            color: 'primary.main'
          }}
        >
          {t('mfa.title')}
        </Typography>

        <Typography
          sx={{
            fontSize: '18px',
            color: 'text.primary'
          }}
        >
          {t('mfa.message')}
        </Typography>

        {viewState == ViewState.SetupPhoneNumber && (
          <SetupPhoneNumberMFA
            onComplete={onPhoneNumberComplete}
            errorMessage={{ errorMessage, setErrorMessage }}
          />
        )}
        {viewState == ViewState.VerifyMFA && (
          <ValidateMFA onComplete={onMFAComplete} onBack={onBack} />
        )}
        {viewState == ViewState.MFASuccess && <SetupMFASuccess />}
      </Stack>
    </ContentBody>
  );
};

export default SetupMFA;
