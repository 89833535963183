import { Box } from '@mui/material';
import React, { ReactElement } from 'react';
import HeaderDivider from '../../common/header_divider.png';

interface ContentBodyProps {
  children: ReactElement;
}

const ContentBody = (props: ContentBodyProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#ffffff'
      }}
    >
      <Box>
        <img src={HeaderDivider} width="1200px" />
      </Box>
      {props.children}
    </Box>
  );
};

export default ContentBody;
