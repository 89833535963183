import { emptyReport, Report, ReportResponse } from '../Report';
import { ReportV2ToBase, ReportV2 } from './v2/ReportV2';

function getMajorVersion(version: string): number {
  if (version.includes('.')) {
    return +version.split('.')[0] ?? 0;
  } else {
    return +version ?? 0;
  }
}

export function getReportByVersion(reportResponse: ReportResponse): Report {
  //For future, more complex use, use semver npm package to check semantic versioning
  const major = getMajorVersion(reportResponse.metadata.version.toString());
  if (major == 1) {
    return reportResponse.data as Report;
  }
  /*
        Version 2 of the report does not exist yet!!! 
        ReportV2 is created as a placeholder and show-how for future versions. 
        This code is to be removed once new version comes.
    */
  if (major == 2) {
    const report = reportResponse.data as ReportV2;
    return ReportV2ToBase(report);
  }
  return emptyReport;
}
