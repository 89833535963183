import React from 'react';
import { Button } from '@mui/material';
import palette from '../../theme/palette';

interface TPOButtonProps {
  onClick?: () => void;
  children: React.ReactNode[] | React.ReactNode;
  styles?: React.CSSProperties;
  disabled?: boolean;
}

const TPOButton = ({ onClick, children, styles, disabled }: TPOButtonProps) => (
  <Button
    onClick={onClick}
    disabled={disabled}
    sx={{
      fontSize: '16px',
      padding: '16px 17px',
      height: '60px',
      backgroundColor: disabled ? palette.background.default : palette.tertiary.main,
      color: palette.text.secondary,
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: disabled ? palette.common.greyBorder : palette.tertiary.main,
      textTransform: 'none',
      boxShadow: 'none',
      transition: 'linear 0.3s',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      ':hover': {
        backgroundColor: palette.tertiary.main,
        borderColor: palette.tertiary.main,
        opacity: '0.8'
      },
      ...styles
    }}
  >
    {children}
  </Button>
);

export default TPOButton;
