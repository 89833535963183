import React, { useContext } from 'react';
import TPOTable from '../../../../components/TPOTable/TPOTable';
import { ReportContext } from '../../../../common/reportContext';
import { NetChange } from '../../../../networking/analyses/report/NearTermLiquidity.types';
import { currencyFormatting } from '../../../../common/helpers';

interface NetChangeTableProps {
  selectedAccountGroupIndex: number;
}

const NetChangeTable = (props: NetChangeTableProps) => {
  const { report } = useContext(ReportContext);

  const getNetChangeDataPoints = (obj: NetChange) => {
    return [
      new Date(obj.month).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        timeZone: 'UTC'
      }),
      currencyFormatting(obj.positiveCashFlow),
      currencyFormatting(obj.negativeCashFlow),
      currencyFormatting(obj.netMonthlyImpact)
    ];
  };

  const content = report.nearTermLiquidity.nearTermLiquidity[
    props.selectedAccountGroupIndex
  ].netChange.map((obj: NetChange) => getNetChangeDataPoints(obj));

  const headers = [
    { label: 'Month', id: 'Month', showToolTip: false },
    { label: 'Positive Cash flows', id: 'Positive Cash flows', showToolTip: false },
    { label: 'Negative Cash flows', id: 'Negative Cash flows', showToolTip: false },
    { label: 'Net Monthly Impact', id: 'Net Monthly Impact', showToolTip: false }
  ];

  return <TPOTable content={content} center={true} headers={headers} />;
};

export default NetChangeTable;
