import { Stack, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Authentication } from '../../auth/Authentication.types';
import { verifyUserMFA, extractAuthentication } from '../../auth/authenticator';
import { getUserInfo } from '../../networking/userInfo/userInfo';
import { UserInfo } from '../../common/interfaces/UserInfo.types';
import { AuthContext } from '../../common/authContext';

import ViewState from '../SetupMFA/ViewState';
import ValidateMFA from '../SetupMFA/ValidateMFA';
import ContentBody from '../../components/ContentBody/ContentBody';
import UserAuthentication from './UserAuthentication';
import Session from '../../common/Session.types';
import { ACCOUNT_DETAILS_ACCOUNTS_PAGE, ROUTES } from '../../routing/ROUTES';
import { RoutesEnum } from '../../routing/types';
import { useNavigate } from 'react-router-dom';
import { stripPathParams } from '../../utils/routing';
import MFALoginAction, { getMFALoginAction } from '../../utils/login/mfaLoginAction';
import { CognitoUser } from '@aws-amplify/auth';
import { RoutingHistoryContext } from '../../common/routingHistoryContext';

const Login = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [viewState, setViewState] = useState(ViewState.SignIn);
  const { setAuthState, setUserInfo, setSession } = useContext(AuthContext);
  const navigate = useNavigate();
  const { landingURL, isLandingURLValid } = useContext(RoutingHistoryContext);

  const login = (userInfo: UserInfo): void => {
    setUserInfo(userInfo);

    if (userInfo.role == 'Client') {
      setSession(Session.Client);
      navigate(isLandingURLValid() ? (landingURL as RoutesEnum) : ACCOUNT_DETAILS_ACCOUNTS_PAGE);
    } else if (userInfo.role == 'Admin' || userInfo.role == 'User') {
      setSession(Session.RelationshipManager);
      navigate(ROUTES.RelationshipManager);
    }
  };

  const onMFAComplete = async (value: string): Promise<void> => {
    const authentication: Authentication = await verifyUserMFA(user, value);
    if (authentication.isSuccess) {
      try {
        const userInfo = await getUserInfo(authentication.bearerToken);
        setAuthState({
          clientId: userInfo.allClients[0].id,
          clientName: userInfo.allClients[0].name,
          elevatedUser: userInfo.role != 'Client',
          bearerToken: authentication.bearerToken
        });
        login(userInfo);
      } catch (error) {
        console.log('ERROR - Unable to login to cashVest');
      }
    } else {
      console.error('ERROR - Unable to authenticate user: ', authentication.error);
    }
  };

  const completeLogin = async (cognitoUser: CognitoUser): Promise<void> => {
    const authentication = extractAuthentication(cognitoUser);
    if (authentication.isSuccess) {
      try {
        const userInfo = await getUserInfo(authentication.bearerToken);
        setAuthState({
          clientId: userInfo.allClients[0].id,
          clientName: userInfo.allClients[0].name,
          elevatedUser: userInfo.role != 'Client',
          bearerToken: authentication.bearerToken
        });
        login(userInfo);
      } catch (error) {
        console.log('ERROR - Unable to login to cashVest');
      }
    } else {
      console.error('ERROR - Unable to authenticate user: ', authentication.error);
    }
  };

  const onAuthenticationComplete = async (
    cognitoUser: CognitoUser,
    username: string,
    password: string
  ) => {
    setUser(cognitoUser);

    const mfaAction = getMFALoginAction(cognitoUser.challengeName);

    if (mfaAction === MFALoginAction.USE_MFA) {
      setViewState(ViewState.VerifyMFA);
    } else if (mfaAction === MFALoginAction.SETUP_MFA) {
      navigate(`${stripPathParams(ROUTES.SetupMFA)}/${username}/${password}`);
    } else if (mfaAction === MFALoginAction.NOT_AWS_PRODUCTION_ENVIRONMENT_SKIP_MFA) {
      await completeLogin(cognitoUser);
    }
  };

  const handleOnBack = () => {
    setViewState(ViewState.SignIn);
  };

  return (
    <ContentBody>
      <Stack
        spacing={1}
        sx={{
          width: '750px',
          marginTop: '10px',
          marginLeft: '45px',
          marginBottom: '120px'
        }}
      >
        <Typography
          sx={{
            fontSize: '45px',
            color: 'primary.main'
          }}
        >
          {t('login.loginTitle')}
        </Typography>

        <Typography
          sx={{
            fontSize: '18px',
            color: 'text.primary'
          }}
        >
          {t('login.welcomeMessage')}
        </Typography>

        {viewState === ViewState.SignIn && (
          <UserAuthentication onComplete={onAuthenticationComplete} />
        )}
        {viewState === ViewState.VerifyMFA && (
          <ValidateMFA onComplete={onMFAComplete} onBack={handleOnBack} />
        )}
      </Stack>
    </ContentBody>
  );
};

export default Login;
