import { Grid, Stack, Typography, Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SummaryWidget from '../../../../components/SummaryWidget/SummaryWidget';

interface AccountListSummaryProps {
  dateAsOf: string;
  openAccounts: number;
  totalBalance: number;
  maturingInvestments: number;
  maturingInvestmentsTotal: number;
}

const AccountListSummary = (props: AccountListSummaryProps) => {
  const datePublished = new Date(props.dateAsOf).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC'
  });
  const balanceAsOfDate = new Date(props.dateAsOf).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZone: 'UTC'
  });

  const { t } = useTranslation();

  const getMaturingInvestmentsTotal = (maturingInvestmentsTotal: number): string => {
    let total = '0';
    if (maturingInvestmentsTotal != null) {
      total = maturingInvestmentsTotal.toLocaleString('en-US');
    }
    return total;
  };

  return (
    <Box
      sx={{
        padding: '25px',
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridGap: '50px'
      }}
    >
      <Stack
        spacing={1}
        sx={{
          maxWidth: '500px'
        }}
      >
        <Typography
          sx={{
            fontSize: '45px',
            color: 'primary.main'
          }}
        >
          {t('listOfAccounts.accountDetailsTitle')}
        </Typography>

        <Typography
          sx={{
            fontSize: '18px',
            color: 'text.primary'
          }}
        >
          {t('listOfAccounts.dataUpdatedThrough', { date: datePublished })}
        </Typography>

        <Typography
          sx={{
            fontSize: '18px',
            color: 'text.primary'
          }}
        >
          {t('listOfAccounts.accountDetailsSummary')}
        </Typography>
      </Stack>

      <Stack spacing={3}>
        <SummaryWidget
          figure={`${props.openAccounts}`}
          explanation="Total number of open accounts"
          color="rgba(70, 0, 116, 0.3)"
        />
        <SummaryWidget
          figure={`$${props.totalBalance.toLocaleString('en-US')}`}
          explanation={t('listOfAccounts.balanceAsOfDate', { balanceAsOfDate: balanceAsOfDate })}
          color="rgba(238, 185, 107, 0.3)"
        />
        <SummaryWidget
          figure={`${props.maturingInvestments || 0}`}
          explanation={t('listOfAccounts.maturingInvestments', {
            maturingInvestments: getMaturingInvestmentsTotal(props.maturingInvestmentsTotal)
          })}
          color="rgba(255, 85, 69, 0.3)"
        />
      </Stack>
    </Box>
  );
};

export default AccountListSummary;
