import { getToken } from '../../../auth/authenticator';
import { AuthState } from '../../../common/AuthContext.types';
import axiosClient from '../../axios-api';
import { AccountDetails } from './AccountDetails';
import DEVELOPMENT_FLAGS from '../../../developmentFlags';
import mockResponse from '../../../mock/accounts/details/response.json';

export async function getAccountDetails(
  userSession: AuthState,
  bankNames: string[],
  accountTypes: string[],
  groupNames: string[],
  includeClosedAccount: boolean
): Promise<AccountDetails | {}> {
  if (DEVELOPMENT_FLAGS.isMock === 'true') return mockResponse;

  let finalResponse = {};

  const bankNamesQueryParams = bankNames
    .map((bankName) => `bank_names=${encodeURIComponent(bankName)}`)
    .join('&');
  const accountTypesQueryParams = accountTypes
    .map((accountType) => `account_types=${encodeURIComponent(accountType)}`)
    .join('&');

  const groupNamesQueryParams = groupNames
    .map((groupName) => `account_group_ids=${encodeURIComponent(groupName)}`)
    .join('&');

  const apiUrl = `/accounts/details?c_id=${userSession.clientId}&${bankNamesQueryParams}&${accountTypesQueryParams}&${groupNamesQueryParams}&include_closed=${includeClosedAccount}`;

  await axiosClient({
    url: apiUrl,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${await getToken()}`
    }
  })
    .then((response) => {
      finalResponse = response.data;
    })
    .catch((error) =>
      console.log('An error has occured when fetching account details - ', error?.message)
    );

  return finalResponse;
}
