import React, { useState } from 'react';
import { Drawer, Box, Typography, TextField, IconButton } from '@mui/material';
import TPOButton from '../../../components/TPOButton/TPOButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { UserDetails } from '../../../networking/profile/userDetails/UserDetails.types';

interface EditNameDrawerProps {
  isOpen: boolean;
  handleCloseDrawer: () => void;

  updateUserDetails: (userDetails: UserDetails) => Promise<void>;
  onDetailsUpdated: () => void;
  currentUserDetails: UserDetails;
  onSuccess: () => void;
  onError: () => void;
}

const EditNameDrawer: React.FC<EditNameDrawerProps> = ({
  isOpen,
  handleCloseDrawer,
  updateUserDetails,
  onDetailsUpdated,
  currentUserDetails,
  onSuccess,
  onError
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');

  const handleSave = async () => {
    try {
      await updateUserDetails({
        firstName: name || currentUserDetails.firstName,
        lastName: surname || currentUserDetails.lastName,
        phoneNumber: currentUserDetails.phoneNumber
      });
      onSuccess();
      onDetailsUpdated();
    } catch (error) {
      onError();
    } finally {
      handleCloseDrawer();
    }
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleCloseDrawer}
      PaperProps={{
        style: { backgroundColor: 'white' }
      }}
    >
      <IconButton
        onClick={handleCloseDrawer}
        sx={{ position: 'absolute', right: 0, top: 0, padding: '10px', cursor: 'pointer' }}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{ width: 'auto', padding: 4, margin: '50px' }}>
        <Typography
          variant="h4"
          sx={{ textAlign: 'left', marginLeft: '-28px', paddingBottom: '70px' }}
        >
          {t('yourProfile.personalDetails.editName.updateName')}
        </Typography>

        <Typography variant="h6" sx={{ textAlign: 'left', marginBottom: 1 }}>
          {t('yourProfile.personalDetails.editName.newFirstName')}
        </Typography>

        <TextField
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          sx={{ marginBottom: 5 }}
          data-testid="firstNameInput"
        />

        <Typography variant="h6" sx={{ textAlign: 'left', marginBottom: 1 }}>
          {t('yourProfile.personalDetails.editName.newLastName')}
        </Typography>

        <TextField
          variant="outlined"
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
          fullWidth
          sx={{ marginBottom: 7 }}
          data-testid="lastNameInput"
        />

        <TPOButton
          onClick={handleSave}
          styles={{ width: '250px', height: '45px' }}
          data-testid="SAVE_BUTTON_TEST_ID"
          disabled={!name && !surname}
        >
          {t('yourProfile.personalDetails.editName.save')}
        </TPOButton>
      </Box>
    </Drawer>
  );
};

export default EditNameDrawer;
