import React, { useState } from 'react';
import { Box, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ToolTip from '../../../../components/ToolTip/ToolTip';
import LineItemFeesTable from './LineItemFeesTable';
import LineItemFeesChart from './LineItemFeesChart';

interface LineItemFeesProps {
  selectedAccountGroupIndex: number;
}

const LineItemFees = (props: LineItemFeesProps) => {
  const { t } = useTranslation();

  const [index, setIndex] = useState(0);

  const handleChange = (event: SelectChangeEvent) => {
    setIndex(+event.target.value);
  };

  const options = ['Table', 'Chart'];

  return (
    <Stack spacing={2} padding={3}>
      <Box>
        <Typography
          sx={{
            fontSize: '18px',
            color: 'text.primary',
            fontWeight: 650,
            marginBottom: '10px'
          }}
        >
          {t('common.selectView')}
        </Typography>

        <Box>
          <Select
            value={String(index)}
            onChange={handleChange}
            sx={{
              backgroundColor: '#f2f2f2',
              width: '350px',
              height: '40px'
            }}
          >
            {options.map((option, i) => (
              <MenuItem value={i} key={i}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      {index == 0 && (
        <Stack spacing={3}>
          <LineItemFeesTable selectedAccountGroupIndex={props.selectedAccountGroupIndex} />
          <ToolTip
            title={t('cashVestAnalyses.treasuryServices.LineItemFeesTabTitle')}
            content={t('cashVestAnalyses.treasuryServices.LineItemFeesTableToolTipContent')}
            label={t('common.explainTable')}
          />
        </Stack>
      )}
      {index == 1 && (
        <Stack spacing={3}>
          <LineItemFeesChart selectedAccountGroupIndex={props.selectedAccountGroupIndex} />
          <ToolTip
            title={t('cashVestAnalyses.treasuryServices.LineItemFeesTabTitle')}
            content={t('cashVestAnalyses.treasuryServices.LineItemFeesChartToolTipContent')}
            label={t('common.explainChart')}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default LineItemFees;
