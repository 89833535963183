import { Box } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import { ROUTES } from '../../routing/ROUTES';
import HeaderDivider from '../../common/header_divider.png';
import { useNavigate } from 'react-router-dom';
import { ViewState } from './ViewState';
import CheckYourEmail from '../CheckYourEmail/CheckYourEmail';
import EnterYourEmail from './EnterYourEmail';
import { forgotPassword } from '../../auth/authenticator';
import ContentBody from '../../components/ContentBody/ContentBody';

const ForgottenPassword = () => {
  const navigate = useNavigate();

  const [viewState, setViewState] = useState(ViewState.ForgottenPassword);
  const [email, setEmail] = useState('');

  const handleOnComplete = async (value: string) => {
    await forgotPassword(value);
    setEmail(value);
    setViewState(ViewState.CheckYourEmail);
  };

  const handleOnBack = () => navigate(ROUTES.Logout);

  const renderContent = (): ReactElement => {
    if (viewState === ViewState.CheckYourEmail) {
      return <CheckYourEmail email={email}></CheckYourEmail>;
    }
    return <EnterYourEmail onComplete={handleOnComplete} onBack={handleOnBack}></EnterYourEmail>;
  };

  return <ContentBody>{renderContent()}</ContentBody>;
};

export default ForgottenPassword;
