import React, { useContext } from 'react';
import PaperSection from '../../../../components/PaperSection/PaperSection';
import { Stack, Typography } from '@mui/material';
import ToolTip from '../../../../components/ToolTip/ToolTip';
import { useTranslation } from 'react-i18next';
import EditTextField from '../../../../components/EditTextField/EditTextField';
import { CommentsContext } from '../../../../common/commentsContext';
import { NetChange } from '../../../../networking/analyses/report/NearTermLiquidity.types';
import NetChangeTable from './NetChangeTable';
import { currencyFormatting } from '../../../../common/helpers';
import { ReportContext } from '../../../../common/reportContext';

interface NetChangeProps {
  selectedAccountGroupIndex: number;
}

const NetChangeSection = (props: NetChangeProps) => {
  const { t } = useTranslation();

  const { report } = useContext(ReportContext);

  const getNetChangeDataPoints = (obj: NetChange) => {
    return [
      new Date(obj.month).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        timeZone: 'UTC'
      }),
      currencyFormatting(obj.positiveCashFlow),
      currencyFormatting(obj.negativeCashFlow),
      currencyFormatting(obj.netMonthlyImpact)
    ];
  };

  const content = report.nearTermLiquidity.nearTermLiquidity[
    props.selectedAccountGroupIndex
  ].netChange.map((obj: NetChange) => getNetChangeDataPoints(obj));

  const headers = [
    { label: 'Month', showToolTip: false, id: 'month' },
    { label: 'Positive Cash flows', showToolTip: false, id: 'positiveCashFlow' },
    { label: 'Negative Cash flows', showToolTip: false, id: 'negativeCashFlow' },
    { label: 'Net Monthly Impact', showToolTip: false, id: 'netMonthlyImpact' }
  ];

  const { comments, setComments } = useContext(CommentsContext);

  return (
    <PaperSection title={t('cashVestAnalyses.shortTermLiquidity.monthlyNetChangeTitle')}>
      <Stack spacing={2}>
        <Stack spacing={2}>
          <Typography
            sx={{
              color: 'text.primary',
              fontSize: '18px',
              fontWeight: 650
            }}
          >
            {t('common.cashVestAnalysisHeading')}
          </Typography>
          <EditTextField
            text={comments.nearTermLiquidity.net}
            setText={(text) => {
              const newComments = { ...comments };
              newComments.nearTermLiquidity.net = text;
              setComments(newComments);
            }}
          />
        </Stack>

        <NetChangeTable selectedAccountGroupIndex={props.selectedAccountGroupIndex} />

        <ToolTip
          title={t('cashVestAnalyses.shortTermLiquidity.monthlyNetChangeToolTipTitle')}
          content={t('cashVestAnalyses.shortTermLiquidity.monthlyNetChangeToolTipContent')}
          label={t('common.explainTable')}
        />
      </Stack>
    </PaperSection>
  );
};

export default NetChangeSection;
