import axios from 'axios';
import { getToken } from '../../../auth/authenticator';
import axiosClient from '../../axios-api';
import { Comments } from './Comments.types';

export async function getComments(
  clientId: number,
  fileKey: string,
  version: string
): Promise<Comments | {}> {
  if (fileKey == '' || fileKey == undefined) {
    return {};
  }

  let finalResponse = {};

  const apiUrl = `/analysis/file?c_id=${clientId}&f_id=${fileKey}&v=${version}`;

  await axiosClient({
    url: apiUrl,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${await getToken()}`
    }
  })
    .then(async (fileUrl) => {
      await axiosClient({
        method: 'GET',
        url: fileUrl.data,
        decompress: true
      })
        .then((response) => {
          finalResponse = response.data;
        })
        .catch((error) =>
          console.log('An error has occured when fetching report - ', error?.message)
        );
    })
    .catch((error) => console.log('An error has occured when fetching reports - ', error?.message));

  return finalResponse;
}
