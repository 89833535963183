import { ScoreHistory } from '../../../networking/analyses/report/Scorecard.types';
import chartStar from './chartStar.svg';
import chartCircle from './chartCircle.svg';
import Star from './star.svg';

export const pointStyle = (scoreHistory: ScoreHistory[]) => {
  const starPoint = new Image();
  starPoint.src = chartStar;

  const finalPointCircle = new Image();
  finalPointCircle.src = chartCircle;

  const finalPointStar = new Image();
  finalPointStar.src = Star;
  finalPointStar.width = finalPointStar.height = 20;

  const pointStyles = scoreHistory.map((score: ScoreHistory, i: number) => {
    if (i === scoreHistory.length - 1) {
      return score.score >= 90 ? finalPointStar : finalPointCircle;
    }
    return score.score >= 90 ? starPoint : 'circle';
  });
  return pointStyles;
};

export const pointRadius = (lengthOfArray: number) => {
  const radiusArray = [...new Array(lengthOfArray).fill(3)];
  radiusArray[radiusArray.length - 1] = 8;
  return radiusArray;
};
