import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import TPOPaper from '../../../components/TPOPaper/TPOPaper';
import SecondaryTabButton from '../TabButton/SecondaryTabButton';
import ToolTip from '../../../components/ToolTip/ToolTip';
import TreasuryServicesTab from './TreasuryServicesTab';

enum TreasuryServicesTabState {
  BalanceSummary,
  ECR,
  FeesAndCompensatingOffset,
  LineItemFees
}

interface TreasuryServicesTabSectionProps {
  selectedAccountGroupIndex: number;
}

const TreasuryServicesTabSection = (props: TreasuryServicesTabSectionProps) => {
  const { t } = useTranslation();

  const [tabState, setTabState] = useState(TreasuryServicesTabState.BalanceSummary);

  return (
    <TPOPaper color="rgba(70, 0, 116, 0.3)">
      <Box paddingBottom={'20px'}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
          marginBottom={'20px'}
        >
          <SecondaryTabButton
            isActive={tabState == TreasuryServicesTabState.BalanceSummary}
            onClick={() => setTabState(TreasuryServicesTabState.BalanceSummary)}
            text={t('cashVestAnalyses.treasuryServices.BalanceSummaryTabTitle')}
          />
          <SecondaryTabButton
            isActive={tabState == TreasuryServicesTabState.ECR}
            onClick={() => setTabState(TreasuryServicesTabState.ECR)}
            text={t('cashVestAnalyses.treasuryServices.ECRTabTitle')}
          />
          <SecondaryTabButton
            isActive={tabState == TreasuryServicesTabState.FeesAndCompensatingOffset}
            onClick={() => setTabState(TreasuryServicesTabState.FeesAndCompensatingOffset)}
            text={t('cashVestAnalyses.treasuryServices.TotalFeesAndCreditsTabTitle')}
          />
          <SecondaryTabButton
            isActive={tabState == TreasuryServicesTabState.LineItemFees}
            onClick={() => setTabState(TreasuryServicesTabState.LineItemFees)}
            text={t('cashVestAnalyses.treasuryServices.LineItemFeesTabTitle')}
          />
        </Box>

        {tabState == TreasuryServicesTabState.BalanceSummary && (
          <TreasuryServicesTab
            selectedAccountGroupIndex={props.selectedAccountGroupIndex}
            tabState={tabState}
          >
            <ToolTip
              title={t('cashVestAnalyses.treasuryServices.BalanceSummaryTabTitle')}
              content={t('cashVestAnalyses.treasuryServices.balanceSummaryToolTipContent')}
              label={t('common.explainTable')}
            />
          </TreasuryServicesTab>
        )}

        {tabState == TreasuryServicesTabState.ECR && (
          <TreasuryServicesTab
            selectedAccountGroupIndex={props.selectedAccountGroupIndex}
            tabState={tabState}
          >
            <ToolTip
              title={t('cashVestAnalyses.treasuryServices.ECRTabTitle')}
              content={t('cashVestAnalyses.treasuryServices.ECRToolTipContent')}
              label={t('common.explainTable')}
            />
          </TreasuryServicesTab>
        )}

        {tabState == TreasuryServicesTabState.FeesAndCompensatingOffset && (
          <TreasuryServicesTab
            selectedAccountGroupIndex={props.selectedAccountGroupIndex}
            tabState={tabState}
          >
            <ToolTip
              title={t('cashVestAnalyses.treasuryServices.TotalFeesAndCreditsTabTitle')}
              content={t('cashVestAnalyses.treasuryServices.TotalFeesAndCreditsToolTipContent')}
              label={t('common.explainTable')}
            />
          </TreasuryServicesTab>
        )}

        {tabState == TreasuryServicesTabState.LineItemFees && (
          <TreasuryServicesTab
            selectedAccountGroupIndex={props.selectedAccountGroupIndex}
            tabState={tabState}
          />
        )}
      </Box>
    </TPOPaper>
  );
};

export default TreasuryServicesTabSection;
