import React, { useContext } from 'react';
import PaperSection from '../../../../components/PaperSection/PaperSection';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import ToolTip from '../../../../components/ToolTip/ToolTip';
import { Box } from '@mui/material';
import EditTextField from '../../../../components/EditTextField/EditTextField';
import { ReportContext } from '../../../../common/reportContext';
import { CommentsContext } from '../../../../common/commentsContext';
import { currencyFormatting } from '../../../../common/helpers';
import StrategicLiquidityTable from './StrategicLiquidityTable';

interface StrategicLiquidityProps {
  index: number;
}

const StrategicLiquidity = (props: StrategicLiquidityProps) => {
  const { t } = useTranslation();
  const { comments, setComments } = useContext(CommentsContext);
  const { report } = useContext(ReportContext);
  const strategicLiquidity =
    report.liquidityProficiency.liquidityProficiency[props.index].strategicLiquidity;

  return (
    <PaperSection title={t('cashVestAnalyses.liquidityProficiency.strategicLiquidityTitle')}>
      <Stack spacing={2}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '700px auto',
            gap: '32px'
          }}
        >
          <Stack spacing={2}>
            <Typography
              sx={{
                color: 'text.primary',
                fontSize: '18px',
                fontWeight: 650
              }}
            >
              {t('common.cashVestAnalysisHeading')}
            </Typography>
            <EditTextField
              text={comments.liquidityProficiency.strategicLiquidity}
              setText={(text) => {
                const newComments = { ...comments };
                newComments.liquidityProficiency.strategicLiquidity = text;
                setComments(newComments);
              }}
            />
          </Stack>
          <Stack spacing={1}>
            <Typography
              data-test="totalCashVestAnalysis"
              sx={{
                color: 'text.primary',
                fontSize: '32px',
                fontWeight: 600
              }}
            >
              {currencyFormatting(strategicLiquidity.totalLiquidity, 0)}
            </Typography>
            <Typography
              sx={{
                color: 'text.primary',
                fontSize: '18px',
                fontWeight: 600
              }}
            >
              {t('cashVestAnalyses.liquidityProficiency.strategicLiquidityTotal')}
            </Typography>
          </Stack>
        </Box>

        <StrategicLiquidityTable index={props.index} />

        <ToolTip
          title={t('cashVestAnalyses.liquidityProficiency.strategicLiquidityTableToolTipTitle')}
          content={t('cashVestAnalyses.liquidityProficiency.strategicLiquidityTableToolTipContent')}
          label={t('common.explainTable')}
        />
      </Stack>
    </PaperSection>
  );
};

export default StrategicLiquidity;
