import React, { useContext } from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import type { ChartOptions, Tick } from 'chart.js';
import { numberFormatting } from '../../../../common/helpers';
import { Typography, Box } from '@mui/material';
import TPOLabel from '../../../../components/TPOLabel/TPOLabel';
import { ReportContext } from '../../../../common/reportContext';
import { getColors } from '../../../../common/colorUtils';
import { Dataset } from './BankByBankAnalysis.types';

interface BalancesByBankChartProps {
  selectedAccountGroupIndex: number;
}

const options: ChartOptions<'line'> = {
  scales: {
    y: {
      beginAtZero: true,
      suggestedMax: 20,
      position: 'right',
      stacked: true,
      ticks: {
        callback: (tickValue: string | number, index: number, ticks: Tick[]) =>
          numberFormatting(+tickValue)
      },
      grid: {
        display: false
      }
    },
    x: {
      grid: {
        display: false
      },
      ticks: {
        autoSkip: false,
        callback: function (val, index) {
          // Only display ticks that are the first of the month
          const label = this.getLabelForValue(Number(val));
          return new Date(label).getDate() === 1 ? this.getLabelForValue(Number(val)) : '';
        }
      }
    }
  },
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: (tooltipItem) => {
          return tooltipItem.dataset.label
            ? tooltipItem.dataset.label + `: $${tooltipItem.formattedValue}`
            : `$${tooltipItem.formattedValue}`;
        }
      }
    }
  },
  elements: {
    point: {
      radius: 0
    }
  },
  interaction: {
    mode: 'index'
  }
};

const BalancesByBankChart = (props: BalancesByBankChartProps) => {
  const { t } = useTranslation();
  const { report } = useContext(ReportContext);

  const accountGroup = report.accountOptimization.groups[props.selectedAccountGroupIndex];

  const getLabels = (): string[] => {
    return accountGroup.dailyAccountBalances[0].daily.map((obj) => {
      const [year, month, day] = obj.date.split('-').map((x) => +x);
      const dateObject = new Date(year, month - 1, day);
      return dateObject.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      });
    });
  };

  const colors = getColors(accountGroup.dailyAccountBalances.length);

  const getAccountDataset = (): Array<Dataset> => {
    const balances = accountGroup.dailyAccountBalances;
    const datasets: Dataset[] = [];
    for (let i = 0; i < accountGroup.dailyAccountBalances.length; i++) {
      const dataset: Dataset = {
        data: [],
        bank: '',
        label: '',
        fill: true
      };
      const data: number[] = [];
      let isEmpty = true;
      for (let j = 0; j < balances[i].daily.length; j++) {
        const amount = balances[i].daily[j].amount;
        if (amount !== 0) isEmpty = false;
        data.push(amount);
      }
      if (isEmpty) continue;
      dataset.data = data;
      dataset.bank = balances[i].account;
      dataset.label = balances[i].account;
      dataset.fill = true;
      datasets.push(dataset);
    }
    for (let i = 0; i < datasets.length; i++) {
      datasets[i].backgroundColor = colors[i % colors.length];
    }
    return datasets;
  };

  const getBankDataset = (): Array<Dataset> => {
    const balances = report.accountOptimization.balanceByBank.dailyBankBalances;
    const datasets: Dataset[] = [];
    for (let i = 0; i < balances.length; i++) {
      const dataset: Dataset = {
        data: [],
        bank: '',
        label: '',
        fill: true
      };
      const data: number[] = [];
      let isEmpty = true;
      for (let j = 0; j < balances[i].daily.length; j++) {
        const amount = balances[i].daily[j].amount;
        if (amount !== 0) isEmpty = false;
        data.push(amount);
      }
      if (isEmpty) continue;

      dataset.data = data;
      dataset.bank = balances[i].bank;
      dataset.label = balances[i].bank;
      dataset.fill = true;
      datasets.push(dataset);
    }

    for (let i = 0; i < datasets.length; i++) {
      datasets[i].backgroundColor = colors[i % colors.length];
    }

    return datasets;
  };

  const getDataset = (): Array<Dataset> => {
    if (accountGroup.accountGroup === 'All Accounts') {
      return getBankDataset();
    } else {
      return getAccountDataset();
    }
  };

  const getChartTitle = (): string => {
    if (accountGroup.accountGroup === 'All Accounts') {
      return 'Bank by Bank Analysis';
    } else {
      return accountGroup.accountGroup;
    }
  };

  const chartData = {
    labels: getLabels(),
    datasets: getDataset()
  };

  return (
    <>
      <Typography
        align="right"
        sx={{
          fontSize: '18px',
          color: 'text.primary',
          fontWeight: 650
        }}
      >
        {getChartTitle()}
      </Typography>

      <Line data={chartData} options={options} redraw />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap'
        }}
      >
        {chartData.datasets.map((bankDataset: Dataset, index: number) => (
          <TPOLabel
            key={`TPOLabel: ${bankDataset.bank} - ${index}`}
            text={bankDataset.bank}
            color={bankDataset.backgroundColor}
          />
        ))}
      </Box>
    </>
  );
};

export default React.memo(BalancesByBankChart);
