import React, { useState } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StringFilterState } from '../../../../components/StringFilter/StringFilter';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InvestmentTable, { InvestmentTableRow } from './InvestmentsTable';
import { AccountsInvestment } from '../../../../networking/accounts/investments/AccountsInvestment.types';
import AddInvestmentDrawer from '../../../../components/AddInvestmentDrawer';
import { convertToListOfLabel, Order } from '../../../../components/TPOTable/TPOTableFunctions';
import { formatInvestmentTableRows } from './utils';
import { Header } from '../../../../components/TPOTable/TPOTable';
import InvestmentTimelineChart from '../InvestmentTimelineChart/InvestmentTimelineChart';
import { Dayjs } from 'dayjs';
import FiltersBar, { FiltersBarProps } from '../../../../components/FiltersBar/FiltersBar';
import TPOButton from '../../../../components/TPOButton';
import TPOExportDropdown from '../../../../components/TPOExportDropdown/TPOExportDropdown';
import AddIcon from '@mui/icons-material/Add';
import TPOPaper from '../../../../components/TPOPaper/TPOPaper';
import ToolTip from '../../../../components/ToolTip/ToolTip';

interface AccountInvestmentsProps {
  accountInvestments: AccountsInvestment[];
  setProductType: (filterState: StringFilterState) => void;
  setInstitution: (filterState: StringFilterState) => void;
  setMatureInvestment: (filterState: StringFilterState) => void;
  institution: StringFilterState;
  productType: StringFilterState;
  matureInvestment: StringFilterState;
}

const AccountInvestments = ({
  accountInvestments,
  institution,
  productType,
  matureInvestment,
  setInstitution,
  setMatureInvestment,
  setProductType
}: AccountInvestmentsProps) => {
  const { t } = useTranslation();

  const initialProductType = useState(productType)[0];
  const initialInstitution = useState(institution)[0];
  const initialMatureInvestment = useState(matureInvestment)[0];

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof InvestmentTableRow>('account');
  const [filterDate, setFilterDate] = useState<Dayjs | null>(null);
  const [isAddInvestmentDrawerOpen, setIsAddInvestmentDrawerOpen] = useState(false);
  const [tempProductType, setTempProductType] = useState<StringFilterState>(productType);
  const [tempInstitution, setTempInstitution] = useState<StringFilterState>(institution);
  const [tempMatureInvestment, setTempMatureInvestment] =
    useState<StringFilterState>(matureInvestment);

  const investmentTableHeaders: Header[] = [
    {
      label: 'Account',
      numeric: false,
      id: 'account',
      showToolTip: false
    },
    {
      label: 'Financial institution',
      numeric: false,
      id: 'financialInstitution',
      showToolTip: false
    },
    {
      label: 'Investment type',
      numeric: false,
      id: 'type',
      showToolTip: false
    },
    {
      label: 'Purchase date',
      numeric: false,
      id: 'purchaseDate',
      showToolTip: false
    },
    {
      label: 'Maturity date',
      numeric: false,
      id: 'maturityDate',
      showToolTip: false
    },
    { label: 'Face value', numeric: true, id: 'faceValue', showToolTip: false },
    {
      label: 'Interest rate',
      numeric: true,
      id: 'interestRate',
      showToolTip: false
    },
    {
      label: 'Days until maturity',
      numeric: true,
      id: 'daysUntilMaturity',
      showToolTip: false
    },
    {
      label: 'Fund',
      numeric: false,
      id: 'fund',
      showToolTip: false
    }
  ];

  const investmentFilters = [
    (investment: AccountsInvestment) => institution[investment.institutionName].value,
    (investment: AccountsInvestment) => productType[investment.accountType].value,
    (investment: AccountsInvestment) =>
      new Date(investment.maturityDate) >=
      (filterDate ? new Date(filterDate.toDate().setHours(0, 0, 0, 0)) : 0),
    (investment: AccountsInvestment) =>
      Number(investment.daysUntilMaturity) > 0 || matureInvestment['Matured investments'].value
  ];

  const handleFilterDateChange = (newValue: Dayjs | null) => {
    setFilterDate(newValue);
  };

  const filteredInvestments = accountInvestments.filter((investment) =>
    investmentFilters.every((f) => f(investment))
  );

  const handleOpenAddInvestmentDrawer = () => {
    setIsAddInvestmentDrawerOpen(true);
  };

  const handleCloseAddInvestmentDrawer = () => {
    setIsAddInvestmentDrawerOpen(false);
  };

  const handleIncludeCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = { ...tempMatureInvestment };
    newState['Matured investments'] = { value: event.target.checked };
    setTempMatureInvestment(newState);
  };

  const applyFilters = () => {
    setProductType(tempProductType);
    setInstitution(tempInstitution);
    setMatureInvestment(tempMatureInvestment);
  };

  const dropdownFilters: FiltersBarProps['dropdownFilters'] = [
    {
      title: 'Filter product type',
      allSelectedTitle: 'All types',
      state: tempProductType,
      setState: setTempProductType
    },
    {
      title: 'Filter financial institutions',
      allSelectedTitle: 'All institutions',
      state: tempInstitution,
      setState: setTempInstitution
    }
  ];

  const checkbox: FiltersBarProps['checkbox'] = {
    isChecked: tempMatureInvestment['Matured investments'].value,
    onChange: handleIncludeCheckboxChange,
    name: 'includeMatureInvestments',
    label: 'Include Matured Investments'
  };

  const formattedInvestmentRows = formatInvestmentTableRows(filteredInvestments, order, orderBy);

  const clearFilters = () => {
    setTempProductType(initialProductType);
    setTempInstitution(initialInstitution);

    const newMatureInvestmentState = { ...initialMatureInvestment };
    newMatureInvestmentState['Matured investments'] = { value: true };
    setTempMatureInvestment(newMatureInvestmentState);
  };

  return (
    <Box
      sx={{
        backgroundColor: 'text.secondary',
        padding: '25px'
      }}
    >
      <AddInvestmentDrawer
        isOpen={isAddInvestmentDrawerOpen}
        handleCloseDrawer={handleCloseAddInvestmentDrawer}
      />
      <Typography
        sx={{
          fontSize: '32px',
          color: 'text.primary'
        }}
      >
        {t('investments.title')}
        {}
      </Typography>

      <TPOPaper color="rgba(70, 0, 16, 0.3)">
        <Box
          sx={{
            padding: '25px'
          }}
        >
          <Stack spacing={3}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <Stack spacing={2}>
                    <Typography
                      sx={{
                        color: 'text.primary',
                        fontSize: '16px',
                        fontWeight: 'bold'
                      }}
                    >
                      {t('investments.filterByDate')}
                    </Typography>
                    <DatePicker
                      value={filterDate}
                      onChange={handleFilterDateChange}
                      views={['month', 'day', 'year']}
                    />
                  </Stack>
                </Box>
              </LocalizationProvider>
            </Box>

            <FiltersBar
              dropdownFilters={dropdownFilters}
              checkbox={checkbox}
              applyFilters={applyFilters}
              clearFilters={clearFilters}
            />
            <Typography
              align="right"
              sx={{
                fontSize: '18px',
                color: 'text.primary',
                fontWeight: 'bold'
              }}
            >
              {t('investments.timelineChartTitle')}
            </Typography>
            <InvestmentTimelineChart investments={filteredInvestments} />
            <ToolTip
              title={t('investments.chartToolTipTitle')}
              content={t('investments.chartToolTipContent')}
              label={t('common.explainChart')}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >
              <TPOButton
                onClick={handleOpenAddInvestmentDrawer}
                styles={{ width: '170px', marginRight: '16px' }}
              >
                {t('investments.newInvestmentButton')}
                <AddIcon fontSize="small" sx={{ margin: '0 0 0 5px' }} />
              </TPOButton>
              <TPOExportDropdown
                sheetContent={formattedInvestmentRows}
                sheetHeaders={convertToListOfLabel(investmentTableHeaders)}
                fileName={'InvestmentTable'}
              />
            </Box>
            <InvestmentTable
              formattedInvestmentRows={formattedInvestmentRows}
              order={order}
              setOrder={setOrder}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
              investmentTableHeaders={investmentTableHeaders}
            />
          </Stack>
        </Box>
      </TPOPaper>
    </Box>
  );
};

export default AccountInvestments;
