import { AppBar, Box, Toolbar } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ACCOUNT_DETAILS_ACCOUNTS_PAGE, ROUTES } from '../../routing/ROUTES';
import Session from '../../common/Session.types';
import icon from './icon.png';
import NavbarPrimaryButton from './NavbarPrimaryButton/NavbarPrimaryButton';
import NavbarSecondaryButton from './NavbarSecondaryButton/NavbarSecondaryButton';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { AuthContext } from '../../common/authContext';
import { signOut } from '../../auth/authenticator';

import { mapStripPathParams, stripPathParams } from '../../utils/routing';
import { RoutingHistoryContext } from '../../common/routingHistoryContext';

export const HOME_LINK_DATA_TEST_ID = 'HOME_LINK_DATA_TEST_ID';

const Navbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { authState, resetAuthState, session } = useContext(AuthContext);
  const { setLandingURLToLoggedOutState } = useContext(RoutingHistoryContext);

  const logoutButtonHiddenPaths = mapStripPathParams([
    ROUTES.Logout,
    ROUTES.ForgottenPassword,
    ROUTES.SetupMFA,
    ROUTES.SetupPassword,
    ROUTES.ResetPassword,
    ROUTES.ResetPasswordSuccess
  ]);

  const navigationButtonHiddenPaths = mapStripPathParams([
    ROUTES.Logout,
    ROUTES.ForgottenPassword,
    ROUTES.RelationshipManager,
    ROUTES.SetupMFA,
    ROUTES.SetupPassword,
    ROUTES.ResetPassword,
    ROUTES.ResetPasswordSuccess
  ]);

  const IsMenuItemHidden = (hiddenPaths: string[]): boolean => {
    const pathName = stripPathParams(location.pathname);
    return hiddenPaths.includes(pathName);
  };

  //todo: this should be moved to auth/login component
  const handleLogout = async (): Promise<void> => {
    await signOut();
    resetAuthState();
    // setSession(Session.LoggedOut); //todo: change to new state
    setLandingURLToLoggedOutState();
    navigate(ROUTES.Logout);
  };

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        position: 'static'
      }}
    >
      <Toolbar>
        <Link to={ROUTES.Logout} data-testid={HOME_LINK_DATA_TEST_ID}>
          <Box
            sx={{
              paddingTop: 2,
              paddingBottom: 2
            }}
          >
            <img src={icon} width="200dp" />
          </Box>
        </Link>

        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'right',
            visibility: IsMenuItemHidden(navigationButtonHiddenPaths) ? 'hidden' : 'visible'
          }}
        >
          {session === Session.RelationshipManager && (
            <NavbarPrimaryButton
              text={authState.clientName}
              isSelected={false}
              onClick={() => {}}
            />
          )}

          <NavbarPrimaryButton
            text={t('navigation.accountList')}
            isSelected={
              stripPathParams(location.pathname) === stripPathParams(ROUTES.AccountDetails)
            }
            onClick={() => navigate(ACCOUNT_DETAILS_ACCOUNTS_PAGE)}
          />

          <NavbarPrimaryButton
            text={t('navigation.analyses')}
            isSelected={stripPathParams(location.pathname) === stripPathParams(ROUTES.Analyses)}
            onClick={() => navigate(ROUTES.Analyses)}
          />

          {!authState.elevatedUser && (
            <NavbarPrimaryButton
              text={t('navigation.profile')}
              isSelected={stripPathParams(location.pathname) === stripPathParams(ROUTES.Profile)}
              onClick={() => navigate(ROUTES.Profile)}
            />
          )}
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'right',
            visibility:
              !IsMenuItemHidden(logoutButtonHiddenPaths) && session == Session.RelationshipManager
                ? 'visible'
                : 'hidden'
          }}
        >
          <NavbarPrimaryButton
            text={t('navigation.account')}
            isSelected={location.pathname === ROUTES.RelationshipManager}
            onClick={() => navigate(ROUTES.RelationshipManager)}
          />
        </Box>

        <Box
          sx={{
            paddingRight: 3,
            visibility: IsMenuItemHidden(logoutButtonHiddenPaths) ? 'hidden' : 'visible'
          }}
        >
          <NavbarSecondaryButton
            text={t('navigation.logout')}
            onClick={() => {
              handleLogout();
            }}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
