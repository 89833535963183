import React from 'react';
import { Stack, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { currencyFormatting } from '../../../../common/helpers';

interface BalanceSummaryTableProps {
  title: string;
  previousYear: number;
  currentYear: number;
  difference: number;
}

const BalanceSummaryTable = (props: BalanceSummaryTableProps) => {
  return (
    <Stack>
      <Typography
        sx={{
          fontSize: '20px',
          color: 'text.primary',
          fontWeight: 500
        }}
      >
        {props.title}
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontSize: '16px',
                color: 'text.primary'
              }}
            >
              Previous year
            </TableCell>

            <TableCell
              sx={{
                fontSize: '16px',
                color: 'text.primary'
              }}
            >
              Current year
            </TableCell>

            <TableCell
              sx={{
                fontSize: '16px',
                color: 'text.primary'
              }}
            >
              Difference
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow
            sx={{
              '&:last-child td, &:last-child th': {
                border: 0
              }
            }}
          >
            <TableCell
              sx={{
                fontSize: '16px',
                color: 'text.primary'
              }}
            >
              {currencyFormatting(props.previousYear)}
            </TableCell>

            <TableCell
              sx={{
                fontSize: '16px',
                color: 'text.primary'
              }}
            >
              {currencyFormatting(props.currentYear)}
            </TableCell>

            <TableCell
              sx={{
                fontSize: '16px',
                color: 'text.primary'
              }}
            >
              {currencyFormatting(props.difference)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  );
};

export default BalanceSummaryTable;
