import { Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CommentsContext } from '../../../common/commentsContext';
import { currencyFormatting } from '../../../common/helpers';
import { ReportContext } from '../../../common/reportContext';
import PaperSection from '../../../components/PaperSection/PaperSection';
import BaseSummary from '../../Analyses/BaseSummary/BaseSummary';
import InvestmentChart from '../../Analyses/LiquidProficiency/Investments/InvestmentChart';
import TimeHorizonChart from '../../Analyses/LiquidProficiency/Investments/TimeHorizonChart';
import StrategicLiquidityTable from '../../Analyses/LiquidProficiency/StrategicLiquidity/StrategicLiquidityTable';
import NoBreak from '../NoBreak';
import Page from '../Page';

const DownloadLiquidProficiencyReport = () => {
  const { t } = useTranslation();
  const { comments } = useContext(CommentsContext);
  const { report } = useContext(ReportContext);

  const liquidityProficiencies = report.liquidityProficiency.liquidityProficiency;

  const options = liquidityProficiencies.map(
    (liquidityProficiency) => liquidityProficiency.accountGroup
  );

  return (
    <Page>
      <Stack spacing={3}>
        <BaseSummary
          title={t('cashVestAnalyses.liquidityProficiency.title')}
          content={t('cashVestAnalyses.liquidityProficiency.summary')}
          setContent={() => {}}
        />
        <Stack spacing={2}>
          <PaperSection title={t('common.cashVestAnalysisHeading')} bannerColor={'secondary.main'}>
            {/* RM comment for table */}
            <Typography
              sx={{
                fontSize: '18px',
                color: 'text.primary',
                fontWeight: 550
              }}
            >
              {t('cashVestAnalyses.liquidityProficiency.strategicLiquidityTitle')}
            </Typography>
            <Typography
              sx={{
                fontSize: '18px',
                color: 'text.primary'
              }}
              gutterBottom
            >
              {comments.liquidityProficiency.strategicLiquidity}
            </Typography>
            {/* RM comment for charts */}
            <Typography
              sx={{
                fontSize: '18px',
                color: 'text.primary',
                fontWeight: 550
              }}
            >
              {t('cashVestAnalyses.liquidityProficiency.investmentVsStrategicLiquidityLvlsTitle')}
            </Typography>
            <Typography
              sx={{
                fontSize: '18px',
                color: 'text.primary'
              }}
            >
              {comments.liquidityProficiency.investments}
            </Typography>
          </PaperSection>
        </Stack>
      </Stack>
      {options.map((accountGroup, index) => (
        <Page key={`charts for ${accountGroup}`}>
          <PaperSection title={`Account Group - ${accountGroup}`} bannerColor={'primary.main'}>
            <NoBreak>
              <PaperSection title="Strategic Liquidity">
                {/* Total Strategic Liquidity */}
                <Stack spacing={1}>
                  <Typography
                    sx={{
                      color: 'text.primary',
                      fontSize: '32px',
                      fontWeight: 600
                    }}
                  >
                    {currencyFormatting(
                      liquidityProficiencies[index].strategicLiquidity.totalLiquidity,
                      0
                    )}
                  </Typography>
                  <Typography
                    sx={{
                      color: 'text.primary',
                      fontSize: '18px',
                      fontWeight: 600
                    }}
                  >
                    {t('cashVestAnalyses.liquidityProficiency.strategicLiquidityTotal')}
                  </Typography>
                </Stack>
                <StrategicLiquidityTable index={index} />
              </PaperSection>
            </NoBreak>
            <NoBreak>
              <PaperSection title="Investment vs Strategic Liquidity Levels">
                <InvestmentChart selectedAccountGroupIndex={index} />
              </PaperSection>
            </NoBreak>
            <NoBreak>
              <PaperSection title="Strategic Liquidity vs Total Balance">
                <TimeHorizonChart selectedAccountGroupIndex={index} />
              </PaperSection>
            </NoBreak>
          </PaperSection>
        </Page>
      ))}
    </Page>
  );
};

export default DownloadLiquidProficiencyReport;
