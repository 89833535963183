import React from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';

import InvestmentForm, { AccountNames, InvestmentTypes } from './InvestmentForm';
import {
  DrawerFilledSubmitButton,
  DrawerOutlinedButton,
  DrawerEditPageDeleteButton
} from './UIElements';

import { FormInvestment } from '../AddInvestmentDrawer.types';
import TPODialog from './TPODialog';

export const EDIT_PAGE_INVESTMENT_FORM_DATA_TEST_ID = 'EDIT_PAGE_INVESTMENT_FORM_DATA_TEST_ID';

export const EDIT_PAGE_DISCARD_CHANGES_TPO_DIALOG = 'EDIT_PAGE_DISCARD_CHANGES_TPO_DIALOG';

interface EditPageProps {
  investment: FormInvestment;
  isDeleteTPODialogOpen: boolean;
  isDiscardChangesTPODialogOpen: boolean;
  handleEditInvestment: (investment: FormInvestment) => void;
  handleBackToAddedPage: () => void;
  handlePrimaryButtonDeleteTPODialog: () => void;
  handleSecondaryButtonDeleteTPODialog: () => void;
  handlePrimaryDiscardChangesTPODialog: () => void;
  handleSecondaryDiscardChangesTPODialog: () => void;
  handleOpenDeleteTPODialog: () => void;
  accountNames: AccountNames;
  investmentTypes: InvestmentTypes;
  investmentTypesGroup2: number[];
  investmentTypesGroup3: number[];
  form_validation_schema: Yup.Schema;
}

const EditPage = ({
  investment,
  isDeleteTPODialogOpen,
  isDiscardChangesTPODialogOpen,
  handleEditInvestment,
  handleBackToAddedPage,
  handlePrimaryButtonDeleteTPODialog,
  handleSecondaryButtonDeleteTPODialog,
  handlePrimaryDiscardChangesTPODialog,
  handleSecondaryDiscardChangesTPODialog,
  handleOpenDeleteTPODialog,
  accountNames,
  investmentTypes,
  investmentTypesGroup2,
  investmentTypesGroup3,
  form_validation_schema
}: EditPageProps) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: investment,
    validationSchema: form_validation_schema,
    onSubmit: (values) => {
      handleEditInvestment(values);
    }
  });

  return (
    <Box
      sx={{ padding: '25px', backgroundColor: 'common.white' }}
      data-testid={EDIT_PAGE_INVESTMENT_FORM_DATA_TEST_ID}
    >
      <TPODialog
        title={t('investments.addInvestment.deleteTPODialog.title')}
        content={t('investments.addInvestment.deleteTPODialog.content')}
        isOpen={isDeleteTPODialogOpen}
        handlePrimaryButton={handlePrimaryButtonDeleteTPODialog}
        handleSecondaryButton={handleSecondaryButtonDeleteTPODialog}
        textPrimaryButton={t('investments.addInvestment.deleteTPODialog.goBackButton')}
        textSecondaryButton={t('investments.addInvestment.deleteTPODialog.okayButton')}
      />
      <TPODialog
        title={t('investments.addInvestment.discardChangesEditPageTPODialog.title')}
        content={t('investments.addInvestment.discardChangesEditPageTPODialog.content')}
        isOpen={isDiscardChangesTPODialogOpen}
        handlePrimaryButton={handlePrimaryDiscardChangesTPODialog}
        handleSecondaryButton={handleSecondaryDiscardChangesTPODialog}
        textPrimaryButton={t(
          'investments.addInvestment.discardChangesEditPageTPODialog.goBackButton'
        )}
        textSecondaryButton={t(
          'investments.addInvestment.discardChangesEditPageTPODialog.okayButton'
        )}
        dataTestid={EDIT_PAGE_DISCARD_CHANGES_TPO_DIALOG}
      />
      <Stack spacing={1}>
        <InvestmentForm
          formik={formik}
          accountNames={accountNames}
          investmentTypes={investmentTypes}
          investmentTypesGroup2={investmentTypesGroup2}
          investmentTypesGroup3={investmentTypesGroup3}
        />
        <DrawerFilledSubmitButton onClick={formik.submitForm}>
          {t('investments.addInvestment.editPage.saveButton') as string}
        </DrawerFilledSubmitButton>
        <DrawerOutlinedButton
          onClick={handleBackToAddedPage}
          text={t('investments.addInvestment.editPage.backButton')}
        />
        <DrawerEditPageDeleteButton
          onClick={handleOpenDeleteTPODialog}
          text={t('investments.addInvestment.editPage.deleteButton')}
        />
      </Stack>
    </Box>
  );
};

export default EditPage;
