import React, { useState } from 'react';
import { Box, Typography, Stack, LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AccountsStatement } from '../../../../networking/accounts/statements/AccountsStatement';
import AccountTable from '../AccountTable/AccountTable';
import TPOPaper from '../../../../components/TPOPaper/TPOPaper';
import { StringFilterState } from '../../../../components/StringFilter/StringFilter';
import AccountGraph from '../AccountGraph/AccountGraph';
import FiltersBar, { FiltersBarProps } from '../../../../components/FiltersBar/FiltersBar';

interface AccountsProps {
  statements: AccountsStatement[];
  balances: InstitutionBalances[];
  dateAsOf: string;
  institution: StringFilterState;
  setInstitution: (filterState: StringFilterState) => void;
  productType: StringFilterState;
  setProductType: (filterState: StringFilterState) => void;
  group: StringFilterState;
  setGroup: (filterState: StringFilterState) => void;
  closedAccount: StringFilterState;
  setClosedAccount: (filterState: StringFilterState) => void;
  reloading: boolean;
}

const AccountsStatements = (props: AccountsProps) => {
  const { t } = useTranslation();

  const initialProductType = useState(props.productType)[0];
  const initialInstitution = useState(props.institution)[0];
  const initialGroup = useState(props.group)[0];
  const initialClosedAccount = useState(props.closedAccount)[0];

  const [tempProductType, setTempProductType] = useState<StringFilterState>(props.productType);
  const [tempInstitution, setTempInstitution] = useState<StringFilterState>(props.institution);
  const [tempGroup, setTempGroup] = useState<StringFilterState>(props.group);
  const [tempClosedAccount, setTempClosedAccount] = useState<StringFilterState>(
    props.closedAccount
  );

  const applyFilters = () => {
    props.setProductType(tempProductType);
    props.setInstitution(tempInstitution);
    props.setClosedAccount(tempClosedAccount);
    props.setGroup(tempGroup);
  };

  const filteredStatements = props.statements
    .filter((statement) => props.productType[statement.accountType].value)
    .filter((statement) => props.institution[statement.institutionName].value)
    .filter((statement) => {
      const accountGroups = statement.accountGroups || [];
      const groupFilterPassed = accountGroups.some((accountGroup) => {
        const filterValue = props.group[accountGroup.id].value;
        return filterValue;
      });
      return groupFilterPassed;
    })
    .filter((statement) => !statement.isClosed || props.closedAccount['Closed accounts'].value);

  const updatedThroughDate = (): string => {
    return new Date(props.dateAsOf).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      timeZone: 'UTC'
    });
  };
  const handleIncludeCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = { ...tempClosedAccount };
    newState['Closed accounts'] = { value: event.target.checked };
    setTempClosedAccount(newState);
  };

  const dropdownFilters: FiltersBarProps['dropdownFilters'] = [
    {
      title: 'Filter product types',
      allSelectedTitle: 'All types',
      state: tempProductType,
      setState: setTempProductType
    },
    {
      title: 'Filter financial institutions',
      allSelectedTitle: 'All institutions',
      state: tempInstitution,
      setState: setTempInstitution
    },
    {
      title: 'Account groups',
      allSelectedTitle: 'All groups',
      state: tempGroup,
      setState: setTempGroup
    }
  ];

  const checkbox: FiltersBarProps['checkbox'] = {
    isChecked: tempClosedAccount['Closed accounts'].value,
    onChange: handleIncludeCheckboxChange,
    name: 'includeClosedAccounts',
    label: 'Include Closed Accounts'
  };

  const clearFilters = () => {
    setTempProductType(initialProductType);
    setTempInstitution(initialInstitution);
    setTempGroup(initialGroup);

    const newClosedAccountState = { ...initialClosedAccount };
    newClosedAccountState['Closed accounts'] = { value: false };
    setTempClosedAccount(newClosedAccountState);

    props.setProductType(initialProductType);
    props.setInstitution(initialInstitution);
    props.setGroup(initialGroup);
    props.setClosedAccount(newClosedAccountState);
  };

  return (
    <Stack
      spacing={1}
      sx={{
        backgroundColor: '#ffffff',
        padding: '25px'
      }}
    >
      <Box>
        <Typography
          sx={{
            fontSize: '32px',
            color: 'text.primary'
          }}
        >
          {t('listOfAccounts.chartTitle')}
        </Typography>
        <Typography
          sx={{
            fontSize: '18px',
            color: 'text.primary'
          }}
        >
          {t('listOfAccounts.dataUpdatedThrough', { date: updatedThroughDate() })}
        </Typography>
      </Box>

      {props.reloading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}

      <TPOPaper color="rgba(70, 0, 116, 0.3)">
        <Box padding={3}>
          <FiltersBar
            dropdownFilters={dropdownFilters}
            checkbox={checkbox}
            applyFilters={applyFilters}
            clearFilters={clearFilters}
          />
          <AccountGraph balances={props.balances} endDate={props.dateAsOf} />
          <AccountTable statements={filteredStatements} />
        </Box>
      </TPOPaper>
    </Stack>
  );
};

export default AccountsStatements;
