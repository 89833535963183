import { Auth } from 'aws-amplify';
import { UserAttributes } from '../networking/userInfo/User.types';
import { Authentication } from './Authentication.types';
import { isTestEnvironment } from '../utils/tests/helpers';
import { CognitoUser } from '@aws-amplify/auth';

export const getToken = async (): Promise<string> => {
  if (isTestEnvironment()) return '';

  return (await Auth.currentSession()).getIdToken().getJwtToken();
};

const getCurrentUser = async (username: string, password: string): Promise<any> => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch {
    return await Auth.signIn(username, password);
  }
};

export const fetchCognitoUser = (username: string, password: string): Promise<CognitoUser> => {
  return Auth.signIn(username, password);
};

export const setPreferredMFA = async (user: any): Promise<void> => {
  try {
    await Auth.setPreferredMFA(user, 'SMS_MFA');

    if (!user.challengeName) {
      console.log('No challengeName');
    }
  } catch (error) {
    console.log('Something bad happened: ', error);
  }
};

export const verifyUserMFA = async (user: any, code: string): Promise<Authentication> => {
  const authentication: Authentication = {
    isSuccess: false,
    bearerToken: '',
    challengeName: '',
    error: undefined
  };

  try {
    const loggedUser = await Auth.confirmSignIn(user, code, user.challengeName);

    authentication.bearerToken = loggedUser.signInUserSession.idToken.jwtToken;
    authentication.isSuccess = true;
  } catch (error) {
    authentication.isSuccess = false;
    authentication.error = error;
  }

  return authentication;
};

export const extractAuthentication = (loggedUser: any): Authentication => {
  const authentication: Authentication = {
    isSuccess: false,
    bearerToken: '',
    challengeName: '',
    error: undefined
  };

  try {
    authentication.bearerToken = loggedUser.signInUserSession.idToken.jwtToken;
    authentication.isSuccess = true;
  } catch (error) {
    authentication.isSuccess = false;
    authentication.error = error;
  }

  return authentication;
};

export const signIn = async (username: string, password: string): Promise<Authentication> => {
  const authentication: Authentication = {
    isSuccess: false,
    bearerToken: '',
    challengeName: undefined,
    error: undefined
  };

  try {
    const user = await Auth.signIn(username, password);
    authentication.isSuccess = true;

    if (
      user.challengeName === 'SMS_MFA' ||
      user.challengeName === 'SOFTWARE_TOKEN_MFA' ||
      user.preferredMFA === 'SMS_MFA'
    ) {
      authentication.challengeName = user.challengeName ?? user.preferredMFA;
    } else if (!user.challengeName || user.preferredMFA === 'NOMFA') {
      authentication.bearerToken = user.signInUserSession.idToken.jwtToken;
    }
  } catch (error) {
    authentication.isSuccess = false;
    authentication.error = error;
  }

  return authentication;
};

/**
 * Request a user's one time passcode
 * @param {String} username - The Cognito username/email
 * @param {String} temporaryPassword - The temporary code from cashMax
 * @param {UserAttributes} attributes - The attributes to be configured for the singed-in user.
 * More: https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#complete-new-password
 */
export const requestOneTimeCode = async (
  username: string,
  password: string,
  attributes: UserAttributes
): Promise<Authentication> => {
  const authentication: Authentication = {
    isSuccess: false,
    bearerToken: '',
    challengeName: undefined,
    error: undefined
  };
  try {
    const user = await getCurrentUser(username, password);
    await Auth.updateUserAttributes(user, attributes);
    for (const k in attributes) {
      Auth.verifyCurrentUserAttribute(k);
    }
    await setPreferredMFA(user);
    authentication.isSuccess = true;
  } catch (error) {
    authentication.error = error;
  }
  return authentication;
};

/**
 * Verify a user's attributes
 * @param {UserAttributes} attributes - The attributes to be verified for an already singed-in user.
 */
export const verifyAttributes = async (attributes: UserAttributes): Promise<Authentication> => {
  const authentication: Authentication = {
    isSuccess: false,
    bearerToken: '',
    challengeName: undefined,
    error: undefined
  };
  try {
    for (const k in attributes) {
      Auth.verifyCurrentUserAttributeSubmit(k, attributes[k]);
    }
    authentication.isSuccess = true;
  } catch (error) {
    authentication.error = error;
  }

  return authentication;
};

export const forgotPasswordSubmit = async (
  username: string,
  code: string,
  password: string
): Promise<Authentication> => {
  const authentication: Authentication = {
    isSuccess: false,
    bearerToken: '',
    challengeName: undefined,
    error: undefined
  };

  try {
    await Auth.forgotPasswordSubmit(username, code, password);
    authentication.isSuccess = true;
  } catch (error) {
    console.error(error);
    authentication.error = error;
  }

  return authentication;
};
export const verifyPassword = async (
  username: string,
  temporaryPassword: string,
  password: string
): Promise<Authentication> => {
  const authentication: Authentication = {
    isSuccess: false,
    bearerToken: '',
    challengeName: undefined,
    error: undefined
  };

  try {
    await signOut();
    const user = await getCurrentUser(username, temporaryPassword);
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      await Auth.completeNewPassword(user, password);
      authentication.isSuccess = true;
    }
  } catch (error) {
    authentication.error = error;
  }
  return authentication;
};

export const forgotPassword = async (email: string): Promise<void> => {
  try {
    await Auth.forgotPassword(email, { domain: 'cashvest.us' });
  } catch (error) {
    // NOOP - For security we don't want give away errors from Cognito.
  }
};

export const signOut = async (): Promise<void> => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('Failure to signOut: ', { error });
  }
};
