import { SetStateAction, createContext } from 'react';
import { AuthProvider, AuthState } from './AuthContext.types';
import Session from './Session.types';
import { UserInfo } from './interfaces/UserInfo.types';

export const AuthContext = createContext({} as AuthProvider);

export const EMPTY_AUTH_STATE: AuthState = {
  bearerToken: '',
  clientId: 0,
  elevatedUser: false,
  clientName: ''
};

export const EMPTY_USER_INFO: UserInfo = {
  role: null,
  userName: '',
  allClients: [],
  myClients: []
};

export const MOCK_AUTH_CONTEXT: AuthProvider = {
  authState: EMPTY_AUTH_STATE,
  setAuthState: function (value: SetStateAction<AuthState>) {
    throw new Error('Function not implemented.');
  },
  resetAuthState: function () {
    throw new Error('Function not implemented.');
  },
  session: Session.LoggedOut,
  setSession: function (value: SetStateAction<Session>): void {
    throw new Error('Function not implemented.');
  },
  userInfo: EMPTY_USER_INFO,
  setUserInfo: function (value: SetStateAction<UserInfo>): void {
    throw new Error('Function not implemented.');
  }
};
