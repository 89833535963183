import { createContext, useEffect, useMemo, useState } from 'react';
import { isPrivateRoute, isRealRoute } from '../routing/ROUTES';
import { RoutesEnum } from '../routing/types';
import { useLocation } from 'react-router-dom';
import { LOGGED_OUT_STATE, LandingURL, RoutingHistory } from './routingHistoryContext.types';

export const emptyRoutingHistory: RoutingHistory = {
  landingURL: null,
  setLandingURLToLoggedOutState: function () {
    throw new Error('Function not implemented.');
  },
  isLandingURLValid: function (): boolean {
    throw new Error('Function not implemented.');
  }
};

export const RoutingHistoryContext = createContext(emptyRoutingHistory);

export const useSaveLandingURL = () => {
  const [landingURL, setLandingURL] = useState(null as LandingURL);
  const { pathname: currentRoute } = useLocation();

  const setLandingURLToLoggedOutState = () => {
    setLandingURL(LOGGED_OUT_STATE);
  };

  const routingHistory: RoutingHistory = useMemo(() => {
    const isLandingURLValid = () => {
      return landingURL !== null && landingURL !== LOGGED_OUT_STATE;
    };

    return {
      landingURL,
      setLandingURLToLoggedOutState,
      isLandingURLValid
    };
  }, [landingURL]);

  useEffect(() => {
    if (routingHistory.landingURL !== null) return;
    if (!isRealRoute(currentRoute)) return;
    if (!isPrivateRoute(currentRoute as RoutesEnum)) return setLandingURLToLoggedOutState();

    setLandingURL(currentRoute as RoutesEnum);
  }, [routingHistory.landingURL, currentRoute]);

  return routingHistory;
};
