import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack
} from '@mui/material';
import React from 'react';
import { TPODialogPrimaryButton } from './UIElements';

export const TPO_DIALOG_DATA_TESTID = 'TPO_DIALOG_DATA_TESTID';

interface TPODialogProps {
  title: string;
  content: string;
  isOpen: boolean;
  handlePrimaryButton: () => void;
  textPrimaryButton: string;
  datatestid: string;
}

const TPODialogConfirmation = ({
  title,
  content,
  isOpen,
  handlePrimaryButton,
  textPrimaryButton,
  datatestid
}: TPODialogProps) => (
  <Dialog data-testid={datatestid} open={isOpen}>
    <DialogTitle
      sx={{
        color: 'text.primary',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '27px',
        textAlign: 'center',
        padding: '24px 0 0 0'
      }}
    >
      {title}
    </DialogTitle>
    <DialogContent sx={{ textAlign: 'center', width: '411px' }}>
      <DialogContentText
        sx={{
          fontSize: '16px',
          fontWeight: '400',
          lineHeight: '22px',
          color: 'text.primary',
          padding: '8px 8px 0'
        }}
      >
        {content}
      </DialogContentText>
      <DialogActions sx={{ marginTop: '20px' }}>
        <Stack sx={{ width: '100%' }} spacing={2}>
          <TPODialogPrimaryButton text={textPrimaryButton} onClick={handlePrimaryButton} />
        </Stack>
      </DialogActions>
    </DialogContent>
  </Dialog>
);

export default TPODialogConfirmation;
