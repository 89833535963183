import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import TPOTooltip from '../TPOTooltip/TPOTooltip';

interface ToolTipProps {
  label: string;
  title: string;
  content: string | null;
}

const ToolTip = (props: ToolTipProps) => {
  return (
    <TPOTooltip title={props.title} content={props.content}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <IconButton
          sx={{
            color: 'common.white',
            backgroundColor: 'primary.main',
            width: '16px',
            height: '16px'
          }}
        >
          <QuestionMarkIcon sx={{ fontSize: 16 }} />
        </IconButton>
        <Typography
          sx={{
            fontSize: '18px',
            color: 'primary.main',
            textDecoration: 'underline'
          }}
        >
          {props.label}
        </Typography>
      </Stack>
    </TPOTooltip>
  );
};

export default ToolTip;
