import { Stack, Typography, TextField, Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { handleChangeWith } from '../../common/functions/handleChangeWith';
import { hasContent } from '../../common/helpers';
import ICallbackProps from '../../common/interfaces/ICallbackProps';
import FormValidation, { IFormError } from '../../common/validation/formValidation';
import { tpoErrorColor } from '../../styles/constants';

const EnterYourEmail = (props: ICallbackProps) => {
  const { t } = useTranslation();
  const formValidation = FormValidation(t);

  const [email, setEmail] = useState('');
  const [formErrors, setFormErrors] = useState<IFormError<string>>({});

  const handlePasswordLink = () => {
    formValidation.validate('email', email);
    if (formValidation.isErrorsFree()) {
      props.onComplete(email);
    } else {
      setFormErrors({ ...formErrors, ...formValidation.errors() });
    }
  };

  return (
    <Stack
      spacing={1}
      sx={{
        width: '750px',
        marginTop: '10px',
        marginLeft: '45px',
        marginBottom: '120px'
      }}
    >
      <Typography
        sx={{
          fontSize: '45px',
          color: 'primary.main'
        }}
      >
        {t('login.forgottenPasswordTitle')}
      </Typography>

      <Typography
        sx={{
          fontSize: '18px',
          color: 'text.primary'
        }}
      >
        {t('login.forgottenPasswordMessage')}
      </Typography>

      <Typography
        sx={{
          fontSize: '18px',
          color: 'text.primary',
          paddingTop: '35px'
        }}
      >
        {t('login.email')}
      </Typography>

      <TextField
        variant="outlined"
        value={email}
        onChange={handleChangeWith(setEmail)}
        sx={{
          width: '540px',
          '& .MuiOutlinedInput-root.Mui-focused': {
            '& > fieldset': {
              borderColor: 'primary.main'
            }
          }
        }}
      />

      {hasContent(formErrors.email) && (
        <Typography
          sx={{
            fontSize: '18px',
            color: tpoErrorColor,
            paddingTop: '10px'
          }}
        >
          {formErrors.email}
        </Typography>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          paddingTop: '35px',
          alignItems: 'center'
        }}
      >
        <Button
          variant="contained"
          onClick={() => handlePasswordLink()}
          sx={{
            textTransform: 'none',
            borderColor: 'primary.main',
            color: '#ffffff',
            backgroundColor: 'primary.main',
            fontSize: '16px',
            paddingLeft: '30px',
            paddingRight: '30px'
          }}
        >
          {t('login.passwordLink')}
        </Button>

        <Button
          variant="outlined"
          onClick={props.onBack}
          sx={{
            borderColor: 'primary.main',
            color: 'primary.main',
            backgroundColor: '#ffffff',
            textTransform: 'none',
            fontSize: '16px',
            marginLeft: '20px'
          }}
        >
          {t('navigation.back')}
        </Button>
      </Box>
    </Stack>
  );
};

export default EnterYourEmail;
