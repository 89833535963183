import ReactGA from 'react-ga4';
import DEVELOPMENT_FLAGS from '../../developmentFlags';
import { UserInfo } from '../../common/interfaces/UserInfo.types';

interface EventConfig {
  page: string;
  title: string;
}

function init() {

  ReactGA.initialize([
    {
      trackingId: DEVELOPMENT_FLAGS.googleAnalyticsTrackingId ?? ''
    }
  ]);
}

function sendEvent(config: EventConfig) {
  const { page, title } = config;
  ReactGA.send({ hitType: 'pageview', page, title });
}

function sendLoginEvent(userInfo: UserInfo) {
  const { role, userName } = userInfo;
  ReactGA.event({
    category: 'User',
    action: 'User Logged In',
    label: `Role: ${role}, Username: ${userName}`
  });
}

export default {
  init,
  sendEvent,
  sendLoginEvent
};
