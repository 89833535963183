export const daysFrom = (start: Date, end: Date): string[] => {
  const days: string[] = [];
  for (let i: Date = new Date(start); i <= end; i.setDate(i.getDate() + 1)) {
    const newDate = new Date(i);
    if (newDate.getDate() === 1) {
      days.push(
        newDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          timeZone: 'UTC'
        })
      );
    } else {
      days.push('');
    }
  }
  return days;
};

export const weeksFrom = (start: Date, end: Date): string[] => {
  const days: string[] = [];
  for (let i: Date = new Date(start); i < end; i.setDate(i.getDate() + 1)) {
    const newDate = new Date(i);
    if (newDate.getDate() === 1) {
      days.push(
        newDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          timeZone: 'UTC'
        })
      );
    } else if (newDate.getDate() % 7 === 1) {
      days.push('');
    }
  }
  return days;
};

export const monthsFrom = (start: Date, end: Date): string[] => {
  const days: string[] = [];
  for (let i: Date = new Date(start); i < end; i.setDate(i.getDate() + 1)) {
    const newDate = new Date(i);
    if (newDate.getDate() === 1) {
      days.push(
        newDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          timeZone: 'UTC'
        })
      );
    } else if (newDate.getDate() % 12 === 1) {
      days.push('');
    }
  }
  return days;
};

export const balanceFrom = (start: number, end: number): number[] => {
  const balances: number[] = [];
  for (let i = 0; i <= 365; i++) {
    if (balances.length === 0) {
      balances.push(Math.floor(Math.random() * (end - start)) + start);
    } else {
      if (balances[balances.length - 1] >= end - 0.2) {
        balances.push(end - 0.4);
      } else if (balances[balances.length - 1] <= start + 0.2) {
        balances.push(start + 0.4);
      } else {
        balances.push(balances[balances.length - 1] + (Math.floor(Math.random() * 3) - 1) / 5);
      }
    }
  }
  return balances;
};
export const balance = (start: number): number[] => {
  const balances: number[] = [];
  for (let i = 0; i <= 365; i++) {
    balances.push(start);
  }
  return balances;
};

export const hasContent = (content: any) =>
  content && (content.length > 0 || Object.keys(content).length > 0);

export const currencyFormatting = (value: number, fractionDigits = 0): string => {
  return !!value
    ? value > 0
      ? value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: fractionDigits,
          minimumFractionDigits: fractionDigits
        })
      : `(${(-value).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: fractionDigits,
          minimumFractionDigits: fractionDigits
        })})`
    : '$0';
};

export const rateFormatting = (
  value: number,
  MinimumFractionDigits = 2,
  maximumFractionDigits = 3
): string => {
  maximumFractionDigits < MinimumFractionDigits && (MinimumFractionDigits = maximumFractionDigits);
  value === 100 && (MinimumFractionDigits = 0);

  const rate = !!value
    ? parseFloat(value.toFixed(2)).toLocaleString('en-US', {
        minimumFractionDigits: MinimumFractionDigits,
        maximumFractionDigits: maximumFractionDigits
      })
    : '0.00';
  return `${rate}%`;
};

export const daysUntilMaturityFormatting = (value: number): string => {
  return !!value ? (value === -1 ? 'Matured' : `${value} days`) : '--';
};

export const numberFormatting = (value: number, fractionDigits = 0): string => {
  return !!value ? value.toLocaleString('en-US', { minimumFractionDigits: fractionDigits }) : '0';
};

export const numberFormattingForTable = (value: number): string => {
  return value >= 1000000 ? numberFormatting(value / 1000000) + 'M' : numberFormatting(value);
};

export const repeatValue = (arrayLength: number, val: number) =>
  [...Array(arrayLength)].map((_val) => val);
