class Color {
  r: number;

  g: number;

  b: number;

  constructor(r: number, g: number, b: number) {
    this.r = r;
    this.g = g;
    this.b = b;
  }

  toString = () => `rgb(${this.r},${this.g},${this.b})`;

  getShades = () => {
    const max = Math.max(Math.max(this.r, Math.max(this.g, this.b)), 1);

    const step = 255 / (max * 7);

    const shades: string[] = [this.toString()];
    for (let i = 3; i < 15; i++) {
      const temp = new Color(this.r * step * i, this.g * step * i, this.b * step * i);
      shades.push(temp.toString());
    }

    return shades;
  };
}

const colorsPredefined: Color[] = [
  new Color(70, 0, 116),
  new Color(1, 85, 69),
  new Color(241, 179, 16),
  new Color(196, 0, 69),
  new Color(69, 112, 222),
  new Color(246, 148, 54),
  new Color(47, 31, 162),
  new Color(219, 108, 121)
];

const flattenColorsExtended = (colorsExtended: string[][]) => {
  const flatColorsExtended = [];
  for (let i = 0; i < colorsExtended[0].length; i++) {
    for (let j = 0; j < colorsExtended.length; j++) {
      flatColorsExtended.push(colorsExtended[j][i]);
    }
  }

  return flatColorsExtended;
};

const colorsExtended = flattenColorsExtended(colorsPredefined.map((color) => color.getShades()));
const colorsDefault = colorsPredefined.map((color) => color.toString());

export const getColors = (dataLength: number) =>
  dataLength > colorsPredefined.length ? colorsExtended : colorsDefault;
