import {
  isAuthorizedOneTimePasscode,
  isValidAuthCredentials,
  isValidOneTimePasscode,
  isValidPhoneNumber,
  validateEmail as isValidEmail,
  validatePassword as isValidPassword,
  validatePasswordSetup as isValidPasswordSetup
} from './validations';

export interface IFormError<T> {
  [k: string]: T;
}

type TranslateFunction = (key: string) => string;

const FormValidation = (t: TranslateFunction) => {
  let validationErrors: IFormError<string> = {};

  const validate = (key: string, value: string): IFormError<string> => {
    const error: IFormError<string> = {};

    error[key] = '';
    if (key === 'email' && !isValidEmail(value)) {
      error[key] = t('error.invalidEmail');
    }
    if (key === 'password' && !isValidPassword(value)) {
      error[key] = t('error.invalidPassword');
    }
    if (key === 'passwordSetup' && !isValidPasswordSetup(value)) {
      error[key] = t('error.invalidPasswordSetup');
    }
    if (key === 'auth' && !isValidAuthCredentials(value)) {
      error[key] = t('error.notAuthorized');
    }
    if (key === 'phoneNumber' && !isValidPhoneNumber(value)) {
      error[key] = t('error.invalidPhoneNumber');
    }
    if (key === 'otp' && !isValidOneTimePasscode(value)) {
      error[key] = t('error.invalidOtp');
    }
    if (key === 'otpAuth' && !isAuthorizedOneTimePasscode(value)) {
      error[key] = t('error.notAuthorizedOtp');
    }
    validationErrors = { ...validationErrors, ...error };
    return validationErrors;
  };

  const errors = (): IFormError<string> => {
    return validationErrors;
  };

  const isErrorsFree = () => {
    return Object.values(validationErrors).every((value) => value === '');
  };

  return { validate, isErrorsFree, errors };
};

export default FormValidation;
