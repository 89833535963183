import { getToken } from '../../auth/authenticator';
import { AuthState } from '../../common/AuthContext.types';
import DEVELOPMENT_FLAGS from '../../developmentFlags';
import { AnalysesReport } from './Analyses.types';
import axiosClient from '../axios-api';

export async function getAnalysis(authState: AuthState): Promise<AnalysesReport | {}> {
  let finalResponse = {
    reports: [],
    commentsReportURI: ''
  };

  if (DEVELOPMENT_FLAGS.isMock == 'true') {
    return require('../../mock/analysis/response.json');
  }

  const apiUrl = `/analysis?c_id=${authState.clientId}`;

  await axiosClient({
    url: apiUrl,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${await getToken()}`
    }
  })
    .then((response) => {
      finalResponse = response.data;
    })
    .catch((error) => console.log('An error has occured when fetching reports - ', error?.message));

  return finalResponse;
}
