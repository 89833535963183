import ApiConfig from '../../../apiConfig';
import { getToken } from '../../../auth/authenticator';
import { AuthState } from '../../../common/AuthContext.types';
import { PublishReportResponse } from '../Analyses.types';

export async function publishReport(
  authState: AuthState,
  reportId: number
): Promise<PublishReportResponse> {
  const apiUrl =
    ApiConfig.apiBaseUrl + `/analysis/publish?c_id=${authState.clientId}&r_id=${reportId}`;

  let result = {
    reportId: 0,
    publishedDate: '',
    published: false
  };
  await fetch(apiUrl, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${await getToken()}`,
      'Content-Type': 'application/json'
    }
  }).then(async (response) => {
    await response.json().then(async (data) => {
      await data;
      result = data;
    });
  });
  return result;
}
