import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TPOTableHeader from './TPOTableHeader';
import { TableSortLabel, Box, SxProps, Theme } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { visuallyHidden } from '@mui/utils';

export interface Header {
  label: string;
  id: string;
  showToolTip: boolean;
  toolTipHeader?: string | null;
  toolTipText?: string | null;
  numeric?: boolean;
}

interface SortingFunctions {
  order: 'asc' | 'desc';
  setOrder: (order: 'asc' | 'desc') => void;
  orderBy: string;
  setOrderBy: (property: any) => void;
}

interface TPOTableProps {
  headers: Header[];
  content: string[][];
  sortable?: SortingFunctions;
  center?: boolean;
  handleOpenEditPage?: ((investmentIndex: number) => void) | null;
}

const TPOTable = ({
  headers,
  content,
  sortable,
  center,
  handleOpenEditPage = null
}: TPOTableProps) => {
  const isAddInvestmentPage = handleOpenEditPage !== null;
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = sortable?.orderBy === property && sortable?.order === 'asc';
    sortable?.setOrder?.(isAsc ? 'desc' : 'asc');
    sortable?.setOrderBy?.(property);
  };

  const addInvestmentPageStyle = {
    fontSize: '16px',
    ...(center ? { textAlign: 'center' } : { textAlign: 'start' }),
    '& .MuiTableSortLabel-icon': {
      display: 'none'
    },
    color: 'common.black',
    fontWeight: 600,
    cursor: !!sortable ? 'pointer' : 'default',
    '& .MuiTableSortLabel-root:focus, .MuiTableSortLabel-root:hover': {
      color: 'common.black',
      cursor: !!sortable ? 'pointer' : 'default'
    },
    ' & .MuiTableSortLabel-root.Mui-active': {
      color: 'common.black',
      '& .MuiTableSortLabel-icon': {
        color: 'common.black',
        display: !!sortable ? 'inline-block' : 'none'
      }
    }
  } as SxProps<Theme>;

  const mainColorBackgroundStyle = {
    backgroundColor: 'primary.main',
    color: 'common.white',
    '& .MuiTableSortLabel-root:focus, .MuiTableSortLabel-root:hover': { color: 'common.white' },
    ' & .MuiTableSortLabel-root.Mui-active': {
      color: 'common.white',
      '& .MuiTableSortLabel-icon': {
        color: 'common.white',
        display: !!sortable ? 'inline-block' : 'none'
      }
    }
  } as SxProps<Theme>;

  const styleHeader = isAddInvestmentPage
    ? addInvestmentPageStyle
    : ({ ...addInvestmentPageStyle, ...mainColorBackgroundStyle } as SxProps<Theme>);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header, i) => (
              <TableCell
                key={`header ${i}`}
                sortDirection={sortable?.orderBy === header.id ? sortable?.order : false}
                sx={styleHeader}
              >
                <TableSortLabel
                  active={sortable?.orderBy === header.id}
                  direction={sortable?.orderBy === header.id ? sortable?.order : 'asc'}
                  onClick={createSortHandler(header.id)}
                >
                  <TPOTableHeader
                    label={header.label}
                    showToolTip={header.showToolTip}
                    toolTipHeader={header.toolTipHeader}
                    toolTipText={header.toolTipText}
                  />
                  {sortable?.orderBy === header.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {sortable?.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          sx={
            isAddInvestmentPage
              ? {
                  '& .MuiTableRow-root': {
                    transition: '0.2s ease-in-out all'
                  },
                  '& .MuiTableRow-root: hover': {
                    cursor: 'pointer'
                  },
                  '& .MuiTableRow-root: hover > *': {
                    backgroundColor: 'common.hover'
                  }
                }
              : {}
          }
        >
          {content.map((row, i) => (
            <TableRow
              hover
              onClick={handleOpenEditPage !== null ? () => handleOpenEditPage(i) : () => {}}
              key={`row - ${i}`}
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              {row.map((item, i) => (
                <TableCell
                  key={`cell - ${i}`}
                  sx={{
                    fontSize: '16px',
                    backgroundColor: 'common.white',
                    color: isAddInvestmentPage ? 'common.grey' : 'text.primary',
                    ...(center ? { textAlign: 'center' } : { textAlign: 'start' })
                  }}
                >
                  {item}
                </TableCell>
              ))}
              {isAddInvestmentPage && (
                <TableCell
                  sx={{
                    fontSize: '16px',
                    backgroundColor: 'common.white',
                    color: isAddInvestmentPage ? 'common.grey' : 'text.primary',
                    ...(center ? { textAlign: 'center' } : { textAlign: 'start' })
                  }}
                >
                  <ArrowForwardIosIcon fontSize="small" sx={{ color: 'primary.action' }} />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TPOTable;
