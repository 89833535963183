import { Box, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hasContent } from '../../common/helpers';
import { tpoErrorColor } from '../../styles/constants';
import FormValidation, { IFormError } from '../../common/validation/formValidation';
import TPOToolTipIcon from '../../components/TPOToolTipIcon/TPOToolTipIcon';
import TPOFormButton from '../../components/TPOFormButton/TPOFormButton';

const ValidateMFA = (props: any) => {
  const { t } = useTranslation();

  const [formErrors, setFormErrors] = useState<IFormError<string>>({});
  const [oneTimePasscode, setOneTimePasscode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const formValidation = FormValidation(t);

  const handleChangeWith =
    (setter: (value: string) => void) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormErrors({});
      setter(event.target.value.trim());
    };

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement> | null) => {
    // prevent form from reloading when submitted
    if (event) event.preventDefault();

    setIsLoading(true);
    formValidation.validate('otp', oneTimePasscode);
    if (formValidation.isErrorsFree()) {
      props
        .onComplete(oneTimePasscode)
        .then((response: string) => {
          formValidation.validate('otpAuth', response);
        })
        .finally(() => {
          setFormErrors({ ...formErrors, ...formValidation.errors() });
          setIsLoading(false);
        });
    } else {
      setFormErrors({ ...formErrors, ...formValidation.errors() });
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#ffffff'
      }}
    >
      <Stack spacing={1}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Typography
            sx={{
              fontSize: '18px',
              color: 'text.primary',
              paddingTop: '35px',
              marginRight: '10px'
            }}
          >
            {t('mfa.oneTimeCodeLabel')}
          </Typography>

          <TPOToolTipIcon
            toolTipHeader={t('mfa.oneTimeCodeLabel')}
            toolTipText={t('mfa.oneTimeCodeToolTip')}
          />
        </Box>
        <form onSubmit={handleOnSubmit}>
          <TextField
            variant="outlined"
            value={oneTimePasscode}
            onChange={handleChangeWith(setOneTimePasscode)}
            sx={{
              width: '540px',
              '& .MuiOutlinedInput-root.Mui-focused': {
                '& > fieldset': {
                  borderColor: 'primary.main'
                }
              }
            }}
          />

          {hasContent(formErrors.otp) && (
            <Typography
              sx={{
                fontSize: '18px',
                color: tpoErrorColor,
                paddingTop: '10px'
              }}
            >
              {formErrors.otp}
            </Typography>
          )}

          {hasContent(formErrors.otpAuth) && (
            <Typography
              sx={{
                fontSize: '18px',
                color: tpoErrorColor,
                paddingTop: '10px'
              }}
            >
              {formErrors.otpAuth}
            </Typography>
          )}
          <Stack direction={'row'} spacing={2} sx={{ paddingTop: '35px' }}>
            <TPOFormButton
              text={'Back'}
              onClick={() => {
                if (props.onBack) {
                  props.onBack();
                }
              }}
            />
            <TPOFormButton
              text={t('mfa.submit')}
              onClick={() => handleOnSubmit(null)}
              isLoading={isLoading}
              isSubmitType
            />
          </Stack>
        </form>
      </Stack>
    </Box>
  );
};

export default ValidateMFA;
