import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ToolTip from '../../../../components/ToolTip/ToolTip';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Stack,
  Typography
} from '@mui/material';
import {
  currencyFormatting,
  numberFormatting,
  numberFormattingForTable,
  rateFormatting
} from '../../../../common/helpers';
import { ReportContext } from '../../../../common/reportContext';

interface StrategicLiquidityTableProps {
  index: number;
}

interface StrategicLiquidityHeader {
  label: string;
  amount: string;
  showToolTip: boolean;
}

type headerLabel = {
  [key: string]: string;
};

export const StrategicLiquidityTable = ({ index }: StrategicLiquidityTableProps) => {
  const { t } = useTranslation();
  const { report } = useContext(ReportContext);

  const accountGroup = report.liquidityProficiency.liquidityProficiency[index];

  const amountsAboveZero = (dataItem: { amount: number }[]) => {
    return dataItem[1].amount > 0;
  };

  const strategicLiquidityLevels = Object.entries(accountGroup.strategicLiquidity.liquidityLevels);
  const filteredStrategicLiquidityLevels = strategicLiquidityLevels.filter(amountsAboveZero);

  const footnoteRate = accountGroup.footnote.rateName;
  const footnoteDate = new Date(accountGroup.footnote.reportEndDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC'
  });

  const headerLabels = {
    level1: 'LEVEL 1',
    level2: 'LEVEL 2',
    level3: 'LEVEL 3',
    level4: 'LEVEL 4',
    level5: 'LEVEL 5',
    cushion: 'Cushion',
    workingCapital: 'Working Capital',
    total: 'Total'
  };

  const { columnHeaders, columnHeadersTotals, columnHeaderTotalsStart } = Generate.headers(
    filteredStrategicLiquidityLevels
  );

  const rows = Generate.rows(filteredStrategicLiquidityLevels);

  return (
    <TableContainer sx={{ overflow: 'hidden' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ backgroundColor: 'primary.main' }} />
            {columnHeaders.map((item, i) => (
              <TableCell
                key={i}
                align="center"
                sx={{
                  backgroundColor: 'primary.main',
                  color: 'common.white'
                }}
              >
                <Stack spacing={0}>
                  <Typography>{item.label}</Typography>
                  <Typography sx={{ fontWeight: 550 }}>{`$${item.amount}`}</Typography>
                </Stack>
              </TableCell>
            ))}
            {columnHeadersTotals.map((item, i) => (
              <TableCell key={i} align="center">
                {!item.showToolTip && (
                  <Stack spacing={0}>
                    <Typography sx={{ fontWeight: 650 }}>{item.label}</Typography>
                    <Typography sx={{ fontWeight: 550 }}>{`$${item.amount}`}</Typography>
                  </Stack>
                )}
                {item.showToolTip && (
                  <>
                    <Stack direction="row" justifyContent="center" spacing={1}>
                      <Typography sx={{ fontWeight: 650 }}>{item.label}</Typography>
                      <ToolTip
                        title={t(
                          'cashVestAnalyses.liquidityProficiency.workingCapitalChartToolTipTitle'
                        )}
                        content={t(
                          'cashVestAnalyses.liquidityProficiency.workingCapitalChartToolTipContent'
                        )}
                        label=""
                      />
                    </Stack>
                    <Typography sx={{ fontWeight: 550 }}>{`$${item.amount}`}</Typography>
                  </>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(rows).map(([key, value]) => (
            <TableRow
              key={key}
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              <TableCell
                align="center"
                sx={{
                  color: 'text.primary',
                  padding: '16px 5px'
                }}
              >
                {key}
              </TableCell>

              {value.map((item, i) =>
                i >= columnHeaderTotalsStart ? (
                  <TableCell
                    align="center"
                    key={i}
                    sx={{
                      color: 'text.primary',
                      padding: '16px 5px'
                    }}
                  >
                    {item}
                  </TableCell>
                ) : (
                  <TableCell
                    key={i}
                    align="center"
                    sx={{
                      backgroundColor: 'primary.light',
                      color: 'text.primary',
                      padding: '16px 5px'
                    }}
                  >
                    {item}
                  </TableCell>
                )
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Typography
        sx={{
          marginTop: '8px',
          marginLeft: '16px',
          color: 'text.primary'
        }}
      >
        {`* ${footnoteRate} as of ${footnoteDate}`}
      </Typography>
    </TableContainer>
  );
};

export default StrategicLiquidityTable;

export class Generate {
  static rows(filteredStrategicLiquidityLevels: [string, any][]) {
    const durations = filteredStrategicLiquidityLevels.map((level) => level[1].duration);
    const benchmarkRates = filteredStrategicLiquidityLevels.map((level) =>
      rateFormatting(level[1].benchmarkRate * 100)
    );
    const benchmarkValues = filteredStrategicLiquidityLevels.map((level) =>
      currencyFormatting(level[1].benchmarkValue)
    );

    return {
      Duration: durations,
      'Benchmark Rates *': benchmarkRates,
      'Benchmark Values': benchmarkValues
    };
  }

  static headers(filteredStrategicLiquidityLevels: [string, any][]) {
    {
      const headerLabels: headerLabel = {
        level1: 'LEVEL 1',
        level2: 'LEVEL 2',
        level3: 'LEVEL 3',
        level4: 'LEVEL 4',
        level5: 'LEVEL 5',
        cushion: 'Cushion',
        workingCapital: 'Working Capital',
        total: 'Total'
      };

      const headers: StrategicLiquidityHeader[] = [];

      filteredStrategicLiquidityLevels.forEach((dataItem) => {
        headers.push({
          label: headerLabels[dataItem[0]],
          amount:
            dataItem[0] == 'workingCapital' || dataItem[0] == 'total'
              ? numberFormatting(dataItem[1].amount)
              : numberFormattingForTable(dataItem[1].amount),
          showToolTip: dataItem[0] == 'workingCapital' ? true : false
        });
      });

      const columnHeaderTotalsStart = headers.length - 2;
      const columnHeaders = headers.slice(0, columnHeaderTotalsStart);
      const columnHeadersTotals = headers.slice(columnHeaderTotalsStart, headers.length);

      return {
        columnHeaderTotalsStart,
        columnHeaders,
        columnHeadersTotals
      };
    }
  }
}
