import { Box } from '@mui/material';
import React from 'react';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <Box
      sx={{
        display: 'flex'
      }}
    >
      <Box
        sx={{
          maxWidth: '1200px',
          margin: 'auto auto',
          minHeight: '100vh'
        }}
      >
        <div className="no-print">
          <Navbar />
        </div>
        {children}
        <footer className="no-print">
          <Footer />
        </footer>
      </Box>
    </Box>
  );
};

export default Layout;
