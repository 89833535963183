const BASE_URL = process.env.REACT_APP_API_BASE_URL ?? '';

const ApiConfig = {
  apiBaseUrl: BASE_URL + '/api',
  apiMaxCacheAge: process.env.REACT_APP_MAX_CACHE_AGE_SECONDS
    ? Number(process.env.REACT_APP_MAX_CACHE_AGE_SECONDS)
    : 900
};

export default ApiConfig;
