import React, { useState, useContext } from 'react';
import DropdownSummary from '../DropdownSummary/DropdownSummary';
import BaseSummary from '../BaseSummary/BaseSummary';
import Stack from '@mui/material/Stack';
import BankByBankAnalyses from './BankByBankAnalysis/BankByBankAnalysis';
import { useTranslation } from 'react-i18next';
import { CommentsContext } from '../../../common/commentsContext';
import { ReportContext } from '../../../common/reportContext';

const AccountOptimization = () => {
  const { t } = useTranslation();

  const { report } = useContext(ReportContext);

  const options: string[] = report.accountOptimization.groups.map((obj) => {
    return obj.accountGroup === 'All Accounts' ? 'Bank by Bank' : obj.accountGroup;
  });

  const [index, setIndex] = useState(0);

  const { comments, setComments } = useContext(CommentsContext);

  return (
    <Stack spacing={3}>
      <DropdownSummary
        title={t('cashVestAnalyses.accountOptimization.selectAccountGroupLabel')}
        options={options}
        index={index}
        setIndex={setIndex}
      >
        <BaseSummary
          title={t('cashVestAnalyses.accountOptimization.title')}
          content={t('cashVestAnalyses.accountOptimization.summary')}
          // content={comments.accountOptimisation.summary}
          setContent={(content) => {
            const newComments = { ...comments };
            newComments.accountOptimisation.summary = content;
            setComments(newComments);
          }}
        />
      </DropdownSummary>
      <BankByBankAnalyses selectedAccountGroupIndex={index} />
    </Stack>
  );
};

export default AccountOptimization;
