import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, TextField, Typography } from '@mui/material';
import { tpoErrorColor } from '../../styles/constants';
import { ROUTES } from '../../routing/ROUTES';
import TPOToolTipIcon from '../../components/TPOToolTipIcon/TPOToolTipIcon';
import FormValidation, { IFormError } from '../../common/validation/formValidation';
import { hasContent } from '../../common/helpers';
import { useNavigate } from 'react-router-dom';
import TPOFormButton from '../../components/TPOFormButton/TPOFormButton';
import { fetchCognitoUser } from '../../auth/authenticator';
import type { CognitoUser } from '@aws-amplify/auth';

export const USERNAME_INPUT_DATA_TESTID = 'USERNAME_INPUT_DATA_TESTID';
export const PASSWORD_INPUT_DATA_TESTID = 'PASSWORD_INPUT_DATA_TESTID';

const UserAuthentication = ({
  onComplete
}: {
  onComplete: (user: CognitoUser, username: string, password: string) => Promise<void>;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [usernameInput, setUsernameInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [loginErrors, setLoginErrors] = useState<IFormError<string>>({});
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formValidation = FormValidation(t);

  const handleChangeWith =
    (setter: (value: string) => void) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setLoginErrors({});
      setter(event.target.value);
    };

  const handleLogin = async (event: React.FormEvent<HTMLFormElement> | null) => {
    // prevent form from reloading when submitted
    if (event) event.preventDefault();

    setIsLoading(true);
    const username = usernameInput.trim();
    const password = passwordInput.trim();
    formValidation.validate('email', username);
    formValidation.validate('password', password);
    if (formValidation.isErrorsFree()) {
      fetchCognitoUser(username, password)
        .then((user) => onComplete(user, username, password))
        .catch((error) => formValidation.validate('auth', error))
        .finally(() => {
          setLoginErrors({ ...loginErrors, ...formValidation.errors() });
          setIsLoading(false);
        });
    } else {
      setLoginErrors({ ...loginErrors, ...formValidation.errors() });
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <Typography
          sx={{
            fontSize: '18px',
            color: 'text.primary',
            paddingTop: '35px',
            marginRight: '10px'
          }}
        >
          {t('login.username')}
        </Typography>
        <TPOToolTipIcon
          toolTipHeader={t('login.username')}
          toolTipText={t('login.usernameToolTip')}
        />
      </Box>
      <form onSubmit={handleLogin}>
        <TextField
          variant="outlined"
          value={usernameInput}
          onChange={handleChangeWith(setUsernameInput)}
          sx={{
            width: '540px',
            '& .MuiOutlinedInput-root.Mui-focused': {
              '& > fieldset': {
                borderColor: 'primary.main'
              }
            }
          }}
          inputProps={{ 'data-testid': USERNAME_INPUT_DATA_TESTID }}
        />

        {hasContent(loginErrors.email) && (
          <Typography
            sx={{
              fontSize: '18px',
              color: tpoErrorColor,
              paddingTop: '10px'
            }}
          >
            {loginErrors.email}
          </Typography>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Typography
            sx={{
              fontSize: '18px',
              color: 'text.primary',
              paddingTop: '35px',
              marginRight: '10px'
            }}
          >
            {t('login.password')}
          </Typography>
          <TPOToolTipIcon
            toolTipHeader={t('login.password')}
            toolTipText={t('login.passwordToolTip')}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <TextField
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            value={passwordInput}
            onChange={handleChangeWith(setPasswordInput)}
            sx={{
              width: '540px',
              marginRight: '20px',
              '& .MuiOutlinedInput-root.Mui-focused': {
                '& > fieldset': {
                  borderColor: 'primary.main'
                }
              }
            }}
            inputProps={{ 'data-testid': PASSWORD_INPUT_DATA_TESTID }}
          />

          <Typography
            onClick={() => setShowPassword(!showPassword)}
            sx={{
              fontSize: '18px',
              color: 'primary.main',
              cursor: 'pointer'
            }}
          >
            {showPassword ? t('login.hidePassword') : t('login.showPassword')}
          </Typography>
        </Box>

        {hasContent(loginErrors.password) && (
          <Typography
            sx={{
              fontSize: '18px',
              color: tpoErrorColor,
              paddingTop: '10px'
            }}
          >
            {loginErrors.password}
          </Typography>
        )}

        {hasContent(loginErrors.auth) && (
          <Typography
            sx={{
              fontSize: '18px',
              color: tpoErrorColor,
              paddingTop: '10px'
            }}
          >
            {loginErrors.auth}
          </Typography>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '35px',
            alignItems: 'center'
          }}
        >
          <TPOFormButton
            text={t('login.loginButton')}
            onClick={() => handleLogin(null)}
            isLoading={isLoading}
            isSubmitType
          />

          <Typography
            onClick={() => navigate(ROUTES.ForgottenPassword)}
            sx={{
              textDecoration: 'underline',
              fontSize: '16px',
              color: 'primary.main',
              cursor: 'pointer',
              marginLeft: '20px'
            }}
          >
            {t('login.forgottenPasswordLink')}
          </Typography>
        </Box>
      </form>
    </>
  );
};

export default UserAuthentication;
