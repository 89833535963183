import React from 'react';
import { Line } from 'react-chartjs-2';
import type { ChartOptions, Tick } from 'chart.js';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';
import TPOLabel from '../../../../components/TPOLabel/TPOLabel';
import ToolTip from '../../../../components/ToolTip/ToolTip';
import { numberFormatting } from '../../../../common/helpers';
import { getColors } from '../../../../common/colorUtils';
import { DateArrayItem } from './AccountGraph.types';

interface AccountGraphProps {
  balances: InstitutionBalances[];
  endDate: string;
}

const createDateArray = (endingDate: string): DateArrayItem[] => {
  const endDate = new Date(endingDate);
  const oneYearBefore = new Date(endingDate);
  oneYearBefore.setFullYear(oneYearBefore.getFullYear() - 1);

  const dateArray: DateArrayItem[] = [];
  while (oneYearBefore <= endDate) {
    oneYearBefore.setDate(oneYearBefore.getDate() + 1);
    const localeDateString = new Date(oneYearBefore);
    dateArray.push({
      dateTime: localeDateString,
      value: null
    });
  }

  return dateArray;
};

const AccountGraph = ({ balances, endDate }: AccountGraphProps) => {
  const options: ChartOptions<'line'> = {
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 20,
        position: 'right',
        stacked: true,
        ticks: {
          callback: (tickValue: string | number, index: number, ticks: Tick[]) =>
            numberFormatting(+tickValue)
        }
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          autoSkip: false,
          callback: function (val, index) {
            // Only display ticks that are the first of the month
            const label = this.getLabelForValue(Number(val));
            return new Date(label).getDate() === 1 ? this.getLabelForValue(Number(val)) : '';
          }
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return tooltipItem.dataset.label
              ? tooltipItem.dataset.label + `: $${tooltipItem.formattedValue}`
              : `$${tooltipItem.formattedValue}`;
          }
        }
      }
    },
    elements: {
      point: {
        radius: 0
      }
    },
    interaction: {
      mode: 'index'
    }
  };

  const { t } = useTranslation();

  const getInstitutionData = (institutionBalance: InstitutionBalances['balances']) => {
    const dateArray = createDateArray(endDate);
    let institutionData: { dateTime: Date; value: number | null }[] = institutionBalance.map(
      (balance) => {
        return {
          dateTime: new Date(balance.dateTime),
          value: balance.dailyBalanceTotal
        };
      }
    );

    const dates = new Set(institutionData.map((d) => d.dateTime.toDateString()));

    institutionData = [
      ...institutionData,
      ...dateArray.filter((d) => {
        return !dates.has(d.dateTime.toDateString());
      })
    ];

    // We need to sort the array the chart displays the values in order of appearance without parsing the values
    institutionData.sort((a, b) => a.dateTime.getTime() - b.dateTime.getTime());

    return institutionData;
  };

  const generateData = (institutionBalance: InstitutionBalances['balances']) => {
    const institutionData = getInstitutionData(institutionBalance);

    const data = institutionData.map((balance) => {
      return balance.value;
    });

    return data;
  };

  const generateLabels = (institutionBalance: InstitutionBalances['balances']) => {
    const institutionData = getInstitutionData(institutionBalance);

    const labels = institutionData.map((balance) => {
      return balance.dateTime.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: 'UTC'
      });
    });
    return labels;
  };

  const colors = getColors(balances.length);

  const datasets = balances
    ? balances.map((institutionBalances, index) => {
        const color = colors[index];
        return {
          label: institutionBalances.institutionName,
          data: generateData(institutionBalances.balances),
          fill: true,
          backgroundColor: color,
          borderColor: color
        };
      })
    : [];

  const labels = balances.length ? generateLabels(balances[0].balances) : [];

  const data = {
    labels,
    datasets
  };

  return (
    <Box
      sx={{
        padding: 5,
        backgroundColor: '#ffffff'
      }}
    >
      <Stack spacing={3}>
        <Typography
          align="right"
          sx={{
            fontSize: '18px',
            color: 'text.primary',
            fontWeight: 'bold'
          }}
        >
          {t('listOfAccounts.chartHeader')}
        </Typography>

        <Line data={data} options={options} />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}
        >
          {balances &&
            balances.map((balance, index) => (
              <TPOLabel key={index} text={balance.institutionName} color={colors[index]} />
            ))}
        </Box>
        <ToolTip
          title={t('listOfAccounts.chartToolTipTitle')}
          content={t('listOfAccounts.chartToolTipContent')}
          label={t('common.explainChart')}
        />
      </Stack>
    </Box>
  );
};

export default React.memo(AccountGraph);
