import React from 'react';

import { Button, SxProps, CircularProgress } from '@mui/material';

const COMMON_BUTTON_STYLES = {
  fontSize: '16px',
  minWidth: '110px',
  padding: '15px 0',
  textTransform: 'none'
} as SxProps;

export const DRAWER_FILLED_BUTTON_DATA_TESTID = 'DRAWER_FILLED_BUTTON_DATA_TESTID';

interface DrawerFilledSubmitButtonProps {
  children: React.ReactChild;
  onClick?: (() => void) | null;
  disabled?: boolean;
}

/**
 * Submit Button for the form and Added pages.
 * If the onClick is passed, that means the button is not of type submit an can be used outside the form.
 */
export const DrawerFilledSubmitButton = ({
  children,
  onClick = null,
  disabled = false
}: DrawerFilledSubmitButtonProps) => {
  const styles = {
    borderColor: 'tertiary.main',
    color: 'common.white',
    backgroundColor: 'tertiary.main',
    '&:hover': { backgroundColor: 'tertiary.main' },
    ...COMMON_BUTTON_STYLES
  } as SxProps;
  return (
    <>
      {onClick === null ? (
        <Button
          type="submit"
          variant="contained"
          sx={styles}
          disabled={disabled}
          data-testid={DRAWER_FILLED_BUTTON_DATA_TESTID}
        >
          {children}
        </Button>
      ) : (
        <Button
          type="button"
          onClick={onClick}
          variant="contained"
          sx={styles}
          disabled={disabled}
          data-testid={DRAWER_FILLED_BUTTON_DATA_TESTID}
        >
          {children}
        </Button>
      )}
    </>
  );
};

export const DRAWER_OUTLINED_BUTTON_DATA_TESTID = 'DRAWER_OUTLINED_BUTTON_DATA_TESTID';

interface DrawerOutlinedButtonProps {
  onClick: () => void;
  text: string;
  disabled?: boolean;
  isLoading?: boolean;
}

/**
 * Alternative button that only fires when clicked.
 * Styled with outline colour only
 */
export const DrawerOutlinedButton = ({
  onClick,
  text,
  disabled = false,
  isLoading = false
}: DrawerOutlinedButtonProps) => {
  return (
    <Button
      variant="outlined"
      onClick={onClick}
      sx={{
        borderColor: 'common.greyBorder',
        color: 'common.grey',
        borderWidth: '2px',
        '&:hover': { borderWidth: '2px', borderColor: 'tertiary.main' },
        ...COMMON_BUTTON_STYLES
      }}
      disabled={disabled}
      data-testid={DRAWER_OUTLINED_BUTTON_DATA_TESTID}
    >
      {isLoading ? (
        <CircularProgress sx={{ width: '28px!important', height: '28px!important' }} />
      ) : (
        text
      )}
    </Button>
  );
};

export const DRAWER_CANCEL_BUTTON_DATA_TESTID = 'DRAWER_CANCEL_BUTTON_DATA_TESTID';

interface DrawerCancelButtonProps {
  onClick: () => void;
  text: string;
  disabled?: boolean;
}

/**
 * Cancel Button.
 * Styled to be transparent.
 */
export const DrawerCancelButton = ({
  text,
  onClick,
  disabled = false
}: DrawerCancelButtonProps) => {
  return (
    <Button
      data-testid={DRAWER_CANCEL_BUTTON_DATA_TESTID}
      onClick={onClick}
      sx={{
        color: 'tertiary.main',
        '&:hover': { backgroundColor: 'transparent' },
        ...COMMON_BUTTON_STYLES
      }}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export const DrawerEditPageDeleteButton = ({ text, onClick }: DrawerCancelButtonProps) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        color: 'common.error',
        textDecoration: 'underline',
        '&:hover': {
          backgroundColor: 'transparent',
          textDecoration: 'underline'
        },
        ...COMMON_BUTTON_STYLES
      }}
    >
      {text}
    </Button>
  );
};

interface TPODialogButtonProps {
  text: string;
  onClick: () => void;
}

export const TPODialogPrimaryButton = ({ text, onClick }: TPODialogButtonProps) => {
  const styles = {
    borderColor: 'primary.main',
    color: 'common.white',
    backgroundColor: 'primary.main',
    '&:hover': { backgroundColor: 'primary.main' },
    ...COMMON_BUTTON_STYLES
  } as SxProps;
  return (
    <Button type="button" onClick={onClick} variant="contained" sx={styles}>
      {text}
    </Button>
  );
};

export const TPODialogSecondaryButton = ({ text, onClick }: TPODialogButtonProps) => {
  const styles = {
    borderColor: 'primary.main',
    color: 'primary.main',
    borderWidth: '2px',
    '&:hover': { borderWidth: '2px', borderColor: 'primary.main' },
    ...COMMON_BUTTON_STYLES
  } as SxProps;

  return (
    <Button type="button" onClick={onClick} variant="outlined" sx={styles}>
      {text}
    </Button>
  );
};
