import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Box, Typography, Select, SelectChangeEvent, MenuItem } from '@mui/material';
import PaperSection from '../../../../components/PaperSection/PaperSection';
import ToolTip from '../../../../components/ToolTip/ToolTip';
import EditTextField from '../../../../components/EditTextField/EditTextField';
import CurrentStatusChart from './CurrentStatusChart';
import { ReportContext } from '../../../../common/reportContext';
import { CommentsContext } from '../../../../common/commentsContext';
import BalanceSummary from '../BalanceSummary/BalanceSummary';
import TPOPaper from '../../../../components/TPOPaper/TPOPaper';

const CurrentStatus = () => {
  const { report } = useContext(ReportContext);
  const [index, setIndex] = useState(0);
  const { t } = useTranslation();

  const options = report.cashBoard.yearOverYearChange.map(
    (accountGroup) => accountGroup.accountGroup
  );

  const handleChange = (event: SelectChangeEvent) => {
    setIndex(+event.target.value);
  };

  const { comments, setComments } = useContext(CommentsContext);

  return (
    <>
      <PaperSection title="Current status and year-over-year change">
        <Stack spacing={2}>
          <Stack spacing={2}>
            <Typography
              sx={{
                color: 'text.primary',
                fontSize: '18px',
                fontWeight: 650
              }}
            >
              {t('common.cashVestAnalysisHeading')}
            </Typography>
            <EditTextField
              text={comments.cashBoard.yearOverYear}
              setText={(text) => {
                const newComments = { ...comments };
                newComments.cashBoard.yearOverYear = text;
                setComments(newComments);
              }}
            />
          </Stack>

          <Stack>
            <Box
              sx={{
                marginBottom: '30px'
              }}
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  color: 'text.primary',
                  fontWeight: 650,
                  marginBottom: '10px'
                }}
              >
                {t('common.selectAccountGroupLabel')}
              </Typography>

              <Select
                value={String(index)}
                onChange={handleChange}
                sx={{
                  backgroundColor: '#ffffff',
                  flexGrow: 1,
                  fontSize: '16px',
                  width: '350px',
                  height: '40px'
                }}
              >
                {options.map((option, i) => (
                  <MenuItem value={i} key={i}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <CurrentStatusChart selectedAccountGroupIndex={index} />
          </Stack>

          <ToolTip
            title={t('cashVestAnalyses.cashBoard.annualBalanceChartToolTipTitle')}
            content={t('cashVestAnalyses.cashBoard.annualBalanceChartToolTipContent')}
            label={t('common.explainChart')}
          />
        </Stack>
      </PaperSection>
      <TPOPaper color="rgba(70, 0, 116, 0.3)">
        <BalanceSummary selectedAccountGroupIndex={index} />
      </TPOPaper>
    </>
  );
};

export default CurrentStatus;
