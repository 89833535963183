import React, { useContext } from 'react';
import PaperSection from '../../../../components/PaperSection/PaperSection';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, Box } from '@mui/material';
import BalancesByBankChart from './BalancesByBankChart';
import AnnualBalancesTable from './AnnualBalancesTable';
import EditTextField from '../../../../components/EditTextField/EditTextField';
import { CommentsContext } from '../../../../common/commentsContext';
import { ReportContext } from '../../../../common/reportContext';
import ToolTip from '../../../../components/ToolTip/ToolTip';
import TPOPaper from '../../../../components/TPOPaper/TPOPaper';

interface BankByBankAnalysisProps {
  selectedAccountGroupIndex: number;
}

const BankByBankAnalysis = (props: BankByBankAnalysisProps) => {
  const { t } = useTranslation();

  const { report } = useContext(ReportContext);

  const accountGroup = report.accountOptimization.groups[props.selectedAccountGroupIndex];

  const { comments, setComments } = useContext(CommentsContext);

  const getSubtitle = (): string => {
    return accountGroup.accountGroup === 'All Accounts'
      ? 'Bank by Bank Analysis'
      : accountGroup.accountGroup;
  };

  return (
    <PaperSection
      title={t('cashVestAnalyses.accountOptimization.bankByBankAnalysisTitle')}
      subtitle={getSubtitle()}
    >
      <Stack spacing={2}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '700px auto',
            alignItems: 'end',
            justifyContent: 'space-between'
          }}
        >
          <Stack spacing={2}>
            <Typography
              sx={{
                color: 'text.primary',
                fontSize: '18px',
                fontWeight: 650
              }}
            >
              {t('common.cashVestAnalysisHeading')}
            </Typography>
            <EditTextField
              text={comments.accountOptimisation.accountAnalysis}
              setText={(text) => {
                const newComments = { ...comments };
                newComments.accountOptimisation.accountAnalysis = text;
                setComments(newComments);
              }}
            />
          </Stack>
        </Box>
        <TPOPaper color="rgba(70, 0, 116, 0.3)">
          <Stack spacing={3} padding={3}>
            <BalancesByBankChart selectedAccountGroupIndex={props.selectedAccountGroupIndex} />
            <Stack direction={'row'} spacing={2}>
              <ToolTip
                title={t('cashVestAnalyses.accountOptimization.bankByBankChartToolTipTitle')}
                content={t('cashVestAnalyses.accountOptimization.bankByBankChartToolTipContent')}
                label={t('common.explainChart')}
              />
            </Stack>
          </Stack>
        </TPOPaper>
        <AnnualBalancesTable selectedAccountGroupIndex={props.selectedAccountGroupIndex} />
      </Stack>
    </PaperSection>
  );
};

export default BankByBankAnalysis;
