import { Divider, Stack, TableCell, Typography } from '@mui/material';
import React from 'react';

export interface SubcategoryItemProps {
  content: string[];
  isSubcategoryTitle: boolean;
}

export const SubcategoryItem = (props: SubcategoryItemProps) => {
  return (
    <TableCell align={props.isSubcategoryTitle ? 'center' : 'right'}>
      {props.content.map((item, i) => (
        <Stack key={i}>
          {!!i && <Divider light sx={{ paddingTop: '8px', marginBottom: '16px' }} />}
          <Typography>{item}</Typography>
        </Stack>
      ))}
    </TableCell>
  );
};
