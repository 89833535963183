import { CashBoard } from '../../CashBoard.types';

interface FinancialRelationshipSummary {
  bankingRelationships: number;
  bankingRelationshipsAccounts: number;
  investmentRelationships: number;
  investmentRelationshipsAccounts: number;
  changeInBankingAccounts: number;
  changeInInvestmentAccounts: number;
}

interface LiquidityBreakdownAccounts {
  accounts: number;
  periodInterest: number;
  yieldPercent: number;
  averageBalance: number;
  percentOffunds: number;
}

interface LiquidityBreakdown {
  allAccounts: LiquidityBreakdownAccounts;
  noYieldLiquidDollars: LiquidityBreakdownAccounts;
  liquidDollars: LiquidityBreakdownAccounts;
  fixedIncome: LiquidityBreakdownAccounts;
}

interface AccountYearData {
  amount: number;
  date: string;
}

interface AccountGroupBalance {
  currentYear: AccountYearData[];
  previousYear: AccountYearData[];
  beforeLastYear: AccountYearData[];
}

interface YearOverYearChange {
  accountGroup: string;
  summary: string;
  accountGroupBalances: AccountGroupBalance;
  averageBalances: AverageBalances;
  periodAverageBalance: AverageBalanceDetail;
}

export interface AverageBalanceDetail {
  previous: number;
  current: number;
  difference: number;
  endDate: string;
  startDate: string;
}

interface AverageBalances {
  trailingTwelveMonthsAverageBalance: AverageBalanceDetail;
}

export interface CashBoardV2 {
  financialRelationshipSummary: FinancialRelationshipSummary;
  liquidityBreakdown: LiquidityBreakdown;
  yearOverYearChange: YearOverYearChange[];
}

/*
        Version 2 of the report does not exist yet!!! 
        ReportV2 is created as a placeholder and show-how for future versions. 
        This code is to be removed once new version comes.
    */

export function cashBoardV2ToBase(cashBoardV2: CashBoardV2): CashBoard {
  return {
    financialRelationshipSummary: cashBoardV2.financialRelationshipSummary,
    liquidityBreakdown: cashBoardV2.liquidityBreakdown,
    yearOverYearChange: cashBoardV2.yearOverYearChange.map((value) => {
      return {
        accountGroup: value.accountGroup,
        summary: value.summary,
        accountGroupBalances: value.accountGroupBalances,
        averageBalances: {
          trailingTwelveMonthsAverageBalance:
            value.averageBalances.trailingTwelveMonthsAverageBalance,
          periodAverageBalance: value.periodAverageBalance
        }
      };
    })
  };
}
