import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Stack } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import Divider from '@mui/material/Divider';
import EditTextField from '../../../components/EditTextField/EditTextField';

interface ScorecardSectionTextfieldProps {
  text: string;
  setText: (text: string) => void;
}

interface ScorecardSectionProps {
  score: number;
  impact: number | 'NA';
  summary: ScorecardSectionTextfieldProps;
  reason1: ScorecardSectionTextfieldProps;
  reason2: ScorecardSectionTextfieldProps;
  readonly?: boolean;
}

const ScorecardSection = (props: ScorecardSectionProps) => {
  const { t } = useTranslation();
  const initialReport = props.impact === 'NA';
  const noChange = props.impact == 0;
  const downFromPrevious = props.impact < 0;

  const starSx = { color: '#f1b310', fontSize: '30px' };
  const color = () => {
    return noChange ? '#00000' : downFromPrevious ? '#d9001b' : '#015545';
  };
  const impact = () => {
    return initialReport ? '' : downFromPrevious || noChange ? props.impact : `+${props.impact}`;
  };
  const impactText = () => {
    return initialReport
      ? t('cashVestAnalyses.scoreCard.impactText.initial')
      : noChange
      ? t('cashVestAnalyses.scoreCard.impactText.unchanged')
      : downFromPrevious
      ? t('cashVestAnalyses.scoreCard.impactText.down')
      : t('cashVestAnalyses.scoreCard.impactText.up');
  };
  const arrow = () => {
    return initialReport || noChange ? '' : downFromPrevious ? '⬇ ' : '⬆ ';
  };
  const calculateStars = (): Array<any> => {
    const stars: Array<any> = [];

    let fullStars = 5;
    if (props.score <= 1.5) {
      fullStars = Math.floor(props.score);
    } else if (props.score > 1 && props.score < 5) {
      fullStars = Math.floor(props.score);
    }

    for (let i = 0; i < fullStars; i++) {
      stars.push(1);
    }
    const halfStar = props.score - Math.floor(props.score);
    for (let i = 0; i < halfStar; i++) {
      stars.push(0.5);
    }
    const emptyStars = 5 - Math.ceil(props.score);
    for (let i = 0; i < emptyStars; i++) {
      stars.push(0);
    }
    return stars;
  };

  return (
    <Stack
      spacing={2}
      direction={'row'}
      sx={{ padding: 2 }}
      divider={<Divider orientation="vertical" flexItem />}
    >
      <Stack alignContent={'center'}>
        <Stack spacing={0} direction={'row'}>
          {calculateStars().map((s, i) => (
            <Box key={i}>
              {s == 1 && <StarIcon sx={starSx} key={`Full ${i}`} />}
              {s == 0.5 && <StarHalfIcon sx={starSx} key={`Half ${i}`} />}
              {s == 0 && <StarOutlineIcon sx={starSx} key={`Outline ${i}`} />}
            </Box>
          ))}
        </Stack>
        <Box marginTop={'30px'}>
          <Typography
            sx={{
              textAlign: 'center',
              ...{ color: color() },
              fontSize: '20px'
            }}
          >
            {impact()}
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              textAlign: 'center',
              ...{ color: color() },
              fontSize: '20px'
            }}
          >
            {arrow()}
            {impactText()}
          </Typography>
        </Box>
      </Stack>

      <Stack spacing={2} sx={{ fontSize: '16px', flexGrow: 1 }}>
        <Typography sx={{ fontWeight: 550 }}>{t('cashVestAnalyses.scoreCard.summary')}</Typography>
        {props.readonly === undefined || props.readonly === false ? (
          <EditTextField text={props.summary.text} setText={props.summary.setText} />
        ) : (
          <Typography>{props.summary.text}</Typography>
        )}
      </Stack>

      <Stack spacing={2} sx={{ fontSize: '16px', flexGrow: 1 }}>
        <Typography sx={{ fontWeight: 550 }}>
          {t('cashVestAnalyses.scoreCard.driversOfYourScore')}
        </Typography>
        {props.readonly === undefined || props.readonly === false ? (
          <EditTextField text={props.reason1.text} setText={props.reason1.setText} />
        ) : (
          <Typography>{props.reason1.text}</Typography>
        )}
        {props.readonly === undefined || props.readonly === false ? (
          <EditTextField text={props.reason2.text} setText={props.reason2.setText} />
        ) : (
          <Typography>{props.reason2.text}</Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default ScorecardSection;
