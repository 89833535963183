import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography, SelectChangeEvent, Box, Select, MenuItem } from '@mui/material';
import TimeHorizonChart from './TimeHorizonChart';
import InvestmentChart from './InvestmentChart';
import ToolTip from '../../../../components/ToolTip/ToolTip';
import PaperSection from '../../../../components/PaperSection/PaperSection';
import EditTextField from '../../../../components/EditTextField/EditTextField';
import { CommentsContext } from '../../../../common/commentsContext';

interface InvestmentsProps {
  selectedAccountGroupIndex: number;
}

const Investments = (props: InvestmentsProps) => {
  const { t } = useTranslation();
  const [index, setIndex] = useState(1);

  const handleChange = (event: SelectChangeEvent) => {
    setIndex(+event.target.value);
  };

  const options = [
    'Strategic Liquidity vs Total Balance',
    'Investment vs Strategic Liquidity Levels'
  ];

  const { comments, setComments } = useContext(CommentsContext);

  return (
    <PaperSection
      title={t('cashVestAnalyses.liquidityProficiency.investmentVsStrategicLiquidityLvlsTitle')}
    >
      <Stack spacing={2}>
        <Stack spacing={2}>
          <Typography
            sx={{
              color: 'text.primary',
              fontSize: '18px',
              fontWeight: 650
            }}
          >
            {t('common.cashVestAnalysisHeading')}
          </Typography>
          <EditTextField
            text={comments.liquidityProficiency.investments}
            setText={(text) => {
              const newComments = { ...comments };
              newComments.liquidityProficiency.investments = text;
              setComments(newComments);
            }}
          />
        </Stack>

        <Box>
          <Select
            value={String(index)}
            onChange={handleChange}
            sx={{
              backgroundColor: '#f2f2f2'
            }}
          >
            {options.map((option, i) => (
              <MenuItem value={i} key={i}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Box>

        {index == 1 && (
          <InvestmentChart selectedAccountGroupIndex={props.selectedAccountGroupIndex} />
        )}

        {index == 0 && (
          <TimeHorizonChart selectedAccountGroupIndex={props.selectedAccountGroupIndex} />
        )}

        {index == 1 && (
          <ToolTip
            title={t(
              'cashVestAnalyses.liquidityProficiency.investmentVsStrategicLiquidityLvlsChartToolTipTitle'
            )}
            content={t(
              'cashVestAnalyses.liquidityProficiency.investmentVsStrategicLiquidityLvlsChartToolTipContent'
            )}
            label={t('common.explainChart')}
          />
        )}
        {index == 0 && (
          <ToolTip
            title={t(
              'cashVestAnalyses.liquidityProficiency.investmentVsTotalBalancesChartToolTipTitle'
            )}
            content={t(
              'cashVestAnalyses.liquidityProficiency.investmentVsTotalBalancesChartToolTipContent'
            )}
            label={t('common.explainChart')}
          />
        )}
      </Stack>
    </PaperSection>
  );
};

export default Investments;
