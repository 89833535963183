import { getToken } from '../../../auth/authenticator';
import { AuthState } from '../../../common/AuthContext.types';
import axiosClient from '../../axios-api';
import { AccountsStatement } from './AccountsStatement';
import DEVELOPMENT_FLAGS from '../../../developmentFlags';
import mockResponse from '../../../mock/accounts/statements/response.json';

export async function getAccountStatements(
  authState: AuthState,
  limit = 0,
  offset = 0
): Promise<AccountsStatement[] | {}> {
  if (DEVELOPMENT_FLAGS.isMock === 'true') return mockResponse;

  let finalResponse = {};

  const apiUrl = `/accounts/statements?c_id=${authState.clientId}&limit=${limit}&offset=${offset}`;

  await axiosClient({
    url: apiUrl,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${await getToken()}`
    }
  })
    .then((response) => {
      finalResponse = response.data;
    })
    .catch((error) =>
      console.log('An error has occured when fetching statements - ', error?.message)
    );

  return finalResponse;
}
