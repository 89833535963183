export interface ScoreDetailComments {
  summary: string;
  reason1: string;
  reason2: string;
}

export interface ScorecardComments {
  summary: string;
  availableFundsProvidingValue: ScoreDetailComments;
  liquidityProficiency: ScoreDetailComments;
  warnickRate: ScoreDetailComments;
  cashFlow: ScoreDetailComments;
  investmentPolicy: ScoreDetailComments;
  improvement1: string;
  improvement2: string;
  improvement3: string;
  improvement4: string;
  improvement5: string;
  improvement6: string;
}

export interface CashBoardComments {
  summary: string;
  yearOverYear: string;
}

export interface LiquidityProficiencyComments {
  summary: string;
  strategicLiquidity: string;
  investments: string;
}

export interface NearTermLiquidityComments {
  summary: string;
  forecast: string;
  net: string;
  variance: string;
}

export interface TreasuryServicesComments {
  summary: string;
  balanceSummary: string;
  ecr: string;
  compensatingOffset: string;
  lineItem: string;
}

export interface AccountOptimisationComments {
  summary: string;
  accountAnalysis: string;
}

export interface Comments {
  scoreCard: ScorecardComments;
  cashBoard: CashBoardComments;
  liquidityProficiency: LiquidityProficiencyComments;
  nearTermLiquidity: NearTermLiquidityComments;
  accountOptimisation: AccountOptimisationComments;
  treasuryServices: TreasuryServicesComments;
}

export const emptyComments = {
  scoreCard: {
    summary: '',
    availableFundsProvidingValue: {
      summary: '',
      reason1: '',
      reason2: ''
    },
    liquidityProficiency: {
      summary: '',
      reason1: '',
      reason2: ''
    },
    warnickRate: {
      summary: '',
      reason1: '',
      reason2: ''
    },
    cashFlow: {
      summary: '',
      reason1: '',
      reason2: ''
    },
    investmentPolicy: {
      summary: '',
      reason1: '',
      reason2: ''
    },
    improvement1: '',
    improvement2: '',
    improvement3: '',
    improvement4: '',
    improvement5: '',
    improvement6: ''
  },
  cashBoard: {
    summary: '',
    yearOverYear: ''
  },
  liquidityProficiency: {
    summary: '',
    strategicLiquidity: '',
    investments: ''
  },
  nearTermLiquidity: {
    summary: '',
    forecast: '',
    net: '',
    variance: ''
  },
  accountOptimisation: {
    summary: '',
    accountAnalysis: ''
  },
  treasuryServices: {
    summary: '',
    balanceSummary: '',
    ecr: '',
    compensatingOffset: '',
    lineItem: ''
  }
};
