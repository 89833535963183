import Star from '../../Analyses/Scorecard/star.svg';
import Circle from '../../Analyses/Scorecard/circle.svg';
import Edit from '../../Analyses/Scorecard/edit.png';
import { Stack, Box, Typography, Grid } from '@mui/material';
import React, { useContext } from 'react';
import { CommentsContext } from '../../../common/commentsContext';
import { currencyFormatting } from '../../../common/helpers';
import { ReportContext } from '../../../common/reportContext';
import TPOPaper from '../../../components/TPOPaper/TPOPaper';
import BaseSummary from '../../Analyses/BaseSummary/BaseSummary';
import ScorecardChart from '../../Analyses/Scorecard/ScorecardChart';
import { useTranslation } from 'react-i18next';
import ScorecardSection from '../../Analyses/Scorecard/ScorecardSection';
import Page from '../Page';
import NoBreak from '../NoBreak';

const DownloadScoreCardReport = () => {
  const { t } = useTranslation();
  const { comments } = useContext(CommentsContext);
  const { report } = useContext(ReportContext);

  return (
    <Page>
      <Stack spacing={3}>
        <BaseSummary
          title="Scorecard"
          content={comments.scoreCard.summary}
          setContent={(content) => {}}
        />
        {/* cashVest Score History */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              marginRight: '25px'
            }}
          >
            <ScorecardChart />
          </Box>
          {/* cashVest Score */}
          <TPOPaper color="rgba(70, 0, 116, 0.3)">
            <Box
              sx={{
                padding: 3
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: 3
                }}
              >
                <img src={Number(report.scorecard.periodStats[0].value) >= 90 ? Star : Circle} />
                <Typography
                  sx={{
                    fontSize: '28px',
                    color: 'text.primary',
                    position: 'absolute',
                    marginTop: Number(report.scorecard.periodStats[0].value) >= 90 ? '28px' : '18px'
                  }}
                >
                  {report.scorecard.periodStats[0].value}
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: '32px',
                  color: 'text.primary',
                  textAlign: 'center',
                  marginBottom: '20px'
                }}
              >
                cashVest Score
              </Typography>

              <Grid container spacing="20px">
                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontSize: '20px',
                      color: 'text.primary',
                      fontWeight: 550,
                      textAlign: 'center'
                    }}
                  >
                    {currencyFormatting(report.scorecard.periodStats[0].periodInterest)}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      color: 'text.primary',
                      textAlign: 'left'
                    }}
                  >
                    Period Interest
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontSize: '20px',
                      color: 'text.primary',
                      fontWeight: 550,
                      textAlign: 'center'
                    }}
                  >
                    {currencyFormatting(report.scorecard.periodStats[0].interestInLastYear)}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      color: 'text.primary',
                      textAlign: 'left'
                    }}
                  >
                    Last 12 months
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography
                    data-test="interestSinceAccountOpened"
                    sx={{
                      fontSize: '20px',
                      color: 'text.primary',
                      fontWeight: 550,
                      textAlign: 'center'
                    }}
                  >
                    {currencyFormatting(report.scorecard.periodStats[0].interestSinceAccountOpened)}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      color: 'text.primary',
                      textAlign: 'left'
                    }}
                  >
                    Cumulative Interest
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </TPOPaper>
        </Box>
      </Stack>
      {/* Tab section */}
      <NoBreak>
        <TPOPaper color="rgba(70, 0, 116, 0.3)">
          <Box>
            <Typography
              sx={{
                paddingLeft: '10px',
                backgroundColor: 'primary.main',
                color: '#ffffff'
              }}
            >
              {t('cashVestAnalyses.scoreCard.scoreWidgetTitle')}
            </Typography>
            <ScorecardSection
              readonly
              score={report.scorecard.subScores.availableFundsProvidingValue.score}
              summary={{
                text: comments.scoreCard.availableFundsProvidingValue.summary,
                setText: (text) => {}
              }}
              impact={report.scorecard.subScores.availableFundsProvidingValue.impact}
              reason1={{
                text: comments.scoreCard.availableFundsProvidingValue.reason1,
                setText: (text) => {}
              }}
              reason2={{
                text: comments.scoreCard.availableFundsProvidingValue.reason2,
                setText: (text) => {}
              }}
            />
          </Box>
        </TPOPaper>
      </NoBreak>
      <NoBreak>
        <TPOPaper color="rgba(70, 0, 116, 0.3)">
          <Box>
            <Typography
              sx={{
                paddingLeft: '10px',
                backgroundColor: 'primary.main',
                color: '#ffffff'
              }}
            >
              {t('cashVestAnalyses.scoreCard.liquidityWidgetTitle')}
            </Typography>
            <ScorecardSection
              readonly
              score={report.scorecard.subScores.liquidityProficiency.score}
              summary={{
                text: comments.scoreCard.liquidityProficiency.summary,
                setText: (text) => {}
              }}
              impact={report.scorecard.subScores.liquidityProficiency.impact}
              reason1={{
                text: comments.scoreCard.liquidityProficiency.reason1,
                setText: (text) => {}
              }}
              reason2={{
                text: comments.scoreCard.liquidityProficiency.reason2,
                setText: (text) => {}
              }}
            />
          </Box>
        </TPOPaper>
      </NoBreak>
      <NoBreak>
        <TPOPaper color="rgba(70, 0, 116, 0.3)">
          <Box>
            <Typography
              sx={{
                paddingLeft: '10px',
                backgroundColor: 'primary.main',
                color: '#ffffff'
              }}
            >
              {t('cashVestAnalyses.scoreCard.warnickWidgetTitle')}
            </Typography>
            <ScorecardSection
              readonly
              score={report.scorecard.subScores.warnickRate.score}
              summary={{
                text: comments.scoreCard.warnickRate.summary,
                setText: (text) => {}
              }}
              impact={report.scorecard.subScores.warnickRate.impact}
              reason1={{
                text: comments.scoreCard.warnickRate.reason1,
                setText: (text) => {}
              }}
              reason2={{
                text: comments.scoreCard.warnickRate.reason2,
                setText: (text) => {}
              }}
            />
          </Box>
        </TPOPaper>
      </NoBreak>
      <NoBreak>
        <TPOPaper color="rgba(70, 0, 116, 0.3)">
          <Box>
            <Typography
              sx={{
                paddingLeft: '10px',
                backgroundColor: 'primary.main',
                color: '#ffffff'
              }}
            >
              {t('cashVestAnalyses.scoreCard.optimizationWidgetTitle')}
            </Typography>
            <ScorecardSection
              readonly
              score={report.scorecard.subScores.cashFlow.score}
              summary={{
                text: comments.scoreCard.cashFlow.summary,
                setText: (text) => {}
              }}
              impact={report.scorecard.subScores.cashFlow.impact}
              reason1={{
                text: comments.scoreCard.cashFlow.reason1,
                setText: (text) => {}
              }}
              reason2={{
                text: comments.scoreCard.cashFlow.reason2,
                setText: (text) => {}
              }}
            />
          </Box>
        </TPOPaper>
      </NoBreak>
      <NoBreak>
        <TPOPaper color="rgba(70, 0, 116, 0.3)">
          <Box>
            <Typography
              sx={{
                paddingLeft: '10px',
                backgroundColor: 'primary.main',
                color: '#ffffff'
              }}
            >
              {t('cashVestAnalyses.scoreCard.investmentPolicyWidgetTitle')}
            </Typography>
            <ScorecardSection
              readonly
              score={report.scorecard.subScores.investment.score}
              summary={{
                text: comments.scoreCard.investmentPolicy.summary,
                setText: (text) => {}
              }}
              impact={report.scorecard.subScores.investment.impact}
              reason1={{
                text: comments.scoreCard.investmentPolicy.reason1,
                setText: (text) => {}
              }}
              reason2={{
                text: comments.scoreCard.investmentPolicy.reason2,
                setText: (text) => {}
              }}
            />
          </Box>
        </TPOPaper>
      </NoBreak>
      <Page>
        {/* Activities to help improve your cashVest Score */}
        <TPOPaper color="rgba(70, 0, 116, 0.3)">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <Box
              sx={{
                width: '5px',
                backgroundColor: '#460074'
              }}
            />

            <Stack
              spacing={2}
              sx={{
                padding: 3,
                width: '100%'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <img src={Edit} width="50px" />
              </Box>
              <Typography
                sx={{
                  fontSize: '32px',
                  color: 'text.primary',
                  textAlign: 'center'
                }}
              >
                Activities to help improve your cashVest Score
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'auto',
                  gap: '16px'
                }}
              >
                {comments.scoreCard.improvement1 && (
                  <Stack
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%'
                    }}
                    spacing={2}
                  >
                    <Box
                      sx={{
                        height: '10px',
                        width: '10px',
                        backgroundColor: '#015545',
                        borderRadius: '50%'
                      }}
                    />
                    <Typography>{comments.scoreCard.improvement1}</Typography>
                  </Stack>
                )}
                {comments.scoreCard.improvement2 && (
                  <Stack
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexGrow: 1
                    }}
                    spacing={2}
                  >
                    <Box
                      sx={{
                        height: '10px',
                        width: '10px',
                        backgroundColor: '#015545',
                        borderRadius: '50%'
                      }}
                    />
                    <Typography>{comments.scoreCard.improvement2}</Typography>
                  </Stack>
                )}
                {comments.scoreCard.improvement3 && (
                  <Stack
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexGrow: 1
                    }}
                    spacing={2}
                  >
                    <Box
                      sx={{
                        height: '10px',
                        width: '10px',
                        backgroundColor: '#015545',
                        borderRadius: '50%'
                      }}
                    />
                    <Typography>{comments.scoreCard.improvement3}</Typography>
                  </Stack>
                )}
                {comments.scoreCard.improvement4 && (
                  <Stack
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexGrow: 1
                    }}
                    spacing={2}
                  >
                    <Box
                      sx={{
                        height: '10px',
                        width: '10px',
                        backgroundColor: '#015545',
                        borderRadius: '50%'
                      }}
                    />
                    <Typography>{comments.scoreCard.improvement4}</Typography>
                  </Stack>
                )}
                {comments.scoreCard.improvement5 && (
                  <Stack
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexGrow: 1
                    }}
                    spacing={2}
                  >
                    <Box
                      sx={{
                        height: '10px',
                        width: '10px',
                        backgroundColor: '#015545',
                        borderRadius: '50%'
                      }}
                    />
                    <Typography>{comments.scoreCard.improvement5}</Typography>
                  </Stack>
                )}
                {comments.scoreCard.improvement6 && (
                  <Stack
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexGrow: 1
                    }}
                    spacing={2}
                  >
                    <Box
                      sx={{
                        height: '10px',
                        width: '10px',
                        backgroundColor: '#015545',
                        borderRadius: '50%'
                      }}
                    />
                    <Typography>{comments.scoreCard.improvement6}</Typography>
                  </Stack>
                )}
              </Box>
            </Stack>
          </Box>
        </TPOPaper>
      </Page>
    </Page>
  );
};

export default DownloadScoreCardReport;
