import { RoutesEnum } from '../routing/types';

export const LOGGED_OUT_STATE = 'LOGGED_OUT_STATE' as const;

export type LandingURL = RoutesEnum | typeof LOGGED_OUT_STATE | null;

export interface RoutingHistory {
  landingURL: LandingURL;
  setLandingURLToLoggedOutState: () => void;
  isLandingURLValid: () => boolean;
}
