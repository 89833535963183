export default {
  background: {
    default: '#f2f2f2',
    paper: '#eeeeee'
  },
  primary: {
    // purple
    main: '#460074',
    light: '#ede6f1',
    action: '#6E3792'
  },
  secondary: {
    // orange
    main: '#f1b310'
  },
  tertiary: {
    // green
    main: '#015545'
  },
  text: {
    primary: '#333333',
    secondary: '#ffffff'
  },
  common: {
    white: '#ffffff',
    black: '#333333',
    grey: '#575757',
    greyBorder: '#C2C2C2',
    hover: 'rgba(0, 0, 0, 0.04)',
    error: '#d32f2f'
  }
};
