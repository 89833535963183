import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch
} from '@mui/material';
import TPOButton from '../../../components/TPOButton/TPOButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { UserEmailPreferences } from '../../../networking/profile/userDetails/UserDetails.types';

interface EditEmailPreferencesDrawer {
  isOpen: boolean;
  handleCloseDrawer: () => void;
  updateUserEmailPreferences: (userEmailPreferences: UserEmailPreferences) => Promise<void>;
  onEmailPreferencesUpdated: () => Promise<void>;
  currentUserEmailPreferences: UserEmailPreferences;
  onSuccess: () => void;
  onError: () => void;
}

const EditNameDrawer: React.FC<EditEmailPreferencesDrawer> = ({
  isOpen,
  handleCloseDrawer,
  updateUserEmailPreferences,
  onEmailPreferencesUpdated,
  currentUserEmailPreferences,
  onSuccess,
  onError
}) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: currentUserEmailPreferences,
    validationSchema: Yup.object().shape({
      oneDayInvestmentMaturitySubscription: Yup.boolean().required('Required'),
      sevenDayInvestmentMaturitySubscription: Yup.boolean().required('Required')
    }),
    onSubmit: async (values) => {
      try {
        await updateUserEmailPreferences(values);
        onSuccess();
        await onEmailPreferencesUpdated();
      } catch (error) {
        onError();
      } finally {
        handleCloseDrawer();
      }
    }
  });

  const handleCleanCloseDrawer = () => {
    handleCloseDrawer();
    formik.setValues(currentUserEmailPreferences);
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleCleanCloseDrawer}
      PaperProps={{
        style: { backgroundColor: 'white' }
      }}
    >
      <IconButton
        onClick={handleCleanCloseDrawer}
        sx={{ position: 'absolute', right: 0, top: 0, padding: '10px', cursor: 'pointer' }}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{ width: 'auto', padding: 4, margin: '50px' }}>
        <Typography
          variant="h4"
          sx={{ textAlign: 'left', marginLeft: '-28px', paddingBottom: '70px' }}
        >
          {t('yourProfile.emailPreferences.edit.title')}
        </Typography>

        <form onSubmit={formik.handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.oneDayInvestmentMaturitySubscription}
                    onChange={formik.handleChange}
                    name="oneDayInvestmentMaturitySubscription"
                  />
                }
                sx={{
                  justifyContent: 'space-between',
                  width: '400px',
                  margin: '0 0 20px 0',
                  fontSize: '1.5rem'
                }}
                label={t(
                  'yourProfile.emailPreferences.fields.oneDayInvestmentMaturitySubscription'
                )}
                labelPlacement="start"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.sevenDayInvestmentMaturitySubscription}
                    onChange={formik.handleChange}
                    name="sevenDayInvestmentMaturitySubscription"
                  />
                }
                sx={{
                  justifyContent: 'space-between',
                  width: '400px',
                  margin: '0 0 60px 0',
                  fontSize: '1.5rem'
                }}
                label={t(
                  'yourProfile.emailPreferences.fields.sevenDayInvestmentMaturitySubscription'
                )}
                labelPlacement="start"
              />
            </FormGroup>
          </FormControl>

          <TPOButton
            onClick={formik.submitForm}
            styles={{ width: '250px', height: '45px' }}
            data-testid="SAVE_BUTTON_TEST_ID"
            disabled={!formik.isValid}
          >
            {t('yourProfile.personalDetails.editName.save')}
          </TPOButton>
        </form>
      </Box>
    </Drawer>
  );
};

export default EditNameDrawer;
