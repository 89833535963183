import React from 'react';
import {
  Typography,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  SelectChangeEvent
} from '@mui/material';

export interface StringFilterState {
  [key: string]: {
    value: boolean;
    label?: string;
  };
}

export const stringFilterToArray = (filterState: StringFilterState) => {
  const filters = filterState as StringFilterState;
  return Object.keys(filters).filter((key) => filters[key].value);
};

interface StringFilterProps {
  title: string;
  allSelectedTitle: string;
  groupLabel?: string;
  state: StringFilterState;
  setState: React.Dispatch<React.SetStateAction<StringFilterState>>;
}

const StringFilter: React.FC<StringFilterProps> = ({
  title,
  allSelectedTitle,
  groupLabel,
  state,
  setState
}) => {
  const handleFilterChange = (event: SelectChangeEvent<string[]>) => {
    setState((previousState) => {
      const newStringFilterState: StringFilterState = {};

      // Initialize the newStringFilterState with all filters set to false (deselected)
      Object.keys(state).forEach((key) => {
        newStringFilterState[key] = { ...previousState[key], value: false };
      });

      // Mark the selected filters as true (selected)
      const selectedFields = event.target.value as string[];
      for (const field of selectedFields) {
        newStringFilterState[field] = { ...previousState[field], value: true };
      }

      return newStringFilterState;
    });
  };

  const areAllFiltersSelected = stringFilterToArray(state).length === Object.keys(state).length;

  return (
    <Stack spacing={2} sx={{ width: '280px' }}>
      <Typography
        sx={{
          color: 'text.primary',
          fontSize: '16px',
          fontWeight: 'bold'
        }}
      >
        {title}
      </Typography>

      <FormControl
        fullWidth
        variant="outlined"
        sx={{
          maxWidth: '430px'
        }}
      >
        {groupLabel && <InputLabel>{groupLabel}</InputLabel>}
        <Select
          label={groupLabel}
          multiple
          value={stringFilterToArray(state)}
          onChange={handleFilterChange}
          renderValue={(selected) => {
            if (areAllFiltersSelected) {
              return allSelectedTitle;
            } else {
              const selectedFieldsLabels = selected.map(
                (selectedField) => state[selectedField].label ?? selectedField
              );
              return selectedFieldsLabels.join(', ');
            }
          }}
          data-testid="SELECT_FILTER_DROPDOWN"
        >
          {Object.entries(state).map(([item, data]) => (
            <MenuItem key={item} value={item} data-testid={`DROPDOWN_ITEM_${item}`}>
              <Checkbox checked={data.value} />
              <ListItemText primary={data.label ?? item} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default StringFilter;
