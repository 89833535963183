import React, { useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { ChartOptions } from 'chart.js';
import { Stack, Typography, Grid } from '@mui/material';
import TPOPaper from '../../../../components/TPOPaper/TPOPaper';
import TPOLabel from '../../../../components/TPOLabel/TPOLabel';
import { ReportContext } from '../../../../common/reportContext';
import { ChartData, LineItemFee } from './LineItemFees.types';

interface LineItemFeesChartProps {
  selectedAccountGroupIndex: number;
}

const approvedColors = ['#20968b', '#39568c', '#f64c58', '#f69436', '#703b92', '#84bf42'];

const generateDataset = (lineItemFees: LineItemFee[]): ChartData => {
  const values = lineItemFees.map((item) => {
    let categoryTotal = 0;
    item.subcategory.forEach((subcategory) => {
      categoryTotal += subcategory.cost;
    });
    return categoryTotal;
  });

  const labels = lineItemFees.map((item) => {
    return item.category;
  });

  return {
    labels: labels,
    datasets: [
      {
        label: 'Total costs',
        data: values,
        backgroundColor: approvedColors,
        barThickness: 80
      }
    ]
  };
};

const LineItemFeesChart = (props: LineItemFeesChartProps): JSX.Element => {
  const { t } = useTranslation();
  const { report } = useContext(ReportContext);

  const treasuryService = report.treasuryServices.treasuryServices[props.selectedAccountGroupIndex];

  const options: ChartOptions<'bar'> = {
    scales: {
      y: {
        position: 'right',
        grid: {
          display: false
        }
      },
      x: {
        grid: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: false,
        position: 'bottom'
      }
    }
  };

  const data = generateDataset(treasuryService.lineItemFees);

  const chartData: ChartData = {
    labels: data.labels,
    datasets: data.datasets
  };

  return (
    <TPOPaper color="rgba(70, 0, 116, 0.3)">
      <Stack spacing={3} padding={3}>
        <Typography
          align="right"
          sx={{
            fontSize: '18px',
            color: 'text.primary',
            fontWeight: 650
          }}
        >
          {t('cashVestAnalyses.treasuryServices.LineItemFeesChartTitle')}
        </Typography>

        <Bar data={chartData} options={options} redraw />

        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}
        >
          {data.labels.map((label: string, i: number) => (
            <Grid item xs={4} key={`lineItemChartLegend${i}`}>
              <TPOLabel text={label} color={approvedColors[i % approvedColors.length]} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </TPOPaper>
  );
};

export default React.memo(LineItemFeesChart);
