import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React, { useContext } from 'react';
import { ReportContext } from '../../../../common/reportContext';
import ToolTip from '../../../../components/ToolTip/ToolTip';
import { Header } from '../../../../components/TPOTable/TPOTable';
import TPOTableHeader from '../../../../components/TPOTable/TPOTableHeader';
import { createLineItemFeesContent } from './createLineItemFeesContent';
import { Row, RowSubcategory } from './LineItemFees.types';
import { SubcategoryItem } from './SubcategoryItem';

interface LineItemFeesTableProps {
  selectedAccountGroupIndex: number;
}

const LineItemFeesTable = (props: LineItemFeesTableProps) => {
  const { report } = useContext(ReportContext);

  const treasuryService = report.treasuryServices.treasuryServices[props.selectedAccountGroupIndex];

  const content = createLineItemFeesContent(treasuryService.lineItemFees);

  const headers: Header[] = [
    { id: 'category', label: 'Category', showToolTip: false },
    { id: 'total-costs', label: 'Total costs', showToolTip: false },
    { id: 'subcategory', label: 'Subcategory', showToolTip: false },
    { id: 'unit-price', label: 'Unit Price', showToolTip: false },
    { id: 'volume', label: 'Volume', showToolTip: false },
    { id: 'cost', label: 'Cost', showToolTip: false }
  ];

  return (
    <Stack spacing={2}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header, i) => (
                <TableCell
                  key={i}
                  sx={{
                    backgroundColor: 'primary.main',
                    color: 'common.white',
                    fontSize: '16px'
                  }}
                >
                  <TPOTableHeader
                    label={header.label}
                    showToolTip={header.showToolTip}
                    toolTipHeader={header.toolTipHeader}
                    toolTipText={header.toolTipText}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {content.map((row, i) => (
              <TableRow
                key={i}
                sx={{
                  fontSize: '16px',
                  height: '80px'
                }}
              >
                <TableCell
                  align="left"
                  sx={{
                    borderRight: 1,
                    borderColor: '#DCDCDC'
                  }}
                >
                  {!row.showToolTip && (
                    <Stack spacing={0}>
                      <Typography>{row.category}</Typography>
                    </Stack>
                  )}
                  {row.showToolTip && (
                    <Stack direction="row" spacing={1}>
                      <Typography>{row.category}</Typography>
                      <ToolTip title={row.category} content={row.toolTipContent} label="" />
                    </Stack>
                  )}
                </TableCell>
                <TableCell sx={{ fontSize: '16px' }}>{row.totalCosts}</TableCell>
                {[...Array(4).keys()].map((index, i) => (
                  <SubcategoryItem
                    isSubcategoryTitle={!index}
                    content={row.subcategory.map((item) => item[index])}
                    key={i}
                  />
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default LineItemFeesTable;
