import { Box, Container, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CommentsContext } from '../../../common/commentsContext';
import { ReportContext } from '../../../common/reportContext';
import PaperSection from '../../../components/PaperSection/PaperSection';
import TPOPaper from '../../../components/TPOPaper/TPOPaper';
import BaseSummary from '../../Analyses/BaseSummary/BaseSummary';
import BalanceSummary from '../../Analyses/CashBoard/BalanceSummary/BalanceSummary';
import CurrentStatusChart from '../../Analyses/CashBoard/CurrentStatus/CurrentStatusChart';
import FinancialSummary from '../../Analyses/CashBoard/FinancialSummary/FinancialSummary';
import LiquidityBreakdown from '../../Analyses/CashBoard/LiquidityBreakdown/LiquidityBreakdown';
import Page from '../Page';

interface DownloadCashboardReportProps {
  reportDate: string;
}

const DownloadCashboardReport = (props: DownloadCashboardReportProps) => {
  const { t } = useTranslation();
  const { comments } = useContext(CommentsContext);
  const { report } = useContext(ReportContext);

  const options = report.cashBoard.yearOverYearChange.map(
    (accountGroup) => accountGroup.accountGroup
  );

  return (
    <Page>
      <Stack spacing={3}>
        <BaseSummary
          title={t('cashVestAnalyses.cashBoard.title', {
            reportDate: props.reportDate
          })}
          content={t('cashVestAnalyses.cashBoard.summary')}
          setContent={(content) => {}}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <FinancialSummary />
          <Box
            sx={{
              width: '30px'
            }}
          />
          <LiquidityBreakdown />
        </Box>

        {/* Current status and year-over-year change */}
        <Stack spacing={2}>
          <PaperSection title={t('common.cashVestAnalysisHeading')} bannerColor={'secondary.main'}>
            <Typography
              sx={{
                fontSize: '18px',
                color: 'text.primary'
              }}
            >
              {comments.cashBoard.yearOverYear}
            </Typography>
          </PaperSection>
        </Stack>
      </Stack>
      {options.map((accountGroup, index) => (
        <Page key={`CurrentStatus - ${accountGroup}`}>
          <PaperSection title={`CurrentStatus - ${accountGroup}`} bannerColor={'primary.main'}>
            <Box
              sx={{
                marginBottom: '30px'
              }}
            >
              <TPOPaper color="rgba(70, 0, 116, 0.3)">
                <CurrentStatusChart selectedAccountGroupIndex={index} />
              </TPOPaper>
            </Box>
            <TPOPaper color="rgba(70, 0, 116, 0.3)">
              <BalanceSummary selectedAccountGroupIndex={index} />
            </TPOPaper>
          </PaperSection>
        </Page>
      ))}
    </Page>
  );
};

export default DownloadCashboardReport;
