import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CommentsContext } from '../../../common/commentsContext';
import { ReportContext } from '../../../common/reportContext';
import { Box, Stack, Typography } from '@mui/material';
import PaperSection from '../../../components/PaperSection/PaperSection';
import BaseSummary from '../../Analyses/BaseSummary/BaseSummary';
import BalancesByBankChart from '../../Analyses/AccountOptimization/BankByBankAnalysis/BalancesByBankChart';
import BankBalancesTable from '../../Analyses/AccountOptimization/BankByBankAnalysis/BalancesTables/BankBalancesTable';
import TPOPaper from '../../../components/TPOPaper/TPOPaper';
import Page from '../Page';
import AnalysisSummary from '../AnalysisSummary';
import NoBreak from '../NoBreak';

const DownloadAccountOptimizationReport = () => {
  const { t } = useTranslation();
  const { comments } = useContext(CommentsContext);
  const { report } = useContext(ReportContext);

  const options: string[] = report.accountOptimization.groups.map((obj) => {
    return obj.accountGroup === 'All Accounts' ? 'Bank by Bank' : obj.accountGroup;
  });

  return (
    <Page>
      <Stack spacing={3}>
        <BaseSummary
          title={t('cashVestAnalyses.accountOptimization.title')}
          content={t('cashVestAnalyses.accountOptimization.summary')}
          setContent={() => {}}
        />
        {/* RM comment for account analysis */}
        <PaperSection title={t('common.cashVestAnalysisHeading')} bannerColor={'secondary.main'}>
          <AnalysisSummary
            heading={t('cashVestAnalyses.accountOptimization.bankByBankAnalysisTitle')}
            body={comments.accountOptimisation.accountAnalysis}
          />
        </PaperSection>
      </Stack>
      {options.map((accountGroup, index) => (
        <Page key={`Account Analysis - ${accountGroup}`}>
          <PaperSection title={accountGroup} bannerColor={'primary.main'}>
            <NoBreak>
              <TPOPaper color="rgba(70, 0, 116, 0.3)">
                <Box>
                  <Typography
                    sx={{
                      paddingLeft: '10px',
                      backgroundColor: 'primary.main',
                      color: '#ffffff'
                    }}
                  >
                    Account Analysis
                  </Typography>
                  <Box
                    sx={{
                      justifyContent: 'center',
                      padding: 3
                    }}
                  >
                    <BalancesByBankChart selectedAccountGroupIndex={index} />
                  </Box>
                </Box>
              </TPOPaper>
            </NoBreak>
            <TPOPaper color="rgba(70, 0, 116, 0.3)">
              <Box>
                <Typography
                  sx={{
                    paddingLeft: '10px',
                    backgroundColor: 'primary.main',
                    color: '#ffffff'
                  }}
                >
                  Annual Balances
                </Typography>
                <BankBalancesTable selectedAccountGroupIndex={index} isAnnual={true} />
              </Box>
            </TPOPaper>
            <Page>
              <TPOPaper color="rgba(70, 0, 116, 0.3)">
                <Box>
                  <Typography
                    sx={{
                      paddingLeft: '10px',
                      backgroundColor: 'primary.main',
                      color: '#ffffff'
                    }}
                  >
                    Period Balances
                  </Typography>
                  <BankBalancesTable selectedAccountGroupIndex={index} isAnnual={false} />
                </Box>
              </TPOPaper>
            </Page>
          </PaperSection>
        </Page>
      ))}
    </Page>
  );
};

export default DownloadAccountOptimizationReport;
