import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext, EMPTY_AUTH_STATE } from '../common/authContext';
import { RouteProps } from './types';

const ProtectedRoute = ({ children }: RouteProps) => {
  const { authState } = useContext(AuthContext);

  if (authState.bearerToken === EMPTY_AUTH_STATE.bearerToken) {
    return <Navigate to="/" />;
  } else {
    return children;
  }
};

export default ProtectedRoute;
