import { CognitoUser } from '@aws-amplify/auth';
import { isProdEnvironment } from '../tests/helpers';

const PRODUCTION_DOMAIN = isProdEnvironment() ? 'cashvest.us' : 'localhost';

const IS_AWS_PROD_ENVIRONMENT =
  window.location.hostname === PRODUCTION_DOMAIN && isProdEnvironment();

enum MFALoginAction {
  USE_MFA,
  SETUP_MFA,
  NOT_AWS_PRODUCTION_ENVIRONMENT_SKIP_MFA
}

export const getMFALoginAction = (
  userChallengeName: CognitoUser['challengeName'],
  isAWSProduction = IS_AWS_PROD_ENVIRONMENT
): MFALoginAction => {
  if (userChallengeName === 'SMS_MFA' || userChallengeName === 'SOFTWARE_TOKEN_MFA') {
    return MFALoginAction.USE_MFA;
  } else {
    if (isAWSProduction) {
      return MFALoginAction.SETUP_MFA;
    } else {
      return MFALoginAction.NOT_AWS_PRODUCTION_ENVIRONMENT_SKIP_MFA;
    }
  }
};

export default MFALoginAction;
