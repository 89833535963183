import React, { useContext, useEffect, useState } from 'react';

import { Drawer, Stack } from '@mui/material';
import * as Yup from 'yup';
import classes from './AddInvestmentDrawer.module.scss';
import Navigation, { HandleCloseDrawer, Section } from './components/Navigation';
import AddedPage from './components/AddedPage';

import { FormInvestment } from './AddInvestmentDrawer.types';
import EditPage from './components/EditPage';
import NewPage, { INITIAL_VALUES, SubmitButton } from './components/NewPage';
import transformValues, {
  PRODUCTION_INVESTMENT_TYPES_GROUP_2,
  PRODUCTION_INVESTMENT_TYPES_GROUP_3
} from './components/utils';
import TPODialog from './components/TPODialog';
import { useTranslation } from 'react-i18next';
import {
  postInvestments,
  getAccountNames,
  getInvestmentTypes
} from '../../networking/accounts/investments/investments';
import { AuthContext } from '../../common/authContext';
import { AccountNames, InvestmentTypes } from './components/InvestmentForm';
import { useFormik } from 'formik';
import { MOCK_INVESTMENT_TYPES_GROUP2, MOCK_INVESTMENT_TYPES_GROUP3 } from './components/mocks';
import { mockValueForTestEnvironment } from '../../utils/tests/helpers';

export const INVESTMENT_TYPES_GROUP_2 = mockValueForTestEnvironment(
  PRODUCTION_INVESTMENT_TYPES_GROUP_2,
  MOCK_INVESTMENT_TYPES_GROUP2
);

export const INVESTMENT_TYPES_GROUP_3 = mockValueForTestEnvironment(
  PRODUCTION_INVESTMENT_TYPES_GROUP_3,
  MOCK_INVESTMENT_TYPES_GROUP3
);

export const ADD_INVESTMENT_DRAWER_TESTID = 'ADD_INVESTMENT_DRAWER_TESTID';
export const INTEREST_RATE_WARNING_TPO_DIALOG_DATA_TESTID =
  'INTEREST_RATE_WARNING_TPO_DIALOG_DATA_TESTID';

interface AddInvestmentDrawerProps {
  isOpen: boolean;
  handleCloseDrawer: HandleCloseDrawer;
}

const AddInvestmentDrawer = ({ isOpen, handleCloseDrawer }: AddInvestmentDrawerProps) => {
  const [investments, setInvestments] = useState([] as FormInvestment[]);
  const [section, setSection] = useState(Section.NEW);
  const [chosenInvestmentIndexToEdit, setChosenInvestmentIndexToEdit] = useState(
    null as number | null
  );
  const [isDeleteTPODialogOpen, setIsDeleteTPODialogOpen] = useState(false);
  const [isEditPageDiscardChangesTPODialogOpen, setIsEditPageDiscardChangesTPODialogOpen] =
    useState(false);
  const [isCancelTPODialogOpen, setIsCancelTPODialogOpen] = useState(false);
  const [isNewInvestmentFormChanged, setIsNewInvestmentFormChanged] = useState(false);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
  const [isUnsuccessfullySubmitted, setIsUnsuccessfullySubmitted] = useState(false);
  const [isSubmitAllLoading, setIsSubmitAllLoading] = useState(false);
  const [accountNames, setAccountNames] = useState<AccountNames>([]);
  const [investmentTypes, setInvestmentTypes] = useState<InvestmentTypes>([]);
  const [submitButtonNewPage, setSubmitButtonNewPage] = useState(
    SubmitButton.ADD_ANOTHER_INVESTMENT
  );
  const [isNewPageWarningTPODialogOpen, setIsNewPageWarningTPODialogOpen] = useState(false);

  const { authState } = useContext(AuthContext);

  const { t } = useTranslation();

  const INVESTMENT_FORM_VALIDATION_SCHEMA = Yup.object().shape({
    accountNumber: Yup.string().required(t('formValidationError.account')),
    investmentTypeId: Yup.number().required(t('formValidationError.investmentTypeId')),
    purchaseAmount: Yup.number()
      .typeError(t('formValidationError.numberTypeError'))
      .required(t('formValidationError.amount')),
    interestRate: Yup.number()
      .typeError(t('formValidationError.numberTypeError'))
      .required(t('formValidationError.rate'))
      .test('Interest rate is positive', t('formValidationError.rate'), (interestRate) => {
        if (!interestRate) return false;
        if (interestRate >= 0 && interestRate <= 100) return true;
        return false;
      }),
    faceValue: Yup.number()
      .typeError(t('formValidationError.numberTypeError'))
      .required(t('formValidationError.amount')),
    purchaseDate: Yup.date()
      .nullable()
      .typeError(t('formValidationError.dateTypeError'))
      .required(t('formValidationError.date')),
    maturityDate: Yup.date().when('purchaseDate', ([purchaseDate]: Date[]) => {
      return Yup.date()
        .nullable()
        .typeError(t('formValidationError.dateTypeError'))
        .required(t('formValidationError.date'))
        .test('Dates order', t('formValidationError.maturityDateError'), (maturityDate) => {
          if (!maturityDate) return true;

          return maturityDate >= purchaseDate;
        });
    }),
    callDate: Yup.date().nullable().typeError(t('formValidationError.dateTypeError')),
    couponRate: Yup.number().typeError(t('formValidationError.numberTypeError')),
    investmentId: Yup.string(),
    fund: Yup.string()
  });

  const formikNewPage = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: INVESTMENT_FORM_VALIDATION_SCHEMA,
    onSubmit: (values) => {
      handleAddInvestmentToList(values);

      formikNewPage.resetForm();

      if (submitButtonNewPage === SubmitButton.REVIEW) handleOpenAddedPage();
    }
  });

  useEffect(() => {
    getAccountNames(authState).then((response) => {
      if (response === null) {
        return;
      }
      setAccountNames(response);
    });

    getInvestmentTypes(authState).then((response) => {
      if (response === null) {
        return;
      }
      setInvestmentTypes(response);
    });
  }, []);


  const resetState = () => {
    setInvestments([]);
    setSection(Section.NEW);
    setChosenInvestmentIndexToEdit(null);
    setIsDeleteTPODialogOpen(false);
    setIsCancelTPODialogOpen(false);
    setIsNewInvestmentFormChanged(false);
    setIsUnsuccessfullySubmitted(false);
    setIsSuccessfullySubmitted(false);
    setIsSubmitAllLoading(false);
    setIsEditPageDiscardChangesTPODialogOpen(false);
    setIsNewPageWarningTPODialogOpen(false);
  };

  const handleOpenWarningTPODialog = (investment: FormInvestment) => {
    if (Number(investment.interestRate) > 20) {
      setIsNewPageWarningTPODialogOpen(true);
    } else {
      formikNewPage.submitForm();
    }
  };

  const handleGoBackButtonWarningTPODialog = () => {
    setIsNewPageWarningTPODialogOpen(false);
  };

  const handleOkayButtonWarningTPODialog = () => {
    formikNewPage.submitForm();
    handleGoBackButtonWarningTPODialog();
  };

  const handleReviewButtonNewPage = () => {
    setSubmitButtonNewPage(SubmitButton.REVIEW);
    formikNewPage.submitForm();
  };

  const handleOkayEditPageDiscardChangesTPODialog = () => {
    setChosenInvestmentIndexToEdit(null);
    setIsEditPageDiscardChangesTPODialogOpen(false);
  };

  const handleGoBackEditPageDiscardChangesTPODialog = () => {
    setSection(Section.ADDED);
    setIsEditPageDiscardChangesTPODialogOpen(false);
  };

  const handleOpenEditPageDiscardChangesTPODialog = () => {
    if (chosenInvestmentIndexToEdit === null) return;
    setIsEditPageDiscardChangesTPODialogOpen(true);
  };

  const handleOpenSuccessfullySubmittedTPOConfirmationDialog = () => {
    setIsSuccessfullySubmitted(true);
  };

  const handleCloseSuccessfullySubmittedTPOConfirmationDialog = () => {
    setIsSuccessfullySubmitted(false);
    resetState();
    handleCloseDrawer();
  };

  const handleOpenUnsuccessfullySubmittedTPOConfirmationDialog = () => {
    setIsUnsuccessfullySubmitted(true);
  };

  const handleCloseUnsuccessfullySubmittedTPOConfirmationDialog = () => {
    setIsUnsuccessfullySubmitted(false);
  };

  const handleDeleteInvestment = (investmentIndex: number) => {
    setInvestments((oldInvestments) =>
      oldInvestments.filter((_, index) => index !== investmentIndex)
    );
    handleCloseEditPage();
  };

  const handleOpenDeleteTPODialog = () => {
    if (chosenInvestmentIndexToEdit === null) return;
    setIsDeleteTPODialogOpen(true);
  };

  const handleCloseDeleteTPODialog = () => {
    setIsDeleteTPODialogOpen(false);
  };

  const handleDeleteButtonDeleteTPODialog = () => {
    if (chosenInvestmentIndexToEdit === null) return;
    handleCloseDeleteTPODialog();
    handleDeleteInvestment(chosenInvestmentIndexToEdit);
  };

  const handleOpenCancelTPODialog = () => {
    if (investments.length !== 0 || isNewInvestmentFormChanged)
      return setIsCancelTPODialogOpen(true);

    resetState();
    handleCloseDrawer();
  };

  const handleGoBackButtonCancelTPODialog = () => {
    setIsCancelTPODialogOpen(false);
  };

  const handleOkayButtonCancelTPODialog = () => {
    handleGoBackButtonCancelTPODialog();
    resetState();
    handleCloseDrawer();
  };

  const handleNewInvestmentFormChange = () => {
    setIsNewInvestmentFormChanged(true);
  };

  const handleSectionChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSection(newValue);
  };

  const handleAddAnotherInvestment = () => {
    setSection(Section.NEW);
  };

  const handleOpenAddedPage = () => {
    setSection(Section.ADDED);
  };

  const handleAddInvestmentToList = (investment: FormInvestment) => {
    setInvestments((oldList) => [investment, ...oldList]);
  };

  const handleCloseEditPage = () => {
    setChosenInvestmentIndexToEdit(null);
  };

  const handleEditInvestment = (investment: FormInvestment) => {
    if (chosenInvestmentIndexToEdit === null) return;
    setInvestments((oldInvestments) => {
      oldInvestments[chosenInvestmentIndexToEdit] = investment;
      return oldInvestments;
    });
    handleCloseEditPage();
  };

  const handleOpenEditPage = (investmentIndex: number) => {
    setChosenInvestmentIndexToEdit(investmentIndex);
  };

  const handleSubmitAllInvestments = async () => {
    setIsSubmitAllLoading(true);

    const transformedInvestments = investments.map((i) =>
      transformValues(i, accountNames)
    );
    const isSuccess = await postInvestments(authState, transformedInvestments);

    setIsSubmitAllLoading(false);

    if (isSuccess) return handleOpenSuccessfullySubmittedTPOConfirmationDialog();

    handleOpenUnsuccessfullySubmittedTPOConfirmationDialog();
  };

  let currentPage = null as React.ReactElement | null;
  if (chosenInvestmentIndexToEdit !== null)
    currentPage = (
      <EditPage
        investment={investments[chosenInvestmentIndexToEdit]}
        handleBackToAddedPage={handleOpenEditPageDiscardChangesTPODialog}
        handleEditInvestment={handleEditInvestment}
        isDeleteTPODialogOpen={isDeleteTPODialogOpen}
        isDiscardChangesTPODialogOpen={isEditPageDiscardChangesTPODialogOpen}
        handlePrimaryButtonDeleteTPODialog={handleCloseDeleteTPODialog}
        handleSecondaryButtonDeleteTPODialog={handleDeleteButtonDeleteTPODialog}
        handleOpenDeleteTPODialog={handleOpenDeleteTPODialog}
        handlePrimaryDiscardChangesTPODialog={handleGoBackEditPageDiscardChangesTPODialog}
        handleSecondaryDiscardChangesTPODialog={handleOkayEditPageDiscardChangesTPODialog}
        accountNames={accountNames}
        investmentTypes={investmentTypes}
        investmentTypesGroup2={INVESTMENT_TYPES_GROUP_2}
        investmentTypesGroup3={INVESTMENT_TYPES_GROUP_3}
        form_validation_schema={INVESTMENT_FORM_VALIDATION_SCHEMA}
      />
    );
  else if (section === Section.NEW)
    currentPage = (
      <NewPage
        handleCloseDrawer={handleOpenCancelTPODialog}
        setIsNewInvestmentFormChanged={handleNewInvestmentFormChange}
        accountNames={accountNames}
        investmentTypes={investmentTypes}
        investmentTypesGroup2={INVESTMENT_TYPES_GROUP_2}
        investmentTypesGroup3={INVESTMENT_TYPES_GROUP_3}
        formik={formikNewPage}
        handleReview={handleReviewButtonNewPage}
        handleOpenWarningTPODialog={handleOpenWarningTPODialog}
      />
    );
  else
    currentPage = (
      <AddedPage
        investments={investments.map((inv) => transformValues(inv, accountNames))}
        investmentTypes={investmentTypes}
        handleAddAnotherInvestment={handleAddAnotherInvestment}
        handleSubmit={handleSubmitAllInvestments}
        handleCancel={handleOpenCancelTPODialog}
        handleOpenEditPage={handleOpenEditPage}
        isSuccessfullySubmitted={isSuccessfullySubmitted}
        isUnsuccessfullySubmitted={isUnsuccessfullySubmitted}
        isSubmitAllLoading={isSubmitAllLoading}
        handlePrimaryButtonUnsuccessfulTPODialogConfirmation={
          handleCloseUnsuccessfullySubmittedTPOConfirmationDialog
        }
        handlePrimaryButtonSuccessfulTPODialogConfirmation={
          handleCloseSuccessfullySubmittedTPOConfirmationDialog
        }
      />
    );

  return (
    <Drawer
      variant="temporary"
      ModalProps={{
        keepMounted: false
      }}
      anchor="right"
      open={isOpen}
      data-testid={ADD_INVESTMENT_DRAWER_TESTID}
    >
      <TPODialog
        title={t('investments.addInvestment.cancelTPODialog.title')}
        content={t('investments.addInvestment.cancelTPODialog.content')}
        textPrimaryButton={t('investments.addInvestment.cancelTPODialog.goBackButton')}
        textSecondaryButton={t('investments.addInvestment.cancelTPODialog.okayButton')}
        isOpen={isCancelTPODialogOpen}
        handlePrimaryButton={handleGoBackButtonCancelTPODialog}
        handleSecondaryButton={handleOkayButtonCancelTPODialog}
      />
      <TPODialog
        title={t('investments.addInvestment.warningTPODialog.title')}
        content={t('investments.addInvestment.warningTPODialog.content')}
        textPrimaryButton={t('investments.addInvestment.warningTPODialog.goBackButton')}
        textSecondaryButton={t('investments.addInvestment.warningTPODialog.okayButton')}
        isOpen={isNewPageWarningTPODialogOpen}
        handlePrimaryButton={handleGoBackButtonWarningTPODialog}
        handleSecondaryButton={handleOkayButtonWarningTPODialog}
        dataTestid={INTEREST_RATE_WARNING_TPO_DIALOG_DATA_TESTID}
      />

      <Stack className={classes.Drawer}>
        <Navigation
          currentSection={section}
          onClickCloseIcon={handleOpenCancelTPODialog}
          handleSectionChange={handleSectionChange}
          addedInvestmentsListCount={investments.length}
          handleTabOnClick={handleOpenEditPageDiscardChangesTPODialog}
        />
        {currentPage}
      </Stack>
    </Drawer>
  );
};

export default AddInvestmentDrawer;
