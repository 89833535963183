import React, { useContext } from 'react';
import { numberFormatting } from '../../../../common/helpers';
import { Line } from 'react-chartjs-2';
import { ChartOptions, Tick } from 'chart.js';
import { Box } from '@mui/material';
import { ReportContext } from '../../../../common/reportContext';

const chartOptions: ChartOptions<'line'> = {
  scales: {
    y: {
      beginAtZero: true,
      suggestedMax: 30,
      stacked: true,
      position: 'right',
      ticks: {
        callback: (tickValue: string | number, index: number, ticks: Tick[]) =>
          numberFormatting(+tickValue)
      }
    },
    x: {
      grid: {
        display: false
      },
      ticks: {
        autoSkip: false,
        callback: function (val, index) {
          // Only display ticks that are the first of the month
          const label = this.getLabelForValue(Number(val));
          return new Date(label).getDate() === 1 ? this.getLabelForValue(Number(val)) : '';
        }
      }
    }
  },
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: (tooltipItem) => {
          return tooltipItem.dataset.label
            ? tooltipItem.dataset.label + `: $${tooltipItem.formattedValue}`
            : `$${tooltipItem.formattedValue}`;
        }
      }
    }
  },
  elements: {
    point: {
      radius: 0
    }
  },
  interaction: {
    mode: 'index'
  }
};

interface TimeHorizonChartProps {
  selectedAccountGroupIndex: number;
}

const TimeHorizonChart = (props: TimeHorizonChartProps) => {
  const { report } = useContext(ReportContext);

  const repeatValue = (val: number) => [...Array(366)].map((_val) => val);

  const levels =
    report.liquidityProficiency.liquidityProficiency[props.selectedAccountGroupIndex]
      .strategicLiquidity.liquidityLevels;

  const investments =
    report.liquidityProficiency.liquidityProficiency[props.selectedAccountGroupIndex].investments;

  const getLabels = (): string[] => {
    return investments.fixedIncome.map((obj) => {
      const [year, month, day] = obj.date.split('-').map((x) => +x);
      const dateObject = new Date(year, month - 1, day);
      return dateObject.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      });
    });
  };

  const level5 = {
    label: 'Level 5',
    data: repeatValue(levels.level5.amount),
    backgroundColor: '#4b74df',
    fill: true,
    stack: 'levels',
    display: levels.level5.amount
  };
  const level4 = {
    label: 'Level 4',
    data: repeatValue(levels.level4.amount),
    backgroundColor: '#015545',
    fill: true,
    stack: 'levels',
    display: levels.level4.amount
  };
  const level3 = {
    label: 'Level 3',
    data: repeatValue(levels.level3.amount),
    backgroundColor: '#460074',
    fill: true,
    stack: 'levels',
    display: levels.level3.amount
  };
  const level2 = {
    label: 'Level 2',
    data: repeatValue(levels.level2.amount),
    backgroundColor: '#c40045',
    fill: true,
    stack: 'levels',
    display: levels.level2.amount
  };
  const level1 = {
    label: 'Level 1',
    data: repeatValue(levels.level1.amount),
    backgroundColor: '#f1b310',
    fill: true,
    stack: 'levels',
    display: levels.level1.amount
  };
  const cushion = {
    label: 'Cushion',
    data: repeatValue(levels.cushion.amount),
    backgroundColor: '#f69436',
    fill: true,
    stack: 'levels',
    display: levels.cushion.amount
  };
  const groupBalance = {
    label: 'Total Balance',
    data: investments.groupBalance.map((obj) => Math.floor(obj.amount)),
    fill: true,
    backgroundColor: '#703b92',
    borderColor: '#703b92',
    display: true
  };

  const chartData = {
    labels: getLabels(),
    datasets: [level5, level4, level3, level2, level1, cushion, groupBalance].filter(
      (set) => set.display
    )
  };

  return (
    <Box>
      <Line data={chartData} options={chartOptions} redraw />
    </Box>
  );
};

export default React.memo(TimeHorizonChart);
