import { TextField, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { AuthContext } from '../../common/authContext';

interface EditTextFieldProps {
  text: string;
  setText: (text: string) => void;
}

const EditTextField = (props: EditTextFieldProps) => {
  const { authState } = useContext(AuthContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setText(event.target.value);
  };

  return authState.elevatedUser ? (
    <TextField
      placeholder="Insert text here"
      multiline
      variant="outlined"
      value={props.text}
      onChange={handleChange}
      sx={{
        width: '100%',
        '& .MuiOutlinedInput-root.Mui-focused': {
          '& > fieldset': {
            borderColor: 'primary.main'
          }
        }
      }}
    />
  ) : (
    <Typography
      sx={{
        fontSize: '16px',
        color: 'text.primary',
        whiteSpace: 'pre-line'
      }}
    >
      {props.text}
    </Typography>
  );
};

export default EditTextField;
