import { Box, Container, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import DownloadScoreCardReport from './DownloadReportPages/DownloadScoreCardReport';
import GreyToGreyDiv from '../../common/grey_grey_div.png';
import { useTranslation } from 'react-i18next';
import DownloadCashboardReport from './DownloadReportPages/DownloadCashboardReport';
import DownloadLiquidProficiencyReport from './DownloadReportPages/DownloadLiquidProficiencyReport';
import DownloadNearTermLiquidityReport from './DownloadReportPages/DownloadNearTermLiquidityReport';
import DownloadAccountOptimizationReport from './DownloadReportPages/DownloadAccountOptimizationReport';
import DownloadTreasuryServicesReport from './DownloadReportPages/DownloadTreasuryServicesReport';

interface DownloadReportProps {
  reportDate: string;
  setPrintState: (val: boolean) => void;
}

const DownloadReport = (props: DownloadReportProps) => {
  const { t } = useTranslation();
  const downloadPDF = () => {
    setTimeout(() => {
      window.print();
      props.setPrintState(false);
    }, 1000);
  };

  useEffect(() => {
    downloadPDF();
  }, []);

  return (
    <Box
      sx={{
        printColorAdjust: 'exact',
        WebkitPrintColorAdjust: 'exact',
        WebkitFilter: 'opacity(1)'
      }}
    >
      <Box sx={{ backgroundColor: '#f2f2f2' }}>
        <img src={GreyToGreyDiv} width="1200px" />
      </Box>
      <Box
        sx={{
          padding: '25px',
          backgroundColor: 'white'
        }}
      >
        <Container>
          <Typography
            sx={{
              fontSize: '48px',
              color: 'primary.main'
            }}
          >
            {t('analysesSummary.title')}
          </Typography>

          <Typography
            sx={{
              fontSize: '18px',
              color: 'text.primary'
            }}
          >
            {t('analysesSummary.detail')}
          </Typography>
          <DownloadScoreCardReport />
          <DownloadCashboardReport reportDate={props.reportDate} />
          <DownloadLiquidProficiencyReport />
          <DownloadNearTermLiquidityReport />
          <DownloadAccountOptimizationReport />
          <DownloadTreasuryServicesReport />
        </Container>
      </Box>
    </Box>
  );
};

export default DownloadReport;
