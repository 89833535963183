import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AccountDetails from '../pages/AccountDetails/AccountDetails';
import Analyses from '../pages/Analyses/Analyses';
import ForgottenPassword from '../pages/ForgottenPassword/ForgottenPassword';
import Login from '../pages/Login/Login';
import RelationshipManager from '../pages/RelationshipManager/RelationshipManager';
import SetupMFA from '../pages/SetupMFA/SetupMFA';
import PasswordSetupSuccess from '../pages/SetupPassword/PasswordSetupSuccess';
import SetupPassword from '../pages/SetupPassword/SetupPassword';
import ProtectedRoute from './ProtecetdRoute';
import { ROUTES } from './ROUTES';
import RedirectHomeRoute from './RedirectHomeRoute';
import Profile from '../pages/Profile/Profile';
import ClientRoute from './ClientRoute';

const RouterConfig = () => (
  <Routes>
    <Route
      path={ROUTES.AccountDetails}
      element={
        <ProtectedRoute>
          <AccountDetails />
        </ProtectedRoute>
      }
    />
    <Route
      path={ROUTES.Analyses}
      element={
        <ProtectedRoute>
          <Analyses />
        </ProtectedRoute>
      }
    />
    <Route
      path={ROUTES.Profile}
      element={
        <ProtectedRoute>
          <ClientRoute>
            <Profile />
          </ClientRoute>
        </ProtectedRoute>
      }
    />
    <Route
      path={ROUTES.RelationshipManager}
      element={
        <ProtectedRoute>
          <RelationshipManager />
        </ProtectedRoute>
      }
    />
    <Route path={ROUTES.ForgottenPassword} element={<ForgottenPassword />} />
    <Route path={ROUTES.SetupPassword} element={<SetupPassword />} />
    <Route path={ROUTES.SetupMFA} element={<SetupMFA />} />
    <Route path={ROUTES.ResetPassword} element={<SetupPassword />} />
    <Route path={ROUTES.ResetPasswordSuccess} element={<PasswordSetupSuccess />} />
    <Route
      path={ROUTES.Logout}
      element={
        <RedirectHomeRoute>
          <Login />
        </RedirectHomeRoute>
      }
    />
  </Routes>
);

export default RouterConfig;
