import React, { useContext } from 'react';
import PaperSection from '../../../../components/PaperSection/PaperSection';
import { Grid } from '@mui/material';
import FinancialSummaryItem from './FinancialSummaryItem';
import { ReportContext } from '../../../../common/reportContext';

const FinancialSummary = () => {
  const { report } = useContext(ReportContext);

  const financialRelationshipSummary = report.cashBoard.financialRelationshipSummary;

  return (
    <PaperSection title="Financial Relationship Summary">
      <Grid
        container
        rowSpacing={5}
        sx={{
          textAlign: 'center'
        }}
      >
        <FinancialSummaryItem
          quantity={financialRelationshipSummary.bankingRelationships}
          type="Banking Relationships"
          detail={`${financialRelationshipSummary.bankingRelationshipsAccounts} accounts`}
        />

        <FinancialSummaryItem
          quantity={financialRelationshipSummary.investmentRelationships}
          type="Investment Relationships"
          detail={`${financialRelationshipSummary.investmentRelationshipsAccounts} accounts`}
        />

        <FinancialSummaryItem
          quantity={financialRelationshipSummary.changeInBankingAccounts}
          type="Change in bank accounts"
          displayDirection={Boolean(financialRelationshipSummary.changeInBankingAccounts)}
        />

        <FinancialSummaryItem
          quantity={financialRelationshipSummary.changeInInvestmentAccounts}
          type="Change in investment accounts"
          displayDirection={Boolean(financialRelationshipSummary.changeInInvestmentAccounts)}
        />
      </Grid>
    </PaperSection>
  );
};

export default FinancialSummary;
