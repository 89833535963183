import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TPOPaper from '../../../components/TPOPaper/TPOPaper';
import { Box } from '@mui/system';
import SecondaryTabButton from '../TabButton/SecondaryTabButton';
import ScorecardSection from './ScorecardSection';
import { ReportContext } from '../../../common/reportContext';
import { CommentsContext } from '../../../common/commentsContext';

enum ScorecardTabState {
  ValueProvided,
  LiquidityProficiency,
  WarnickRate,
  CashFlow,
  Investment
}

const ScorecardTabSection = () => {
  const { t } = useTranslation();
  const [tabState, setTabState] = useState(ScorecardTabState.ValueProvided);
  const { report, setReport } = useContext(ReportContext);
  const { comments, setComments } = useContext(CommentsContext);

  return (
    <TPOPaper color="rgba(70, 0, 116, 0.3)">
      <Box paddingBottom={'20px'}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
          marginBottom={'20px'}
        >
          <SecondaryTabButton
            isActive={tabState == ScorecardTabState.ValueProvided}
            onClick={() => setTabState(ScorecardTabState.ValueProvided)}
            text={t('cashVestAnalyses.scoreCard.scoreWidgetTitle')}
          />
          <SecondaryTabButton
            isActive={tabState == ScorecardTabState.LiquidityProficiency}
            onClick={() => setTabState(ScorecardTabState.LiquidityProficiency)}
            text={t('cashVestAnalyses.scoreCard.liquidityWidgetTitle')}
          />
          <SecondaryTabButton
            isActive={tabState == ScorecardTabState.WarnickRate}
            onClick={() => setTabState(ScorecardTabState.WarnickRate)}
            text={t('cashVestAnalyses.scoreCard.warnickWidgetTitle')}
          />
          <SecondaryTabButton
            isActive={tabState == ScorecardTabState.CashFlow}
            onClick={() => setTabState(ScorecardTabState.CashFlow)}
            text={t('cashVestAnalyses.scoreCard.optimizationWidgetTitle')}
          />
          <SecondaryTabButton
            isActive={tabState == ScorecardTabState.Investment}
            onClick={() => setTabState(ScorecardTabState.Investment)}
            text={t('cashVestAnalyses.scoreCard.investmentPolicyWidgetTitle')}
          />
        </Box>

        {tabState == ScorecardTabState.ValueProvided && (
          <ScorecardSection
            score={report.scorecard.subScores.availableFundsProvidingValue.score}
            summary={{
              text: comments.scoreCard.availableFundsProvidingValue.summary,
              setText: (text) => {
                const newComments = { ...comments };
                newComments.scoreCard.availableFundsProvidingValue.summary = text;
                setComments(newComments);
              }
            }}
            impact={report.scorecard.subScores.availableFundsProvidingValue.impact}
            reason1={{
              text: comments.scoreCard.availableFundsProvidingValue.reason1,
              setText: (text) => {
                const newComments = { ...comments };
                newComments.scoreCard.availableFundsProvidingValue.reason1 = text;
                setComments(newComments);
              }
            }}
            reason2={{
              text: comments.scoreCard.availableFundsProvidingValue.reason2,
              setText: (text) => {
                const newComments = { ...comments };
                newComments.scoreCard.availableFundsProvidingValue.reason2 = text;
                setComments(newComments);
              }
            }}
          />
        )}
        {tabState == ScorecardTabState.LiquidityProficiency && (
          <ScorecardSection
            score={report.scorecard.subScores.liquidityProficiency.score}
            summary={{
              text: comments.scoreCard.liquidityProficiency.summary,
              setText: (text) => {
                const newComments = { ...comments };
                newComments.scoreCard.liquidityProficiency.summary = text;
                setComments(newComments);
              }
            }}
            impact={report.scorecard.subScores.liquidityProficiency.impact}
            reason1={{
              text: comments.scoreCard.liquidityProficiency.reason1,
              setText: (text) => {
                const newComments = { ...comments };
                newComments.scoreCard.liquidityProficiency.reason1 = text;
                setComments(newComments);
              }
            }}
            reason2={{
              text: comments.scoreCard.liquidityProficiency.reason2,
              setText: (text) => {
                const newComments = { ...comments };
                newComments.scoreCard.liquidityProficiency.reason2 = text;
                setComments(newComments);
              }
            }}
          />
        )}
        {tabState == ScorecardTabState.WarnickRate && (
          <ScorecardSection
            score={report.scorecard.subScores.warnickRate.score}
            summary={{
              text: comments.scoreCard.warnickRate.summary,
              setText: (text) => {
                const newComments = { ...comments };
                newComments.scoreCard.warnickRate.summary = text;
                setComments(newComments);
              }
            }}
            impact={report.scorecard.subScores.warnickRate.impact}
            reason1={{
              text: comments.scoreCard.warnickRate.reason1,
              setText: (text) => {
                const newComments = { ...comments };
                newComments.scoreCard.warnickRate.reason1 = text;
                setComments(newComments);
              }
            }}
            reason2={{
              text: comments.scoreCard.warnickRate.reason2,
              setText: (text) => {
                const newComments = { ...comments };
                newComments.scoreCard.warnickRate.reason2 = text;
                setComments(newComments);
              }
            }}
          />
        )}
        {tabState == ScorecardTabState.CashFlow && (
          <ScorecardSection
            score={report.scorecard.subScores.cashFlow.score}
            summary={{
              text: comments.scoreCard.cashFlow.summary,
              setText: (text) => {
                const newComments = { ...comments };
                newComments.scoreCard.cashFlow.summary = text;
                setComments(newComments);
              }
            }}
            impact={report.scorecard.subScores.cashFlow.impact}
            reason1={{
              text: comments.scoreCard.cashFlow.reason1,
              setText: (text) => {
                const newComments = { ...comments };
                newComments.scoreCard.cashFlow.reason1 = text;
                setComments(newComments);
              }
            }}
            reason2={{
              text: comments.scoreCard.cashFlow.reason2,
              setText: (text) => {
                const newComments = { ...comments };
                newComments.scoreCard.cashFlow.reason2 = text;
                setComments(newComments);
              }
            }}
          />
        )}
        {tabState == ScorecardTabState.Investment && (
          <ScorecardSection
            score={report.scorecard.subScores.investment.score}
            summary={{
              text: comments.scoreCard.investmentPolicy.summary,
              setText: (text) => {
                const newComments = { ...comments };
                newComments.scoreCard.investmentPolicy.summary = text;
                setComments(newComments);
              }
            }}
            impact={report.scorecard.subScores.investment.impact}
            reason1={{
              text: comments.scoreCard.investmentPolicy.reason1,
              setText: (text) => {
                const newComments = { ...comments };
                newComments.scoreCard.investmentPolicy.reason1 = text;
                setComments(newComments);
              }
            }}
            reason2={{
              text: comments.scoreCard.investmentPolicy.reason2,
              setText: (text) => {
                const newComments = { ...comments };
                newComments.scoreCard.investmentPolicy.reason2 = text;
                setComments(newComments);
              }
            }}
          />
        )}
      </Box>
    </TPOPaper>
  );
};

export default ScorecardTabSection;
