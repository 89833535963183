import React, { useState } from 'react';
import { AccountsStatement } from '../../../../networking/accounts/statements/AccountsStatement';
import { Stack, Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  stableSort,
  getComparator,
  Order,
  convertToListOfLabel
} from '../../../../components/TPOTable/TPOTableFunctions';
import { currencyFormatting, rateFormatting } from '../../../../common/helpers';
import TPOTable, { Header } from '../../../../components/TPOTable/TPOTable';
import TPOExportDropdown from '../../../../components/TPOExportDropdown/TPOExportDropdown';

export const ACCOUNTS_PAGE_DROPDOWN_TESTID = 'ACCOUNTS_PAGE_DROPDOWN_TESTID';

export interface AccountTableRow {
  account: string;
  financialInstitution: string;
  type: string;
  balanceAsOf: string;
  lastBalance: number;
  monthlyAverage: number;
  monthlyInterest: number;
  interestRate: number;
  status: string;
}
interface AccountTableProps {
  statements: AccountsStatement[];
}

const AccountTable = (props: AccountTableProps) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof AccountTableRow>('account');

  const accountTableHeaders: Header[] = [
    {
      label: t('listOfAccounts.accountTableAccountHeader'),
      numeric: false,
      id: 'account',
      showToolTip: false
    },
    {
      label: t('listOfAccounts.accountTableFinancialInsititutionHeader'),
      numeric: false,
      id: 'financialInstitution',
      showToolTip: false
    },
    {
      label: t('listOfAccounts.accountTableTypeHeader'),
      numeric: false,
      id: 'type',
      showToolTip: false
    },
    {
      label: t('listOfAccounts.accountTableBalanceHeader'),
      numeric: false,
      id: 'balanceAsOf',
      showToolTip: false
    },
    {
      label: t('listOfAccounts.accountTableLastBalanceHeader'),
      numeric: true,
      id: 'lastBalance',
      showToolTip: false
    },
    {
      label: t('listOfAccounts.accountTableMonthlyAverageHeader'),
      numeric: true,
      id: 'monthlyAverage',
      showToolTip: false
    },
    {
      label: t('listOfAccounts.accountTableMonthlyInterestHeader'),
      numeric: true,
      id: 'monthlyInterest',
      showToolTip: true,
      toolTipHeader: t('listOfAccounts.monthlyRateToolTipTitle'),
      toolTipText: t('listOfAccounts.monthlyRateToolTipContent')
    },
    {
      label: t('listOfAccounts.accountTableInterestRateHeader'),
      numeric: true,
      id: 'interestRate',
      showToolTip: true,
      toolTipHeader: t('listOfAccounts.interestRateToolTipTitle'),
      toolTipText: t('listOfAccounts.interestRateToolTipContent')
    },
    {
      label: t('listOfAccounts.accountTableAccountStatus'),
      numeric: false,
      id: 'status',
      showToolTip: false
    }
  ];

  const createData = (statements: AccountsStatement[]): AccountTableRow[] => {
    return statements.map((statement) => {
      return {
        account: `${statement.accountName} - ${statement.accountNumber}`,
        financialInstitution: statement.institutionName,
        type: statement.accountType,
        balanceAsOf: statement.balanceAsOf,
        lastBalance: statement.lastBalance,
        monthlyAverage: statement.monthlyAverage,
        monthlyInterest: statement.monthlyInterest,
        interestRate: statement.interestRate,
        status: statement.isClosed ? 'Closed' : 'Open'
      };
    });
  };

  const rows = createData(props.statements);

  const sortedRows = stableSort(rows, getComparator(order, orderBy));

  const getDataPoints = (obj: AccountTableRow) => {
    // converts object to list of strings
    return [
      obj.account,
      obj.financialInstitution,
      obj.type,
      new Date(obj.balanceAsOf).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'UTC'
      }),
      currencyFormatting(Number(obj.lastBalance), 2),
      currencyFormatting(Number(obj.monthlyAverage), 2),
      currencyFormatting(Number(obj.monthlyInterest), 2),
      rateFormatting(Number(obj.interestRate) * 100),
      obj.status
    ];
  };

  const formattedTableRows = sortedRows.map((row: AccountTableRow) => getDataPoints(row));

  return (
    <Stack
      spacing={2}
      sx={{
        flexDirection: 'row',
        justifyContent: 'flex-end',
        flexWrap: 'wrap'
      }}
    >
      <TPOExportDropdown
        sheetContent={formattedTableRows}
        sheetHeaders={convertToListOfLabel(accountTableHeaders)}
        fileName={'AccountTable'}
        data-testid={ACCOUNTS_PAGE_DROPDOWN_TESTID}
      />
      <TPOTable
        headers={accountTableHeaders}
        content={!showAll ? formattedTableRows.slice(0, 10) : formattedTableRows}
        sortable={{
          order: order,
          setOrder: setOrder,
          orderBy: orderBy,
          setOrderBy: setOrderBy
        }}
      />
      {!showAll && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Button
            variant="outlined"
            onClick={() => setShowAll(true)}
            sx={{
              borderColor: 'primary.main',
              color: 'primary.main',
              backgroundColor: '#ffffff',
              textTransform: 'none',
              fontSize: '16px'
            }}
          >
            Show all
          </Button>
        </Box>
      )}
    </Stack>
  );
};

export default AccountTable;
