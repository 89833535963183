import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography } from '@mui/material';
import { ROUTES } from '../../routing/ROUTES';

interface PageSetupSuccessProps {
  successTitle: string;
  successMessage: string;
}

const PageSetupSuccess = (props: PageSetupSuccessProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Stack
      spacing={1}
      sx={{
        width: '750px',
        marginTop: '10px',
        marginLeft: '45px'
      }}
    >
      <Typography
        sx={{
          fontSize: '45px',
          color: 'primary.main'
        }}
      >
        {props.successTitle}
      </Typography>

      <Typography
        sx={{
          fontSize: '18px',
          color: 'text.primary',
          paddingTop: '20px'
        }}
      >
        {props.successMessage}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          paddingTop: '35px',
          alignItems: 'center'
        }}
      >
        <Button
          variant="contained"
          onClick={() => navigate(ROUTES.Logout)}
          sx={{
            textTransform: 'none',
            borderColor: 'primary.main',
            color: '#ffffff',
            backgroundColor: 'primary.main',
            fontSize: '16px',
            paddingLeft: '30px',
            paddingRight: '30px'
          }}
        >
          {t('navigation.backToLogin')}
        </Button>
      </Box>
    </Stack>
  );
};

export default PageSetupSuccess;
