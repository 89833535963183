import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CommentsContext } from '../../../common/commentsContext';
import { ReportContext } from '../../../common/reportContext';
import { Box, Stack, Typography } from '@mui/material';
import PaperSection from '../../../components/PaperSection/PaperSection';
import BaseSummary from '../../Analyses/BaseSummary/BaseSummary';
import TPOPaper from '../../../components/TPOPaper/TPOPaper';
import LiquidityForecastChart from '../../Analyses/NearTermLiquidity/LiquidityForecast/LiquidityForecastChart';
import ShortTermForecastTable from '../../Analyses/NearTermLiquidity/LiquidityForecast/ShortTermForecastTable/ShortTermForecastTable';
import VarianceChart from '../../Analyses/NearTermLiquidity/Variance/VarianceChart';
import NetChangeTable from '../../Analyses/NearTermLiquidity/NetChange/NetChangeTable';
import Page from '../Page';
import NoBreak from '../NoBreak';
import AnalysisSummary from '../AnalysisSummary';

const DownloadNearTermLiquidityReport = () => {
  const { t } = useTranslation();
  const { comments } = useContext(CommentsContext);
  const { report } = useContext(ReportContext);

  const options = report.nearTermLiquidity.nearTermLiquidity.map((obj) => obj.accountGroup);

  return (
    <Page>
      <Stack spacing={3}>
        <BaseSummary
          title={t('cashVestAnalyses.shortTermLiquidity.title')}
          content={t('cashVestAnalyses.shortTermLiquidity.summary')}
          setContent={() => {}}
        />
        <Stack spacing={2}>
          <PaperSection title={t('common.cashVestAnalysisHeading')} bannerColor={'secondary.main'}>
            {/* RM comment for forecast */}
            <AnalysisSummary
              body={comments.nearTermLiquidity.forecast}
              heading={t('cashVestAnalyses.shortTermLiquidity.liquidityForecastTitle')}
            />
            {/* RM comment for net change */}
            <AnalysisSummary
              body={comments.nearTermLiquidity.net}
              heading={t('cashVestAnalyses.shortTermLiquidity.monthlyNetChangeTitle')}
            />
            {/* RM comment for variance chart */}
            <AnalysisSummary
              body={comments.nearTermLiquidity.variance}
              heading={t('cashVestAnalyses.shortTermLiquidity.varianceChartTitle')}
            />
          </PaperSection>
        </Stack>
      </Stack>
      {options.map((accountGroup, index) => (
        <Page key={`three+one MC® Liquidity Forecast Model - ${accountGroup}`}>
          <PaperSection title={accountGroup} bannerColor={'primary.main'}>
            {/* Liquidity Forecast Model chart and table */}
            <PaperSection title={`three+one MC® Liquidity Forecast Model`}>
              <Box
                sx={{
                  marginBottom: '30px'
                }}
              >
                <NoBreak>
                  <TPOPaper color="rgba(70, 0, 116, 0.3)">
                    <LiquidityForecastChart selectedAccountGroupIndex={index} />
                  </TPOPaper>
                </NoBreak>
                <TPOPaper color="rgba(70, 0, 116, 0.3)">
                  <ShortTermForecastTable selectedAccountGroupIndex={index} />
                </TPOPaper>
              </Box>
            </PaperSection>
            {/* Net change Table */}
            <NoBreak>
              <PaperSection title="Monthly Net Change in Cash">
                <NetChangeTable selectedAccountGroupIndex={index} />
              </PaperSection>
            </NoBreak>
            {/* Three variance Charts */}
            {['Day-to-day Variance', 'Weekly Variance', 'Monthly Variance'].map(
              (value, chartIndex) => (
                <NoBreak key={`${value} - ${chartIndex}`}>
                  <PaperSection title={value}>
                    <VarianceChart selectedAccountGroupIndex={index} chartIndex={chartIndex} />
                  </PaperSection>
                </NoBreak>
              )
            )}
          </PaperSection>
        </Page>
      ))}
    </Page>
  );
};

export default DownloadNearTermLiquidityReport;
