import axiosClient from '../axios-api';
import { UserInfo } from '../../common/interfaces/UserInfo.types';

export const getUserInfo = async (bearerToken: string): Promise<UserInfo> => {
  let finalResponse: UserInfo = {
    role: null,
    userName: '',
    allClients: [],
    myClients: []
  };

  const apiUrl = '/user/login';
  await axiosClient({
    method: 'GET',
    url: apiUrl,
    headers: {
      Authorization: `Bearer ${bearerToken}`
    }
  })
    .then((response) => {
      finalResponse = response.data;
    })
    .catch((error) =>
      console.log('An error has occured when fetching user info - ', error?.message)
    );

  return finalResponse;
};
