import React, { useMemo, useState, useEffect, useContext } from 'react';
import AnalysesSummary from './AnalysesSummary/AnalysesSummary';
import { Box } from '@mui/material';
import TabButton from './TabButton/TabButton';
import TPOPaper from '../../components/TPOPaper/TPOPaper';
import Scorecard from './Scorecard/Scorecard';
import CashBoard from './CashBoard/CashBoard';
import LiquidProficiency from './LiquidProficiency/LiquidProficiency';
import NearTermLiquidity from './NearTermLiquidity/NearTermLiquidity';
import AccountOptimization from './AccountOptimization/AccountOptimization';
import TreasuryServices from './TreasuryServices/TreasuryServices';
import GreyToGreyDiv from '../../common/grey_grey_div.png';
import { useTranslation } from 'react-i18next';
import { Comments, emptyComments } from '../../networking/analyses/comments/Comments.types';
import {
  AnalysesReport,
  emptyAnalyses,
  PublishReportResponse,
  ReportItem
} from '../../networking/analyses/Analyses.types';
import { emptyReport, Report, ReportResponse } from '../../networking/analyses/report/Report';
import { CommentsContext } from '../../common/commentsContext';
import { ReportContext } from '../../common/reportContext';
import { getAnalysis } from '../../networking/analyses/getAnalysis';
import { AuthContext } from '../../common/authContext';
import { getReport } from '../../networking/analyses/report/getReport';
import { getComments } from '../../networking/analyses/comments/getComments';
import { getReportByVersion } from '../../networking/analyses/report/versioning/getReportByVersion';
import DownloadReport from '../DownloadReport/DownloadReport';

enum TabState {
  Scorecard,
  CashBoard,
  LiquidProficiency,
  NearTermLiquidity,
  AccountOptimization,
  TreasuryServices
}

function determineComments(response: any): response is Comments {
  return 'scoreCard' in response;
}

const sortAnalysisReports = (analysesReport: AnalysesReport) => {
  // Sort by end date and by report id
  analysesReport.reports.reverse(); // set latest report id to be first
  analysesReport.reports.sort((a, b) => b.reportPeriodTo.localeCompare(a.reportPeriodTo));
};

const Analyses = () => {
  const [printState, setPrintState] = useState(false);
  const [analysis, setAnalysis] = useState<AnalysesReport>(emptyAnalyses);
  const [report, setReport] = useState<Report>(emptyReport);
  const reportContext = useMemo(
    () => ({
      report,
      setReport
    }),
    [report]
  );

  const [comments, setComments] = useState<Comments>(emptyComments);
  const commentsContext = useMemo(
    () => ({
      comments,
      setComments
    }),
    [comments]
  );

  const { authState } = useContext(AuthContext);

  const [index, setIndex] = useState(0);
  const [isReportLoading, setIsReportLoading] = useState(false);

  const [publishedDates, setPublishedDates] = useState(['No reports']);
  const [dateRanges, setDateRanges] = useState(['No reports']);
  const [reportIds, setReportIds] = useState([0]);

  useEffect(() => {
    setIsReportLoading(true);
    getAnalysis(authState).then((result) => {
      const analysesReport = result as AnalysesReport;
      sortAnalysisReports(analysesReport);
      setAnalysis(analysesReport);
      setIsReportLoading(false);
    });
  }, []);

  useEffect(() => {
    if (analysis.reports && analysis.reports.length > 0) {
      // filter out unpublished reports for non-elevated users
      !authState.elevatedUser &&
        (analysis.reports = analysis.reports.filter((report) => report.published));
      setIsReportLoading(true);
      getReport(authState.clientId, analysis.reports[index].financialReportKey)
        .then((reportResponse) => {
          const report = getReportByVersion(reportResponse as ReportResponse);
          setReport(report as Report);
          setIsReportLoading(false);
        })
        .catch((error) => console.log(error));
      setIsReportLoading(true);
      getComments(
        authState.clientId,
        analysis.reports[index].commentsReportKey,
        analysis.reports[index].updatedAt
      )
        .then((commentsRes) => {
          if (determineComments(commentsRes)) {
            setComments(commentsRes);
          } else {
            setComments(emptyComments);
          }
          setIsReportLoading(false);
        })
        .catch((error) => console.log('No comments found'));
    }
  }, [analysis, index]);

  useEffect(() => {
    const dates = analysis.reports.map((item: ReportItem) =>
      new Date(item.publishedDate).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'UTC'
      })
    );
    setPublishedDates(dates);
    const ranges = analysis.reports.map((item: ReportItem) => {
      const start = new Date(item.reportPeriodFrom).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: 'UTC'
      });
      const end = new Date(item.reportPeriodTo).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: 'UTC'
      });
      return `${start} - ${end}`;
    });
    setDateRanges(ranges);
    const reportIds = analysis.reports.map((item: ReportItem) => item.reportId);
    setReportIds(reportIds);
  }, [analysis]);

  const [tabState, setTabState] = useState(TabState.Scorecard);
  const [dateRangeIndex, setDateRangeIndex] = useState(0);

  const { t } = useTranslation();

  const onPeriodSelect = (index: number): void => {
    setDateRangeIndex(index);
  };

  const onReportUpdate = (change: PublishReportResponse, reportIndex: number): void => {
    const reports = [...analysis.reports];
    const reportItem = reports[reportIndex];
    if (reportItem) {
      reportItem.published = change.published;
      reportItem.publishedDate = change.publishedDate;
      setAnalysis({ reports: reports });
    }
  };

  const onCommentsSaved = (reportIndex: number): void => {
    const reports = [...analysis.reports];
    const reportItem = reports[reportIndex];
    if (reportItem) {
      reportItem.updatedAt = new Date().getTime().toString();
      setAnalysis({ reports: reports });
    }
  };

  return (
    <CommentsContext.Provider value={commentsContext}>
      <ReportContext.Provider value={reportContext}>
        {!printState && (
          <>
            <Box
              sx={{
                backgroundColor: '#f2f2f2'
              }}
            >
              <img src={GreyToGreyDiv} width="1200px" />
            </Box>
            <Box
              sx={{
                padding: '25px',
                backgroundColor: '#f2f2f2'
              }}
            >
              <AnalysesSummary
                dateRanges={dateRanges}
                publishedDates={publishedDates}
                reportIds={reportIds}
                onPeriodSelect={onPeriodSelect}
                index={index}
                setIndex={setIndex}
                isPublished={
                  analysis.reports.length > 0 ? analysis.reports[index].published : false
                }
                setPrintState={setPrintState}
                onReportUpdate={onReportUpdate}
                onCommentsSaved={onCommentsSaved}
                reportLoading={isReportLoading}
              />

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  marginTop: '50px'
                }}
              >
                <TabButton
                  text={t('cashVestAnalyses.scoreCard.tab')}
                  isActive={tabState == TabState.Scorecard}
                  onClick={() => setTabState(TabState.Scorecard)}
                />
                <TabButton
                  text={t('cashVestAnalyses.cashBoard.tab')}
                  isActive={tabState == TabState.CashBoard}
                  onClick={() => setTabState(TabState.CashBoard)}
                />
                <TabButton
                  text={t('cashVestAnalyses.liquidityProficiency.tab')}
                  isActive={tabState == TabState.LiquidProficiency}
                  onClick={() => setTabState(TabState.LiquidProficiency)}
                />
                <TabButton
                  text={t('cashVestAnalyses.shortTermLiquidity.tab')}
                  isActive={tabState == TabState.NearTermLiquidity}
                  onClick={() => setTabState(TabState.NearTermLiquidity)}
                />
                <TabButton
                  text={t('cashVestAnalyses.accountOptimization.tab')}
                  isActive={tabState == TabState.AccountOptimization}
                  onClick={() => setTabState(TabState.AccountOptimization)}
                />
                <TabButton
                  text={t('cashVestAnalyses.treasuryServices.tab')}
                  isActive={tabState == TabState.TreasuryServices}
                  onClick={() => setTabState(TabState.TreasuryServices)}
                />
              </Box>
              <TPOPaper color="rgba(70, 0, 116, 0.3)">
                <Box padding={5}>
                  {tabState == TabState.Scorecard && <Scorecard />}
                  {tabState == TabState.CashBoard && (
                    <CashBoard reportDate={dateRanges[dateRangeIndex]} />
                  )}
                  {tabState == TabState.LiquidProficiency && <LiquidProficiency />}
                  {tabState == TabState.NearTermLiquidity && <NearTermLiquidity />}
                  {tabState == TabState.AccountOptimization && <AccountOptimization />}
                  {tabState == TabState.TreasuryServices && <TreasuryServices />}
                </Box>
              </TPOPaper>
            </Box>
          </>
        )}
        {printState && (
          <DownloadReport reportDate={dateRanges[dateRangeIndex]} setPrintState={setPrintState} />
        )}
      </ReportContext.Provider>
    </CommentsContext.Provider>
  );
};

export default Analyses;
