import { ThemeProvider } from '@emotion/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import theme from './theme';
import { Amplify } from 'aws-amplify';
import amplifyConfig from './amplifyConfig';
import './i18n';
import { BrowserRouter } from 'react-router-dom';

Amplify.configure(amplifyConfig);

const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);
