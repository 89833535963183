import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CommentsContext } from '../../../common/commentsContext';
import { ReportContext } from '../../../common/reportContext';
import { Box, Paper, Stack, Typography } from '@mui/material';
import PaperSection from '../../../components/PaperSection/PaperSection';
import BaseSummary from '../../Analyses/BaseSummary/BaseSummary';
import TreasuryServicesTable from '../../Analyses/TreasuryServices/TreasuryServicesTable';
import TPOPaper from '../../../components/TPOPaper/TPOPaper';
import LineItemFeesChart from '../../Analyses/TreasuryServices/LineItemFees/LineItemFeesChart';
import LineItemFeesTable from '../../Analyses/TreasuryServices/LineItemFees/LineItemFeesTable';
import Page from '../Page';
import AnalysisSummary from '../AnalysisSummary';

const DownloadTreasuryServicesReport = () => {
  const { t } = useTranslation();
  const { comments } = useContext(CommentsContext);
  const { report } = useContext(ReportContext);

  const options: string[] = report.treasuryServices.treasuryServices.map((obj) => obj.accountGroup);

  return (
    <Page>
      <Stack spacing={3}>
        <BaseSummary
          title={t('cashVestAnalyses.treasuryServices.title')}
          content={comments.treasuryServices.summary}
          setContent={() => {}}
        />
        <PaperSection title={t('common.cashVestAnalysisHeading')} bannerColor={'secondary.main'}>
          {/* RM comment for balance summary tab */}
          <AnalysisSummary
            heading={t('cashVestAnalyses.treasuryServices.BalanceSummaryTabTitle')}
            body={comments.treasuryServices.balanceSummary}
          />
          {/* RM comment for balance ECR tab */}
          <AnalysisSummary
            heading={t('cashVestAnalyses.treasuryServices.ECRTabTitle')}
            body={comments.treasuryServices.ecr}
          />
          {/* RM comment for total fees and credits tab */}
          <AnalysisSummary
            heading={t('cashVestAnalyses.treasuryServices.TotalFeesAndCreditsTabTitle')}
            body={comments.treasuryServices.compensatingOffset}
          />
          {/* RM comment for Line Item fees tab */}
          <AnalysisSummary
            heading={t('cashVestAnalyses.treasuryServices.LineItemFeesTabTitle')}
            body={comments.treasuryServices.lineItem}
          />
        </PaperSection>
      </Stack>

      {options.map((accountGroup, index) => (
        <Page key={`Treasury Analysis - ${accountGroup}`}>
          <PaperSection title={accountGroup} bannerColor={'primary.main'}>
            <Stack spacing={2}>
              {/* Balance, Offset and ECR tabs */}
              {[0, 1, 2].map((tabState) => (
                <TPOPaper color="rgba(70, 0, 116, 0.3)" key={`${accountGroup} table -${tabState}`}>
                  <TreasuryServicesTable tabState={tabState} selectedAccountGroupIndex={index} />
                </TPOPaper>
              ))}
            </Stack>
            {/* Line Item fees tab */}
            <Page>
              <PaperSection title="Line Item Fees Table">
                <Box padding={3}>
                  <LineItemFeesTable selectedAccountGroupIndex={index} />
                </Box>
              </PaperSection>
            </Page>
            <Page>
              <PaperSection title="Line Item Fees Chart">
                <LineItemFeesChart selectedAccountGroupIndex={index} />
              </PaperSection>
            </Page>
          </PaperSection>
        </Page>
      ))}
    </Page>
  );
};

export default DownloadTreasuryServicesReport;
