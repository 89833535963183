import React, { useState, useContext } from 'react';
import PaperSection from '../../../../components/PaperSection/PaperSection';
import { Stack, Typography, SelectChangeEvent, Box, Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChartOptions, Tick } from 'chart.js';
import { Line } from 'react-chartjs-2';
import ToolTip from '../../../../components/ToolTip/ToolTip';
import EditTextField from '../../../../components/EditTextField/EditTextField';
import { CommentsContext } from '../../../../common/commentsContext';
import { ReportContext } from '../../../../common/reportContext';
import { numberFormatting } from '../../../../common/helpers';
import VarianceChart from './VarianceChart';

interface VarianceProps {
  selectedAccountGroupIndex: number;
}

const Variance = (props: VarianceProps) => {
  const { t } = useTranslation();
  const { report } = useContext(ReportContext);
  const { comments, setComments } = useContext(CommentsContext);
  const variance =
    report.nearTermLiquidity.nearTermLiquidity[props.selectedAccountGroupIndex].variance;

  const [index, setIndex] = useState(0);

  const options = ['Daily', 'Weekly', 'Monthly'];

  const handleChange = (event: SelectChangeEvent) => {
    setIndex(+event.target.value);
  };

  return (
    <PaperSection title={t('cashVestAnalyses.shortTermLiquidity.varianceChartTitle')}>
      <Stack spacing={2}>
        <Typography
          sx={{
            color: 'text.primary',
            fontSize: '18px',
            fontWeight: 650
          }}
        >
          {t('common.cashVestAnalysisHeading')}
        </Typography>
        <EditTextField
          text={comments.nearTermLiquidity.variance}
          setText={(text) => {
            const newComments = { ...comments };
            newComments.nearTermLiquidity.variance = text;
            setComments(newComments);
          }}
        />
        <Box>
          <Typography
            sx={{
              fontSize: '18px',
              color: 'text.primary',
              fontWeight: 650,
              marginBottom: '10px'
            }}
          >
            {t('common.selectVariance')}
          </Typography>

          <Box>
            <Select
              value={String(index)}
              onChange={handleChange}
              sx={{
                backgroundColor: '#f2f2f2',
                width: '350px',
                height: '40px'
              }}
            >
              {options.map((option, i) => (
                <MenuItem value={i} key={i}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>

        <VarianceChart
          selectedAccountGroupIndex={props.selectedAccountGroupIndex}
          chartIndex={index}
        />
        <ToolTip
          title={t('cashVestAnalyses.shortTermLiquidity.varianceChartToolTipTitle')}
          content={t('cashVestAnalyses.shortTermLiquidity.varianceChartToolTipContent')}
          label={t('common.explainChart')}
        />
      </Stack>
    </PaperSection>
  );
};

export default Variance;
