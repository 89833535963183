import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';

import HeaderDiv from '../../common/header_divider.png';
import {
  getUserDetails,
  getUserEmailPreferences,
  updateUserDetails,
  updateUserEmailPreferences
} from '../../networking/profile/userDetails/userDetails';
import {
  UserDetails,
  UserEmailPreferences
} from '../../networking/profile/userDetails/UserDetails.types';
import { useTranslation } from 'react-i18next';
import EditNameDrawer from './EditNameDrawer/EditNameDrawer';
import EditPhoneNumberDrawer from './EditPhoneNumberDrawer/EditPhoneNumberDrawer';
import EditEmailPreferencesDrawer from './EditEmailPreferencesDrawer/EditEmailPreferencesDrawer';

interface ColumnProps extends React.PropsWithChildren {
  title: string;
}

const Column = ({ title, children }: ColumnProps) => (
  <Stack sx={{ width: '380px' }}>
    <Typography
      sx={{
        fontSize: 20,
        color: 'white',
        marginBottom: 4,
        backgroundColor: 'primary.main',
        padding: '8px',
        paddingLeft: '14px'
      }}
    >
      {title}
    </Typography>
    {children}
  </Stack>
);

const Profile = () => {
  const { t } = useTranslation();

  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [userEmailPreferences, setUserEmailPreferences] = useState<UserEmailPreferences | null>(
    null
  );

  const [isEditNameDrawerOpen, setIsEditNameDrawerOpen] = useState(false);
  const [isEditPhoneNumberDrawerOpen, setIsEditPhoneNumberDrawerOpen] = useState(false);
  const [isEditEmailPreferencesDrawerOpen, setIsEditEmailPreferencesDrawerOpen] = useState(false);

  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorFetchingDataSnackbarOpen, setErrorFetchingDataSnackbarOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const fetchUserDetails = async () => {
    const fetchedUserDetails = await getUserDetails();
    setUserDetails(fetchedUserDetails);
  };

  const fetchUserEmailPreferences = async () => {
    const fetchedUserEmailPreferences = await getUserEmailPreferences();
    setUserEmailPreferences(fetchedUserEmailPreferences);
  };

  const handleCloseEditNameDrawer = () => {
    setIsEditNameDrawerOpen(false);
  };

  const handleCloseEditPhoneNumberDrawer = () => {
    setIsEditPhoneNumberDrawerOpen(false);
  };

  const handleOpenEmailPreferencesDrawer = () => setIsEditEmailPreferencesDrawerOpen(true);
  const handleCloseEmailPreferencesDrawer = () => setIsEditEmailPreferencesDrawerOpen(false);

  const handleOpenSuccessSnackbar = () => setSuccessSnackbarOpen(true);
  const handleCloseSuccessSnackbar = () => setSuccessSnackbarOpen(false);

  const handleOpenErrorSnackbar = () => setErrorSnackbarOpen(true);
  const handleCloseErrorSnackbar = () => setErrorSnackbarOpen(false);

  const handleOpenErrorFetchingDataSnackbarOpen = () => setErrorFetchingDataSnackbarOpen(true);
  const handleCloseErrorFetchingDataSnackbarOpen = () => setErrorFetchingDataSnackbarOpen(false);

  const fetchData = async () => {
    try {
      await fetchUserDetails();
    } catch (e) {
      handleOpenErrorFetchingDataSnackbarOpen();
    }

    try {
      await fetchUserEmailPreferences();
    } catch (e) {
      handleOpenErrorFetchingDataSnackbarOpen();
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box>
      <Box>
        <img src={HeaderDiv} width="1200px" alt="header-background1" />
      </Box>

      <Grid container spacing={2} sx={{ margin: '80px' }}>
        <Grid item xs={6}>
          <Column title={t('yourProfile.personalDetails.title')}>
            {isLoading ? (
              <Typography ml={1}>{t('yourProfile.loadingMessage')}</Typography>
            ) : userDetails === null ? (
              <Typography ml={1}>{t('yourProfile.personalDetails.errorFetchingData')}</Typography>
            ) : (
              <>
                <EditNameDrawer
                  isOpen={isEditNameDrawerOpen}
                  handleCloseDrawer={handleCloseEditNameDrawer}
                  updateUserDetails={updateUserDetails}
                  onDetailsUpdated={fetchUserDetails}
                  onSuccess={handleOpenSuccessSnackbar}
                  onError={handleOpenErrorSnackbar}
                  currentUserDetails={userDetails}
                />

                <EditPhoneNumberDrawer
                  isOpen={isEditPhoneNumberDrawerOpen}
                  handleCloseDrawer={handleCloseEditPhoneNumberDrawer}
                  updateUserDetails={updateUserDetails}
                  onDetailsUpdated={fetchUserDetails}
                  onSuccess={handleOpenSuccessSnackbar}
                  onError={handleOpenErrorSnackbar}
                  currentUserDetails={userDetails}
                />

                <Box sx={{ paddingLeft: '10px' }}>
                  <Typography sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                    {t('yourProfile.personalDetails.firstName')}
                  </Typography>
                  <Typography sx={{ marginBottom: 4 }}> {userDetails.firstName}</Typography>
                </Box>

                <Box sx={{ paddingLeft: '10px' }}>
                  <Typography sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                    {t('yourProfile.personalDetails.lastName')}
                  </Typography>
                  <Typography> {userDetails.lastName}</Typography>
                </Box>

                <Button
                  variant="outlined"
                  onClick={() => setIsEditNameDrawerOpen(true)}
                  sx={{
                    borderColor: 'primary.main',
                    color: 'primary.main',
                    backgroundColor: '#ffffff',
                    textTransform: 'none',
                    fontSize: '16px',
                    width: '250px',
                    marginTop: '30px',
                    marginBottom: '65px'
                  }}
                >
                  {t('yourProfile.personalDetails.editName.updateName')}
                </Button>

                <Box sx={{ paddingLeft: '10px' }}>
                  <Typography sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                    {t('yourProfile.personalDetails.phoneNumber')}
                  </Typography>
                  <Typography>
                    {userDetails.phoneNumber
                      ? userDetails.phoneNumber
                      : t('yourProfile.personalDetails.noPhoneNumberStored')}
                  </Typography>
                </Box>

                <Button
                  variant="outlined"
                  onClick={() => setIsEditPhoneNumberDrawerOpen(true)}
                  sx={{
                    borderColor: 'primary.main',
                    color: 'primary.main',
                    backgroundColor: '#ffffff',
                    textTransform: 'none',
                    fontSize: '16px',
                    width: '250px',
                    marginTop: '30px'
                  }}
                >
                  {t('yourProfile.personalDetails.editPhoneNumber.updateNumber')}
                </Button>
              </>
            )}
          </Column>
        </Grid>
        <Grid item xs={6}>
          <Column title={t('yourProfile.emailPreferences.title')}>
            {isLoading ? (
              <Typography ml={1}>{t('yourProfile.loadingMessage')}</Typography>
            ) : userEmailPreferences === null ? (
              <Typography ml={1}>{t('yourProfile.emailPreferences.errorFetchingData')}</Typography>
            ) : (
              <>
                <EditEmailPreferencesDrawer
                  isOpen={isEditEmailPreferencesDrawerOpen}
                  handleCloseDrawer={handleCloseEmailPreferencesDrawer}
                  onSuccess={handleOpenSuccessSnackbar}
                  onError={handleOpenErrorSnackbar}
                  updateUserEmailPreferences={updateUserEmailPreferences}
                  onEmailPreferencesUpdated={fetchUserEmailPreferences}
                  currentUserEmailPreferences={userEmailPreferences}
                />

                <Stack
                  sx={{ padding: '0 20px 0 10px', justifyContent: 'space-between' }}
                  direction="row"
                  mb={4}
                >
                  <Typography>
                    {t('yourProfile.emailPreferences.fields.oneDayInvestmentMaturitySubscription')}
                  </Typography>
                  <Typography>
                    {userEmailPreferences.oneDayInvestmentMaturitySubscription ? 'On' : 'Off'}
                  </Typography>
                </Stack>

                <Stack
                  sx={{ padding: '0 20px 0 10px', justifyContent: 'space-between' }}
                  direction="row"
                  mb={6}
                >
                  <Typography>
                    {t(
                      'yourProfile.emailPreferences.fields.sevenDayInvestmentMaturitySubscription'
                    )}
                  </Typography>
                  <Typography>
                    {userEmailPreferences.sevenDayInvestmentMaturitySubscription ? 'On' : 'Off'}
                  </Typography>
                </Stack>

                <Button
                  variant="outlined"
                  onClick={handleOpenEmailPreferencesDrawer}
                  sx={{
                    borderColor: 'primary.main',
                    color: 'primary.main',
                    backgroundColor: '#ffffff',
                    textTransform: 'none',
                    fontSize: '16px',
                    width: '250px'
                  }}
                >
                  {t('yourProfile.emailPreferences.edit.actionButton')}
                </Button>
              </>
            )}
          </Column>
        </Grid>
      </Grid>

      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSuccessSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSuccessSnackbar} severity="success">
          {t('yourProfile.personalDetails.updateSucess')}
        </Alert>
      </Snackbar>

      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseErrorSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseErrorSnackbar} severity="error">
          {t('yourProfile.personalDetails.updateError')}
        </Alert>
      </Snackbar>

      <Snackbar
        open={errorFetchingDataSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseErrorFetchingDataSnackbarOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseErrorFetchingDataSnackbarOpen} severity="error">
          {t('yourProfile.errorFetchingData')}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Profile;
