import React, { useState, useContext, useEffect, useRef } from 'react';
import { Box, Typography, Select, SelectChangeEvent, MenuItem, Stack, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FEATURE_FLAGS from '../../../featureFlags';
import { CommentsContext } from '../../../common/commentsContext';
import { postComments } from '../../../networking/analyses/comments/postComments';
import { AuthContext } from '../../../common/authContext';
import { publishReport } from '../../../networking/analyses/report/publishReport';
import { unpublishReport } from '../../../networking/analyses/report/unpublishReport';
import { PublishReportResponse } from '../../../networking/analyses/Analyses.types';

interface AnalysesSummaryProps {
  dateRanges: string[];
  publishedDates: string[];
  reportIds: number[];
  onPeriodSelect: (dateRangeIndex: number) => void;
  index: number;
  setIndex: (val: number) => void;
  isPublished: boolean;
  setPrintState: (val: boolean) => void;
  onReportUpdate: (change: PublishReportResponse, index: number) => void;
  onCommentsSaved: (reportIndex: number) => void;
  reportLoading: boolean;
}

const DATE_RANGE_PLACEHOLDER = Array(18).join('_');

const AnalysesSummary = ({
  dateRanges,
  publishedDates,
  reportIds,
  onPeriodSelect,
  index,
  setIndex,
  isPublished,
  setPrintState,
  onReportUpdate,
  onCommentsSaved,
  reportLoading
}: AnalysesSummaryProps) => {
  const { t } = useTranslation();
  const [published, setPublished] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    setPublished(isPublished);
  }, [isPublished]);

  useEffect(() => {
    setIsUpdating(reportLoading);
  }, [reportLoading]);

  const handleChange = (event: SelectChangeEvent) => {
    const nextIndex = +event.target.value;
    setIndex(nextIndex);
    onPeriodSelect(nextIndex);
    setPublished(isPublished);
  };

  const handleExportAnalyses = (): void => {
    setPrintState(true);
  };

  const { authState } = useContext(AuthContext);
  const { comments } = useContext(CommentsContext);

  const handleSaveComments = () => {
    setIsUpdating(true);
    postComments(authState, comments, reportIds[index]).then(() => {
      onCommentsSaved(index);
      setIsUpdating(false);
    });
  };

  const handlePublishReport = () => {
    setIsUpdating(true);
    publishReport(authState, reportIds[index]).then((response) => {
      onReportUpdate(response, index);
      setIsUpdating(false);
    });
    setPublished(true);
  };

  const handleUnpublishReport = () => {
    setIsUpdating(true);
    unpublishReport(authState, reportIds[index]).then((response) => {
      onReportUpdate(response, index);
      setIsUpdating(false);
    });
    setPublished(false);
  };

  const getDatePublished = (publishedDate: string) =>
    new Date(publishedDate).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      timeZone: 'UTC'
    });

  /**
   *
   * @param publishedDate - Published date of the report or the lack of it. Indicated by null.
   * @returns The current status of the report. Threee versions available:
   * 1. No reports available - for no reports for this client
   * 2. Report Status: unpublished - for an unpublished report
   * 3. Report Status: published on {publishedDate} - for a published report
   */
  const getReportText = (publishedDate: string | null) => {
    if (publishedDate === null) return 'No reports available';

    if (publishedDate.includes('1970')) return t('analysesSummary.statusUnpublished');

    return t('analysesSummary.statusPublished', {
      datePublished: getDatePublished(publishedDates[index])
    });
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '100% auto'
      }}
    >
      <Stack spacing={3}>
        <Typography
          sx={{
            fontSize: '48px',
            color: 'primary.main'
          }}
        >
          {t('analysesSummary.title')}
        </Typography>

        <Typography
          sx={{
            fontSize: '18px',
            color: 'text.primary'
          }}
        >
          {t('analysesSummary.detail')}
        </Typography>

        <Stack spacing={1}>
          <Typography
            sx={{
              color: 'text.primary',
              fontWeight: 650
            }}
          >
            {t('analysesSummary.selectTime')}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gridGap: '20px'
            }}
          >
            <Select
              value={String(index)}
              onChange={handleChange}
              disabled={!dateRanges.length || isUpdating}
              sx={{
                backgroundColor: 'text.secondary',
                '&.Mui-disabled': {
                  color: 'text.secondary',
                  opacity: '50%'
                }
              }}
            >
              {dateRanges.length ? (
                dateRanges.map((dateRange, i) => (
                  <MenuItem value={i} key={i}>
                    {dateRange}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={0} key={0}>
                  {DATE_RANGE_PLACEHOLDER}
                </MenuItem>
              )}
            </Select>
            {FEATURE_FLAGS.downloadReport == 'true' && (
              <Button
                onClick={handleExportAnalyses}
                disabled={!dateRanges.length || isUpdating}
                sx={{
                  textTransform: 'none',
                  borderColor: 'primary.main',
                  color: 'text.secondary',
                  backgroundColor: 'primary.main',
                  fontSize: '16px',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                  '&.Mui-disabled': {
                    color: 'text.secondary',
                    opacity: '50%'
                  }
                }}
              >
                {t('analysesSummary.downloadReport')}
              </Button>
            )}
            {authState.elevatedUser && !published && (
              <Button
                onClick={handleSaveComments}
                disabled={isUpdating}
                sx={{
                  textTransform: 'none',
                  borderColor: 'primary.main',
                  color: 'text.secondary',
                  backgroundColor: 'primary.main',
                  fontSize: '16px',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                  '&.Mui-disabled': {
                    color: 'text.secondary',
                    opacity: '50%'
                  }
                }}
              >
                {t('analysesSummary.saveComments')}
              </Button>
            )}
            {authState.elevatedUser &&
              (published ? (
                <Button
                  onClick={handleUnpublishReport}
                  disabled={isUpdating}
                  sx={{
                    textTransform: 'none',
                    borderColor: 'primary.main',
                    color: 'text.secondary',
                    backgroundColor: 'primary.main',
                    fontSize: '16px',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    '&.Mui-disabled': {
                      color: 'text.secondary',
                      opacity: '50%'
                    }
                  }}
                >
                  {t('analysesSummary.unpublishReport')}
                </Button>
              ) : (
                <Button
                  onClick={handlePublishReport}
                  disabled={isUpdating}
                  sx={{
                    textTransform: 'none',
                    borderColor: 'primary.main',
                    color: 'text.secondary',
                    backgroundColor: 'primary.main',
                    fontSize: '16px',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    '&.Mui-disabled': {
                      color: 'text.secondary',
                      opacity: '50%'
                    }
                  }}
                >
                  {t('analysesSummary.publishReport')}
                </Button>
              ))}
          </Box>

          <Typography
            sx={{
              fontSize: '16px',
              color: 'text.primary'
            }}
          >
            {getReportText(publishedDates.length ? publishedDates[index] : null)}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AnalysesSummary;
