import { UserDetails, UserEmailPreferences } from './UserDetails.types';
import axiosClient from '../../axios-api';
import { getToken } from '../../../auth/authenticator';
import DEVELOPMENT_FLAGS from '../../../developmentFlags';
import responseUserDetails from '../../../mock/userDetails/responseUserDetails.json';
import responseUserEmailPreferences from '../../../mock/userDetails/responseUserEmailPreferences.json';

export const getUserEmailPreferences = async (): Promise<UserEmailPreferences> => {
  if (DEVELOPMENT_FLAGS.isMock === 'true') return responseUserEmailPreferences;

  const apiUrl = `/user/email-preferences`;

  const result = await axiosClient.get<UserEmailPreferences>(apiUrl, {
    headers: {
      Authorization: `Bearer ${await getToken()}`
    }
  });

  return result.data;
};

export const updateUserEmailPreferences = async (
  userEmailPreferences: UserEmailPreferences
): Promise<void> => {
  if (DEVELOPMENT_FLAGS.isMock === 'true') {
    console.log('Updated user email preferences (mock):', userEmailPreferences);
    return;
  }
  const apiUrl = `/user/email-preferences`;

  await axiosClient.put(apiUrl, userEmailPreferences, {
    headers: {
      Authorization: `Bearer ${await getToken()}`
    }
  });
};

export const getUserDetails = async (): Promise<UserDetails> => {
  if (DEVELOPMENT_FLAGS.isMock === 'true') return responseUserDetails;

  const apiUrl = `/user`;

  const result = await axiosClient.get<UserDetails>(apiUrl, {
    headers: {
      Authorization: `Bearer ${await getToken()}`
    }
  });

  return result.data;
};

export const updateUserDetails = async (userDetails: UserDetails): Promise<void> => {
  if (DEVELOPMENT_FLAGS.isMock === 'true') {
    console.log('Updated user details (mock):', userDetails);
    return;
  }

  const apiUrl = `/user`;

  await axiosClient({
    url: apiUrl,
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${await getToken()}`
    },
    data: userDetails
  })
    .then((response) => {
      console.log('Updated user details:', response.data);
    })
    .catch((error) => {
      console.log('An error has occured when posting user details for profile - ', error?.message);
      throw error;
    });
};
