import { Button } from '@mui/material';
import React, { CSSProperties } from 'react';

export interface TPOButtonProps {
  text: string;
  selected: boolean;
  onClick: () => void;
  styles?: CSSProperties;
}

const TPOFilterButton = ({ text, selected, onClick, styles = {} }: TPOButtonProps) => {
  return (
    <Button
      variant={selected ? 'contained' : 'outlined'}
      onClick={onClick}
      sx={{
        borderColor: 'primary.main',
        color: selected ? '#ffffff' : 'text.primary',
        backgroundColor: selected ? 'primary.main' : '#ffffff',
        textTransform: 'none',
        margin: '5px',
        ...styles
      }}
    >
      {text}
    </Button>
  );
};

export default TPOFilterButton;
