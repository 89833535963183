import { AccountOptimization } from './AccountOptimizations.types';
import { CashBoard } from './CashBoard.types';
import { LiquidityProficiencies } from './LiquidityProficiency.types';
import { NearTermLiquidities } from './NearTermLiquidity.types';
import { Scorecard } from './Scorecard.types';
import { TreasuryServices } from './TreasuryServices.types';

export interface ReportResponse {
  data: any;
  metadata: {
    version: string;
  };
}

export interface Report {
  scorecard: Scorecard;
  cashBoard: CashBoard;
  liquidityProficiency: LiquidityProficiencies;
  nearTermLiquidity: NearTermLiquidities;
  accountOptimization: AccountOptimization;
  treasuryServices: TreasuryServices;
}

export const emptyReport: Report = {
  scorecard: {
    scoreHistory: [
      {
        date: '2022-02-17',
        score: 0,
        isInitial: true
      }
    ],
    periodStats: [
      {
        value: 0,
        periodInterest: 0,
        interestInLastYear: 0,
        interestSinceAccountOpened: 0
      }
    ],
    subScores: {
      availableFundsProvidingValue: {
        score: 0,
        impact: 0
      },
      liquidityProficiency: {
        score: 0,
        impact: 0
      },
      warnickRate: {
        score: 0,
        impact: 0
      },
      cashFlow: {
        score: 0,
        impact: 0
      },
      investment: {
        score: 0,
        impact: 0
      }
    }
  },
  cashBoard: {
    financialRelationshipSummary: {
      bankingRelationships: 0,
      bankingRelationshipsAccounts: 0,
      investmentRelationships: 0,
      investmentRelationshipsAccounts: 0,
      changeInBankingAccounts: 0,
      changeInInvestmentAccounts: 0
    },
    liquidityBreakdown: {
      allAccounts: {
        accounts: 0,
        periodInterest: 0,
        yieldPercent: 0,
        averageBalance: 0,
        percentOffunds: 0
      },
      noYieldLiquidDollars: {
        accounts: 0,
        periodInterest: 0,
        yieldPercent: 0,
        averageBalance: 0,
        percentOffunds: 0
      },
      liquidDollars: {
        accounts: 0,
        periodInterest: 0,
        yieldPercent: 0,
        averageBalance: 0,
        percentOffunds: 0
      },
      fixedIncome: {
        accounts: 0,
        periodInterest: 0,
        yieldPercent: 0,
        averageBalance: 0,
        percentOffunds: 0
      }
    },
    yearOverYearChange: [
      {
        summary: '',
        accountGroup: '',
        accountGroupBalances: {
          currentYear: [
            {
              amount: 0,
              date: '2022-02-17'
            }
          ],
          previousYear: [
            {
              amount: 0,
              date: '2022-02-17'
            }
          ],
          beforeLastYear: [
            {
              amount: 0,
              date: '2022-02-17'
            }
          ]
        },
        averageBalances: {
          trailingTwelveMonthsAverageBalance: {
            previous: 0,
            current: 0,
            difference: 0,
            startDate: '',
            endDate: ''
          },
          periodAverageBalance: {
            previous: 0,
            current: 0,
            difference: 0,
            startDate: '',
            endDate: ''
          }
        }
      }
    ]
  },
  liquidityProficiency: {
    liquidityProficiency: [
      {
        accountGroup: '',
        footnote: {
          reportEndDate: '2022-02-17',
          rateName: ''
        },
        strategicLiquidity: {
          totalLiquidity: 0,
          liquidityLevels: {
            level5: {
              amount: 0,
              duration: '',
              benchmarkRate: 0,
              benchmarkValue: 0
            },
            level4: {
              amount: 0,
              duration: '',
              benchmarkRate: 0,
              benchmarkValue: 0
            },
            level3: {
              amount: 0,
              duration: '',
              benchmarkRate: 0,
              benchmarkValue: 0
            },
            level2: {
              amount: 0,
              duration: '',
              benchmarkRate: 0,
              benchmarkValue: 0
            },
            level1: {
              amount: 0,
              duration: '',
              benchmarkRate: 0,
              benchmarkValue: 0
            },
            cushion: {
              amount: 0,
              duration: '',
              benchmarkRate: 0,
              benchmarkValue: 0
            },
            workingCapital: {
              amount: 0,
              duration: '',
              benchmarkRate: 0,
              benchmarkValue: 0
            },
            total: {
              amount: 0,
              duration: '',
              benchmarkRate: 0,
              benchmarkValue: 0
            }
          }
        },
        investments: {
          groupBalance: [
            {
              amount: 0,
              date: '2022-02-17'
            }
          ],
          fixedIncome: [
            {
              amount: 0,
              date: '2022-02-17'
            }
          ],
          highYieldLiquid: [
            {
              amount: 0,
              date: '2022-02-17'
            }
          ],
          lowYieldLiquid: [
            {
              amount: 0,
              date: '2022-02-17'
            }
          ]
        }
      }
    ]
  },
  nearTermLiquidity: {
    nearTermLiquidity: [
      {
        accountGroup: '',
        isForecastAvailable: true,
        forecastModelWeekly: [
          {
            weekEnding: '2022-02-01',
            totalForecast: 0,
            netForecast: 0,
            lowForecast: 0,
            yearPriorActual: 0
          }
        ],
        forecastModelDaily: {
          strategicLiquidity: 0,
          forecast: [
            {
              amount: 0,
              day: '2022-02-17'
            }
          ],
          lowForecast: [
            {
              amount: 0,
              day: '2022-02-17'
            }
          ],
          yearPriorActual: [
            {
              amount: 0,
              day: '2022-02-17'
            }
          ]
        },
        netChange: [
          {
            month: '2021-02-01',
            positiveCashFlow: 0,
            negativeCashFlow: 0,
            netMonthlyImpact: 0
          }
        ],
        variance: {
          daily: [
            {
              amount: 0,
              date: '2022-02-17'
            }
          ],
          weekly: [
            {
              amount: 0,
              date: '2022-02-17'
            }
          ],
          monthly: [
            {
              amount: 0,
              date: '2022-02-17'
            }
          ]
        }
      }
    ]
  },
  accountOptimization: {
    groups: [
      {
        accountGroup: '',
        dailyAccountBalances: [
          {
            account: '',
            daily: [
              {
                date: '2022-02-01',
                amount: 0
              }
            ]
          }
        ],
        annualBalances: {
          dateRange: {
            startDate: '2022-02-01',
            endDate: '2022-02-02'
          },
          balances: [
            {
              bankName: '',
              account: [
                {
                  accountName: '',
                  accountNumber: '',
                  endingBalance: 0,
                  avgBalance: 0,
                  minBalance: 0,
                  interestRate: 0,
                  interestSum: 0
                }
              ]
            }
          ],
          grandTotal: [
            {
              endingBalance: 0,
              avgBalance: 0,
              minBalance: 0,
              interestRate: 0,
              interestSum: 0
            }
          ]
        },
        periodicBalances: {
          dateRange: {
            startDate: '2022-02-01',
            endDate: '2022-02-02'
          },
          balances: [
            {
              bankName: '',
              account: [
                {
                  accountName: '',
                  accountNumber: '',
                  endingBalance: 0,
                  avgBalance: 0,
                  minBalance: 0,
                  interestRate: 0,
                  interestSum: 0
                }
              ]
            }
          ],
          grandTotal: [
            {
              endingBalance: 0,
              avgBalance: 0,
              minBalance: 0,
              interestRate: 0,
              interestSum: 0
            }
          ]
        }
      }
    ],
    balanceByBank: {
      dailyBankBalances: [
        {
          bank: '',
          daily: [
            {
              date: '2022-02-01',
              amount: 0
            }
          ]
        }
      ],
      annualBalances: {
        balances: [
          {
            bankName: '',
            endingBalance: 0,
            avgBalance: 0,
            minBalance: 0,
            interestRate: 0,
            interestSum: 0
          }
        ],
        grandTotal: [
          {
            endingBalance: 0,
            avgBalance: 0,
            minBalance: 0,
            interestRate: 0,
            interestSum: 0
          }
        ]
      },
      periodicBalances: {
        balances: [
          {
            bankName: '',
            endingBalance: 0,
            avgBalance: 0,
            minBalance: 0,
            interestRate: 0,
            interestSum: 0
          }
        ],
        grandTotal: [
          {
            endingBalance: 0,
            avgBalance: 0,
            minBalance: 0,
            interestRate: 0,
            interestSum: 0
          }
        ]
      }
    }
  },
  treasuryServices: {
    isTreasuryDataAvailable: true,
    treasuryServices: [
      {
        accountGroup: '',
        summaryBalance: {
          availableBalancesAmount: 0,
          balanceNeededAmount: 0,
          excessDeficitAmount: 0
        },
        earningsCreditRate: {
          averageEarningCreditRateAmount: 0,
          fdicFeeAmount: 0,
          netEarningsCreditRateAmount: 0
        },
        feesCompensatingOffset: {
          totalLineItemFeesAmount: 0,
          feesCompensatingOffsetAmount: 0,
          excessCreditAmount: 0
        },
        lineItemFees: [
          {
            category: '',
            subcategory: [
              {
                subcategory: '',
                costType: '',
                priceType: '',
                unitPrice: 0,
                volume: 0,
                cost: 0
              }
            ]
          }
        ]
      }
    ]
  }
};
