import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Drawer, Box, Typography, TextField, Autocomplete, IconButton } from '@mui/material';
import TPOButton from '../../../components/TPOButton/TPOButton';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { UserDetails } from '../../../networking/profile/userDetails/UserDetails.types';

interface EditPhoneNumberDrawerProps {
  isOpen: boolean;
  handleCloseDrawer: () => void;
  updateUserDetails: (userDetails: UserDetails) => Promise<void>;
  onDetailsUpdated: () => void;
  onSuccess: () => void;
  onError: () => void;
  currentUserDetails: UserDetails;
}

const countryCodes = {
  US: '+1 - United States',
  Canada: '+1 - Canada',
  Mexico: '+52 - Mexico',
  Argentina: '+54 - Argentina',
  UK: '+44 - United Kingdom',
  Australia: '+61 - Australia'
};

const countryCodeOptions = Object.values(countryCodes);

const EditPhoneNumberDrawer: React.FC<EditPhoneNumberDrawerProps> = ({
  isOpen,
  handleCloseDrawer,
  onDetailsUpdated,
  onSuccess,
  onError,
  updateUserDetails,
  currentUserDetails
}) => {
  const { t } = useTranslation();
  const validationSchema = yup.object({
    countryCode: yup
      .string()
      .required(t('yourProfile.personalDetails.editPhoneNumber.errors.required')),
    phoneNumber: yup
      .string()
      .matches(/^[0-9]+$/, t('yourProfile.personalDetails.editPhoneNumber.errors.invalidNumber'))
      .min(7, t('yourProfile.personalDetails.editPhoneNumber.errors.minDigits'))
      .max(15, t('yourProfile.personalDetails.editPhoneNumber.errors.maxDigits'))
      .required(t('yourProfile.personalDetails.editPhoneNumber.errors.required')),
    phoneNumberConfirmed: yup
      .string()
      .nullable()
      .oneOf(
        [yup.ref('phoneNumber'), null],
        t('yourProfile.personalDetails.editPhoneNumber.errors.phoneNumbersMustMatch')
      )
  });

  const formik = useFormik({
    initialValues: {
      countryCode: '+1 - United States',
      phoneNumber: '',
      phoneNumberConfirmed: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const extractedCountryCode = values.countryCode.split(' - ')[0];
      const phoneNumberWithCountryCode = extractedCountryCode + values.phoneNumber;
      try {
        await updateUserDetails({
          firstName: currentUserDetails.firstName,
          lastName: currentUserDetails.lastName,
          phoneNumber: phoneNumberWithCountryCode
        });
        onSuccess();
        onDetailsUpdated();
      } catch (error) {
        onError();
      } finally {
        handleCloseDrawer();
      }
    }
  });

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleCloseDrawer}
      PaperProps={{
        style: { backgroundColor: 'white' }
      }}
    >
      <IconButton
        onClick={handleCloseDrawer}
        sx={{ position: 'absolute', right: 0, top: 0, padding: '10px', cursor: 'pointer' }}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{ width: 'auto', padding: 4, margin: '50px' }}>
        <Typography
          variant="h4"
          sx={{ textAlign: 'left', marginLeft: '-28px', paddingBottom: '70px' }}
        >
          {t('yourProfile.personalDetails.editPhoneNumber.updatePhoneNumber')}
        </Typography>

        <Typography variant="h6" sx={{ textAlign: 'left', marginBottom: 1 }}>
          {t('yourProfile.personalDetails.editPhoneNumber.selectCountryCode')}
        </Typography>

        <Autocomplete
          freeSolo
          options={countryCodeOptions}
          value={formik.values.countryCode}
          onChange={(event, newValue) => {
            formik.setFieldValue('countryCode', newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              name="countryCode"
              error={formik.touched.countryCode && Boolean(formik.errors.countryCode)}
              helperText={formik.touched.countryCode && formik.errors.countryCode}
              fullWidth
              sx={{ marginBottom: 5 }}
            />
          )}
        />

        <Typography variant="h6" sx={{ textAlign: 'left', marginBottom: 1 }}>
          {t('yourProfile.personalDetails.editPhoneNumber.newPhoneNumber')}
        </Typography>

        <TextField
          variant="outlined"
          name="phoneNumber"
          value={formik.values.phoneNumber}
          onChange={formik.handleChange}
          error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
          helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
          fullWidth
          sx={{ marginBottom: 5 }}
          data-testid="phoneNumberInput"
        />

        <Typography variant="h6" sx={{ textAlign: 'left', marginBottom: 1 }}>
          {t('yourProfile.personalDetails.editPhoneNumber.confirmNewPhoneNumber')}
        </Typography>

        <TextField
          variant="outlined"
          name="phoneNumberConfirmed"
          value={formik.values.phoneNumberConfirmed}
          onChange={formik.handleChange}
          error={formik.touched.phoneNumberConfirmed && Boolean(formik.errors.phoneNumberConfirmed)}
          helperText={formik.touched.phoneNumberConfirmed && formik.errors.phoneNumberConfirmed}
          fullWidth
          sx={{ marginBottom: 5 }}
          data-testid="confirmPhoneNumberInput"
        />
        <TPOButton
          onClick={formik.handleSubmit}
          styles={{ width: '250px', height: '45px' }}
          data-testid="SAVE_PHONE_BUTTON_TEST_ID"
          disabled={formik.values.phoneNumber === '' || formik.values.phoneNumberConfirmed === ''}
        >
          {t('yourProfile.personalDetails.editPhoneNumber.save')}
        </TPOButton>
      </Box>
    </Drawer>
  );
};

export default EditPhoneNumberDrawer;
