import React from 'react';
import { useTranslation } from 'react-i18next';
import ContentBody from '../../components/ContentBody/ContentBody';
import PageSetupSuccess from '../../components/PageSetupSuccess/PageSetupSuccess';

const PasswordSetupSuccess = () => {
  const { t } = useTranslation();

  return (
    <ContentBody>
      <PageSetupSuccess
        successTitle={t('login.passwordSetupSuccessTitle')}
        successMessage={t('login.passwordSetupSuccessMessage')}
      />
    </ContentBody>
  );
};

export default PasswordSetupSuccess;
