import { createTheme } from '@mui/material';
import palette from './palette';

const theme = createTheme({
  typography: {
    fontFamily: ['OpenSans-Regular', 'Open Sans', 'sans-serif'].join(',')
  },
  palette
});

export default theme;
