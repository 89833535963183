import { Box, Typography } from '@mui/material';
import React from 'react';
import palette from '../../theme/palette';

interface TPOLabelProps {
  text: string;
  color?: string;
}

const TPOLabel = ({text, color = palette.primary.main} : TPOLabelProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '6px'
      }}
    >
      <Box
        sx={{
          backgroundColor: color,
          width: '20px',
          height: '20px',
          borderRadius: '3px'
        }}
      />
      <Typography
        sx={{
          fontSize: '14px',
          marginLeft: '10px'
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default TPOLabel;
