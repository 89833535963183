import { Button, Typography } from '@mui/material';
import React, { HTMLAttributes } from 'react';

interface NavbarPrimaryButtonProps {
  text: string;
  isSelected: boolean;
  onClick: () => void;
}

const NavbarPrimaryButton = (props: NavbarPrimaryButtonProps) => {
  return (
    <Button
      onClick={props.onClick}
      sx={{
        textTransform: 'none',
        color: 'text.primary',
        borderRadius: 0,
        fontSize: '18px',
        marginLeft: 1
      }}
    >
      <Typography
        sx={{
          ...(props.isSelected && {
            borderBottom: '3px solid #460074'
          })
        }}
      >
        {props.text}
      </Typography>
    </Button>
  );
};

export default NavbarPrimaryButton;
