import { Container } from '@mui/material';
import React from 'react';

type PageProps = {
  children: React.ReactNode;
};

const Page: Function = (props: PageProps) => {
  // only works when parent component is not a flex component!
  return (
    <Container disableGutters maxWidth={false} sx={{ marginTop: '3mm', marginBottom: '3mm' }}>
      <div className="pagebreak">{props.children}</div>
    </Container>
  );
};

export default Page;
