import React, { useEffect } from 'react';

import { Stack, Alert, Typography, Box } from '@mui/material';

import { HandleCloseDrawer } from './Navigation';
import { FormInvestment } from '../AddInvestmentDrawer.types';
import { DrawerFilledSubmitButton, DrawerOutlinedButton, DrawerCancelButton } from './UIElements';
import InvestmentForm, { AccountNames, InvestmentTypes } from './InvestmentForm';
import { useTranslation } from 'react-i18next';

export enum SubmitButton {
  REVIEW,
  ADD_ANOTHER_INVESTMENT
}

export const NEW_INVESTMENT_FORM_DATA_TEST_ID = 'NEW_INVESTMENT_FORM_DATA_TEST_ID';

const EmailAlert = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={2} sx={{ paddingTop: '54px' }}>
      <Alert
        severity="info"
        sx={{
          fontSize: '16px',
          color: 'primary.main',
          backgroundColor: '#E5DBEB',
          '& .MuiAlert-icon': {
            color: 'primary.main'
          }
        }}
      >
        {t('investments.addInvestment.newPage.warning')}
        <Typography
          display="inline"
          sx={{
            fontWeight: '550'
          }}
        >
          {t('investments.addInvestment.newPage.rmEmail')}
        </Typography>
      </Alert>
    </Stack>
  );
};

const NewAccountsAlert = () => {
const { t } = useTranslation();

  return (
      <Alert
        severity="info"
        sx={{
          fontSize: '16px',
          color: 'primary.main',
          backgroundColor: '#E5DBEB',
          '& .MuiAlert-icon': {
            color: 'primary.main'
          }
        }}
      >
        {t('investments.addInvestment.newPage.newAccountWarning')}
      </Alert>
  );
};

export const INITIAL_VALUES: FormInvestment = {
  accountNumber: '0',
  investmentTypeId: 1,
  purchaseAmount: '',
  interestRate: '',
  faceValue: '',
  purchaseDate: null,
  maturityDate: null,
  callDate: null,
  couponRate: '',
  investmentId: '',
  fund: ''
};

interface NewPageProps {
  handleCloseDrawer: HandleCloseDrawer;
  setIsNewInvestmentFormChanged: () => void;
  accountNames: AccountNames;
  investmentTypes: InvestmentTypes;
  investmentTypesGroup2: number[];
  investmentTypesGroup3: number[];
  formik: any;
  handleReview: () => void;
  handleOpenWarningTPODialog: (investment: FormInvestment) => void;
}

const NewPage = ({
  handleCloseDrawer,
  setIsNewInvestmentFormChanged,
  accountNames,
  investmentTypes,
  investmentTypesGroup2,
  investmentTypesGroup3,
  formik,
  handleReview,
  handleOpenWarningTPODialog
}: NewPageProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (formik.values !== INITIAL_VALUES) setIsNewInvestmentFormChanged();
  }, [formik.values]);

  return (
    <Box
      sx={{ padding: '25px', backgroundColor: 'common.white' }}
      data-testid={NEW_INVESTMENT_FORM_DATA_TEST_ID}
    >
      <Stack spacing={1}>
        <NewAccountsAlert />
        <InvestmentForm
          formik={formik}
          accountNames={accountNames}
          investmentTypes={investmentTypes}
          investmentTypesGroup2={investmentTypesGroup2}
          investmentTypesGroup3={investmentTypesGroup3}
        />
        <EmailAlert />
        <DrawerFilledSubmitButton onClick={() => handleOpenWarningTPODialog(formik.values)}>
          {t('investments.addInvestment.newPage.addInvestmentButton') as string}
        </DrawerFilledSubmitButton>
        <DrawerOutlinedButton
          onClick={handleReview}
          text={t('investments.addInvestment.newPage.reviewButton')}
        />
        <DrawerCancelButton
          onClick={handleCloseDrawer}
          text={t('investments.addInvestment.newPage.cancelButton')}
        />
      </Stack>
    </Box>
  );
};

export default NewPage;
