import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hasContent } from '../../common/helpers';
import { ROUTES } from '../../routing/ROUTES';
import { tpoErrorColor } from '../../styles/constants';
import FormValidation, { IFormError } from '../../common/validation/formValidation';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { forgotPasswordSubmit, verifyPassword } from '../../auth/authenticator';
import { Authentication } from '../../auth/Authentication.types';

import ContentBody from '../../components/ContentBody/ContentBody';
import { stripPathParams } from '../../utils/routing';

const SetupPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { username, passcode } = useParams<{
    username: string;
    passcode: string;
  }>();

  const formValidation = FormValidation(t);

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formErrors, setFormErrors] = useState<IFormError<string>>({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChangeWith =
    (setter: (value: string) => void) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormErrors({});
      setter(event.target.value);
    };

  const passwordsMatch = (): boolean => {
    return newPassword === confirmPassword;
  };

  const onSubmitt = async () => {
    formValidation.validate('passwordSetup', newPassword);
    if (formValidation.isErrorsFree() && passwordsMatch()) {
      if (location.pathname.includes('/reset-password')) {
        const result: Authentication = await verifyPassword(
          username as string,
          passcode as string,
          newPassword
        );
        if (result.isSuccess) {
          navigate(`${stripPathParams(ROUTES.SetupMFA)}/${username}/${newPassword}`);
        }
      } else if (location.pathname.includes('/forgot-password')) {
        await forgotPasswordSubmit(username as string, passcode as string, newPassword);
        navigate('/');
      }
    } else {
      setFormErrors({ ...formErrors, ...formValidation.errors() });
    }
  };

  return (
    <ContentBody>
      <Stack
        spacing={1}
        sx={{
          width: '750px',
          marginTop: '10px',
          marginLeft: '45px',
          marginBottom: '120px'
        }}
      >
        <Typography
          sx={{
            fontSize: '45px',
            color: 'primary.main'
          }}
        >
          {t('login.setupPasswordTitle')}
        </Typography>

        <Typography
          sx={{
            fontSize: '18px',
            color: 'text.primary'
          }}
        >
          {t('login.setupPasswordMessage')}
        </Typography>

        <Typography
          sx={{
            fontSize: '18px',
            color: 'text.primary'
          }}
        >
          {t('login.setupPasswordConstraints')}
        </Typography>

        <Typography
          sx={{
            fontSize: '18px',
            color: 'text.primary',
            paddingTop: '35px'
          }}
        >
          {t('login.setupPasswordCreate')}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <TextField
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            value={newPassword}
            onChange={handleChangeWith(setNewPassword)}
            sx={{
              width: '540px',
              marginRight: '20px',
              '& .MuiOutlinedInput-root.Mui-focused': {
                '& > fieldset': {
                  borderColor: 'primary.main'
                }
              }
            }}
          />

          <Typography
            onClick={() => setShowPassword(!showPassword)}
            sx={{
              fontSize: '18px',
              color: 'primary.main',
              cursor: 'pointer'
            }}
          >
            {showPassword ? t('login.hidePassword') : t('login.showPassword')}
          </Typography>
        </Box>

        {hasContent(formErrors.passwordSetup) && (
          <Typography
            sx={{
              fontSize: '18px',
              color: tpoErrorColor,
              paddingTop: '10px'
            }}
          >
            {formErrors.passwordSetup}
          </Typography>
        )}

        <Typography
          sx={{
            fontSize: '18px',
            color: 'text.primary',
            paddingTop: '35px'
          }}
        >
          {t('login.setupPasswordConfirm')}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <TextField
            variant="outlined"
            type={showConfirmPassword ? 'text' : 'password'}
            value={confirmPassword}
            onChange={handleChangeWith(setConfirmPassword)}
            sx={{
              width: '540px',
              marginRight: '20px',
              '& .MuiOutlinedInput-root.Mui-focused': {
                '& > fieldset': {
                  borderColor: 'primary.main'
                }
              }
            }}
          />

          <Typography
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            sx={{
              fontSize: '18px',
              color: 'primary.main',
              cursor: 'pointer'
            }}
          >
            {showConfirmPassword ? t('login.hidePassword') : t('login.showPassword')}
          </Typography>
        </Box>

        {!passwordsMatch() && (
          <Typography
            sx={{
              fontSize: '18px',
              color: tpoErrorColor,
              paddingTop: '10px'
            }}
          >
            {t('login.matchError')}
          </Typography>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '35px',
            alignItems: 'center'
          }}
        >
          <Button
            variant="contained"
            onClick={() => onSubmitt()}
            sx={{
              textTransform: 'none',
              borderColor: 'primary.main',
              color: '#ffffff',
              backgroundColor: 'primary.main',
              fontSize: '16px',
              paddingLeft: '30px',
              paddingRight: '30px'
            }}
          >
            {t('login.setupPasswordLink')}
          </Button>
        </Box>
      </Stack>
    </ContentBody>
  );
};

export default SetupPassword;
