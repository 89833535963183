import React, { useContext, useMemo } from 'react';
import { numberFormatting } from '../../../../common/helpers';
import { Line } from 'react-chartjs-2';
import { ChartOptions, Tick } from 'chart.js';
import { Box, Stack } from '@mui/material';
import { ReportContext } from '../../../../common/reportContext';
import TPOLabel from '../../../../components/TPOLabel/TPOLabel';
import { ChartDataObject } from './InvestmentChart.types';

const chartOptions: ChartOptions<'line'> = {
  scales: {
    y: {
      stacked: true,
      beginAtZero: true,
      suggestedMax: 30,
      position: 'right',
      ticks: {
        callback: (tickValue: string | number, index: number, ticks: Tick[]) =>
          numberFormatting(+tickValue)
      }
    },
    x: {
      grid: {
        display: false
      },
      ticks: {
        autoSkip: false,
        callback: function (val, index) {
          // Only display ticks that are the first of the month
          const label = this.getLabelForValue(Number(val));
          return new Date(label).getDate() === 1 ? this.getLabelForValue(Number(val)) : '';
        }
      }
    }
  },
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: (tooltipItem) => {
          return tooltipItem.dataset.label
            ? tooltipItem.dataset.label + `: $${tooltipItem.formattedValue}`
            : `$${tooltipItem.formattedValue}`;
        }
      }
    }
  },
  elements: {
    point: {
      radius: 0
    }
  }
};
interface InvestmentChartProps {
  selectedAccountGroupIndex: number;
}

const InvestmentChart = (props: InvestmentChartProps) => {
  const { report } = useContext(ReportContext);

  const levels =
    report.liquidityProficiency.liquidityProficiency[props.selectedAccountGroupIndex]
      .strategicLiquidity.liquidityLevels;

  const investments =
    report.liquidityProficiency.liquidityProficiency[props.selectedAccountGroupIndex].investments;

  const generateChartDataSets = () => {
    const chartDataList: ChartDataObject[] = [];

    investments.fixedIncome.forEach((entry) => {
      const { date, amount } = entry;
      const indexOfDate = chartDataList.findIndex(
        (chartDataObject) => chartDataObject.date === date
      );
      indexOfDate === -1
        ? chartDataList.push({ date: date, amounts: { ['fixedIncome']: amount } })
        : (chartDataList[indexOfDate].amounts = {
            ...chartDataList[indexOfDate].amounts,
            ['fixedIncome']: amount
          });
    });

    investments.highYieldLiquid.forEach((entry) => {
      const { date, amount } = entry;
      const indexOfDate = chartDataList.findIndex(
        (chartDataObject) => chartDataObject.date === date
      );
      indexOfDate === -1
        ? chartDataList.push({ date: date, amounts: { ['highYieldLiquid']: amount } })
        : (chartDataList[indexOfDate].amounts = {
            ...chartDataList[indexOfDate].amounts,
            ['highYieldLiquid']: amount
          });
    });

    investments.lowYieldLiquid.forEach((entry) => {
      const { date, amount } = entry;
      const indexOfDate = chartDataList.findIndex(
        (chartDataObject) => chartDataObject.date === date
      );
      indexOfDate === -1
        ? chartDataList.push({ date: date, amounts: { ['lowYieldLiquid']: amount } })
        : (chartDataList[indexOfDate].amounts = {
            ...chartDataList[indexOfDate].amounts,
            ['lowYieldLiquid']: amount
          });
    });

    chartDataList.sort((a, b) => a.date.localeCompare(b.date));

    chartDataList.forEach((chartDataObject) => {
      const [year, month, day] = chartDataObject.date.split('-').map((x) => +x);
      const dateObject = new Date(year, month - 1, day);
      chartDataObject.date = dateObject.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      });
      chartDataObject.amounts = {
        ...chartDataObject.amounts,
        level5: levels.level5.amount,
        level4: levels.level4.amount,
        level3: levels.level3.amount,
        level2: levels.level2.amount,
        level1: levels.level1.amount,
        cushion: levels.cushion.amount
      };
    });
    return chartDataList;
  };

  const chartDataList: ChartDataObject[] = generateChartDataSets();

  const level5 = {
    data: chartDataList,
    label: 'Liquidity level 5',
    borderColor: '#ffffff',
    stack: 'levels',
    display: levels.level5.amount,
    parsing: {
      xAxisKey: 'date',
      yAxisKey: 'amounts.level5'
    }
  };

  const level4 = {
    data: chartDataList,
    label: 'Liquidity level 4',
    borderColor: '#ffffff',
    stack: 'levels',
    display: levels.level4.amount,
    parsing: {
      xAxisKey: 'date',
      yAxisKey: 'amounts.level4'
    }
  };

  const level3 = {
    data: chartDataList,
    label: 'Liquidity level 3',
    borderColor: '#ffffff',
    stack: 'levels',
    display: levels.level3.amount,
    parsing: {
      xAxisKey: 'date',
      yAxisKey: 'amounts.level3'
    }
  };

  const level2 = {
    data: chartDataList,
    label: 'Liquidity level 2',
    borderColor: '#ffffff',
    stack: 'levels',
    display: levels.level2.amount,
    parsing: {
      xAxisKey: 'date',
      yAxisKey: 'amounts.level2'
    }
  };

  const level1 = {
    data: chartDataList,
    label: 'Liquidity level 1',
    borderColor: '#ffffff',
    stack: 'levels',
    display: levels.level1.amount,
    parsing: {
      xAxisKey: 'date',
      yAxisKey: 'amounts.level1'
    }
  };

  const cushion = {
    data: chartDataList,
    label: 'Cushion',
    borderColor: '#ffffff',
    stack: 'levels',
    display: levels.cushion.amount,
    parsing: {
      xAxisKey: 'date',
      yAxisKey: 'amounts.cushion'
    }
  };

  const fixedIncome = {
    label: 'Fixed Income',
    data: chartDataList,
    fill: true,
    backgroundColor: '#703b92',
    borderColor: '#703b92',
    display: true,
    parsing: {
      xAxisKey: 'date',
      yAxisKey: 'amounts.fixedIncome'
    }
  };

  const highYield = {
    label: 'High-Yield Liquid',
    data: chartDataList,
    fill: true,
    backgroundColor: '#4570de',
    borderColor: '#4570de',
    display: true,
    parsing: {
      xAxisKey: 'date',
      yAxisKey: 'amounts.highYieldLiquid'
    }
  };

  const lowYield = {
    label: 'Low/No-Yield Liquid',
    data: chartDataList,
    fill: true,
    backgroundColor: '#f69436',
    borderColor: '#f69436',
    display: true,
    parsing: {
      xAxisKey: 'date',
      yAxisKey: 'amounts.lowYieldLiquid'
    }
  };

  const chartData = {
    datasets: [
      level5,
      level4,
      level3,
      level2,
      level1,
      cushion,
      fixedIncome,
      highYield,
      lowYield
    ].filter((set) => set.display)
  };

  return (
    <Box>
      <Line data={chartData} options={chartOptions} redraw />
      <Box
        sx={{
          position: 'absolute',
          marginTop: '-428px',
          marginLeft: '750px',
          width: '190px'
        }}
      ></Box>
      <Stack direction={'row'} spacing={4}>
        <TPOLabel text="Fixed Income" color="#703b92" />
        <TPOLabel text="High-Yield Liquid" color="#4570de" />
        <TPOLabel text="Low/No-Yield Liquid" color="#f69436" />
      </Stack>
    </Box>
  );
};

export default React.memo(InvestmentChart);
