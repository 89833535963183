import React from 'react';
import { Grid, Typography } from '@mui/material';

interface FinancialSummaryItemProps {
  quantity: number;
  type: string;
  detail?: string;
  displayDirection?: boolean;
}

const FinancialSummaryItem = (props: FinancialSummaryItemProps) => {
  return (
    <Grid item xs={6}>
      <Typography
        sx={{
          fontSize: '32px',
          color: 'text.primary'
        }}
      >
        {props.displayDirection && (props.quantity > 0 ? '+' : '') + props.quantity}
        {!props.displayDirection && props.quantity}
      </Typography>

      <Typography
        sx={{
          fontSize: '18px',
          color: 'text.primary',
          fontWeight: 500
        }}
      >
        {props.type}
      </Typography>

      <Typography
        sx={{
          fontSize: '16px',
          color: 'text.primary'
        }}
      >
        {props.detail}
      </Typography>
    </Grid>
  );
};

export default FinancialSummaryItem;
