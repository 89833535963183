import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
import ApiConfig from '../apiConfig';

const cache = setupCache({
  maxAge: ApiConfig.apiMaxCacheAge * 1000,
  exclude: {
    query: false,
    paths: [/\/user.*/]
  }
});

const axiosClient = axios.create({
  adapter: cache.adapter,
  baseURL: ApiConfig.apiBaseUrl
});

export default axiosClient;
