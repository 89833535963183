import { AccountOptimizationV1 } from '../v1/AccountOptimizationsV1.types';
import { CashBoardV2, cashBoardV2ToBase } from '../v2/CashBoardV2';
import { LiquidityProficienciesV1 } from '../v1/LiquidityProficiencyV1.types';
import { NearTermLiquiditiesV1 } from '../v1/NearTermLiquidityV1.types';
import { ScorecardV1 } from '../v1/ScorecardV1.types';
import { TreasuryServicesV1 } from '../v1/TreasuryServicesV1.types';
import { Report } from '../../Report';

/*
        Version 2 of the report does not exist yet!!! 
        ReportV2 is created as a placeholder and show-how for future versions. 
        This code is to be removed once new version comes.
    */

export interface ReportV2 {
  scorecard: ScorecardV1;
  cashBoard: CashBoardV2;
  liquidityProficiency: LiquidityProficienciesV1;
  nearTermLiquidity: NearTermLiquiditiesV1;
  accountOptimization: AccountOptimizationV1;
  treasuryServices: TreasuryServicesV1;
}

export function ReportV2ToBase(reportV2: ReportV2): Report {
  return {
    scorecard: reportV2.scorecard,
    cashBoard: cashBoardV2ToBase(reportV2.cashBoard),
    liquidityProficiency: reportV2.liquidityProficiency,
    nearTermLiquidity: reportV2.nearTermLiquidity,
    accountOptimization: reportV2.accountOptimization,
    treasuryServices: reportV2.treasuryServices
  };
}
