import React from 'react';
import { Box, Typography } from '@mui/material';
import TPOPaper from '../TPOPaper/TPOPaper';

interface PaperSectionProps {
  title: string;
  subtitle?: string;
  bannerColor?: string;
  children?: React.ReactNode;
}

const PaperSection = (props: PaperSectionProps) => {
  return (
    <Box
      sx={{
        display: 'block'
      }}
    >
      <Typography
        sx={{
          paddingTop: 3,
          paddingLeft: 3,
          paddingBottom: !!props.subtitle ? 0 : 3,
          backgroundColor: !!props.bannerColor ? props.bannerColor : 'tertiary.main',
          color: '#ffffff',
          fontSize: '20px',
          fontWeight: 600
        }}
      >
        {props.title}
      </Typography>
      {props.subtitle && (
        <Typography
          sx={{
            paddingLeft: 3,
            paddingBottom: 2,
            backgroundColor: '#015545',
            color: '#fdfdfd',
            fontSize: '18px',
            fontWeight: 500
          }}
        >
          {props.subtitle}
        </Typography>
      )}
      <TPOPaper color="rgba(70, 0, 116, 0.3)">
        <Box
          sx={{
            padding: 3
          }}
        >
          {props.children}
        </Box>
      </TPOPaper>
    </Box>
  );
};

export default PaperSection;
