import React, { useContext, useState } from 'react';
import DropdownSummary from '../DropdownSummary/DropdownSummary';
import BaseSummary from '../BaseSummary/BaseSummary';
import Stack from '@mui/material/Stack';
import StrategicLiquidity from './StrategicLiquidity/StrategicLiquidity';
import Investments from './Investments/Investments';
import { useTranslation } from 'react-i18next';
import { ReportContext } from '../../../common/reportContext';
import { CommentsContext } from '../../../common/commentsContext';

const LiquidProficiency = () => {
  const { t } = useTranslation();

  const { report } = useContext(ReportContext);
  const liquidityProficiencies = report.liquidityProficiency.liquidityProficiency;

  const options = liquidityProficiencies.map(
    (liquidityProficiency) => liquidityProficiency.accountGroup
  );

  const [index, setIndex] = useState(0);

  const { comments, setComments } = useContext(CommentsContext);

  return (
    <Stack spacing={3}>
      <DropdownSummary
        title={t('cashVestAnalyses.liquidityProficiency.selectAccountGroupLabel')}
        options={options}
        index={index}
        setIndex={setIndex}
      >
        <BaseSummary
          title={t('cashVestAnalyses.liquidityProficiency.title')}
          content={t('cashVestAnalyses.liquidityProficiency.summary')}
          setContent={(content) => {
            const newComments = { ...comments };
            newComments.liquidityProficiency.summary = content;
            setComments(newComments);
          }}
        />
      </DropdownSummary>
      <StrategicLiquidity index={index} />
      <Investments selectedAccountGroupIndex={index} />
    </Stack>
  );
};

export default LiquidProficiency;
