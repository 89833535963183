import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext, EMPTY_AUTH_STATE } from '../common/authContext';
import { ACCOUNT_DETAILS_ACCOUNTS_PAGE, ROUTES } from './ROUTES';
import { RouteProps } from './types';

const RedirectHomeRoute = ({ children }: RouteProps) => {
  const { authState } = useContext(AuthContext);

  if (authState.bearerToken !== EMPTY_AUTH_STATE.bearerToken) {
    if (authState.elevatedUser) return <Navigate to={ROUTES.RelationshipManager} />;
    else return <Navigate to={ACCOUNT_DETAILS_ACCOUNTS_PAGE} />;
  } else {
    return children;
  }
};

export default RedirectHomeRoute;
