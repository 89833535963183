import axios from 'axios';
import { getToken } from '../../../auth/authenticator';
import DEVELOPMENT_FLAGS from '../../../developmentFlags';
import axiosClient from '../../axios-api';
import { ReportResponse } from './Report';

export async function getReport(
  clientId: number,
  fileKey: string
): Promise<ReportResponse | { data: {}; metadata: {} }> {
  if (DEVELOPMENT_FLAGS.isMock == 'true') {
    return require('../../../mock/report/response.json');
  }

  let finalResponse = {
    data: {},
    metadata: {}
  };

  const apiUrl = `/analysis/file?c_id=${clientId}&f_id=${fileKey}`;

  await axiosClient({
    url: apiUrl,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${await getToken()}`
    }
  })
    .then(async (fileUrl) => {
      await axiosClient({
        method: 'GET',
        url: fileUrl.data,
        decompress: true
      })
        .then((response) => {
          finalResponse = response.data;
        })
        .catch((error) =>
          console.log('An error has occured when fetching report - ', error?.message)
        );
    })
    .catch((error) => console.log('An error has occured when fetching reports - ', error?.message));

  return finalResponse;
}
