import { useLocation } from 'react-router-dom'
import React from 'react'

import analytics from "../utils/analytics/analytics"

export default function useGoogleAnalytics() {
    const location = useLocation()
  
    React.useEffect(() => {
      analytics.init()
    }, [])
  
    React.useEffect(() => {
      analytics.sendEvent({
        page: location.pathname ,
        title: window.document.title
      })
    }, [location])
  }