import React from 'react';
import { Bar } from 'react-chartjs-2';
import { getColors } from '../../../../common/colorUtils';
import { AccountsInvestment } from '../../../../networking/accounts/investments/AccountsInvestment.types';
import type { ChartOptions } from 'chart.js';
import { useTranslation } from 'react-i18next';
import { BarChartDataset, ChartDatasets, Week } from './InvestmentTimelineChart.types';

export const formatDate = (date: Date) =>
  date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC'
  });

export const formatWeeks = (weeks: Week[]): string[] =>
  weeks.map((week) => formatDate(week.startDate) + ' - ' + formatDate(week.endDate));

export const getWeeks = (startingDate: Date): Week[] => {
  const startDate = new Date(startingDate);
  const oneYearAfter = new Date(startingDate);
  oneYearAfter.setFullYear(oneYearAfter.getFullYear() + 1);

  const labels: Week[] = [];
  while (startDate <= oneYearAfter) {
    const endOfWeek = new Date(startDate);
    endOfWeek.setDate(startDate.getDate() + 6);
    labels.push({
      startDate: new Date(startDate),
      endDate: new Date(endOfWeek)
    });
    startDate.setDate(startDate.getDate() + 7);
  }

  return labels;
};

export const generateXAxisDataForDataset = (
  labels: Week[],
  investments: AccountsInvestment[]
): number[] => {
  const getCurrentWeek = (date: Date): Week => {
    const currentLabel = labels.find((week) => week.startDate >= date || date <= week.endDate);
    return currentLabel as Week;
  };

  const faceValueAccumulators = new Map<Week, number>();
  investments.forEach((i) => {
    const currentWeek = getCurrentWeek(new Date(i.maturityDate));
    const maturityDateFaceValue = faceValueAccumulators.get(currentWeek);
    if (maturityDateFaceValue) {
      faceValueAccumulators.set(currentWeek, maturityDateFaceValue + i.faceValue);
    } else {
      faceValueAccumulators.set(currentWeek, i.faceValue);
    }
  });

  const completeInvestments: number[] = [];
  for (let i = 0; i < labels.length; i++) {
    const maturityDateFaceValue = faceValueAccumulators.get(labels[i]);
    if (maturityDateFaceValue) {
      completeInvestments.push(maturityDateFaceValue);
    } else {
      completeInvestments.push(0);
    }
  }

  return completeInvestments;
};

export const getDatasets = (
  investments: AccountsInvestment[],
  todaysDate: string
): ChartDatasets => {
  const weeks = getWeeks(new Date(todaysDate));

  const upcomingInvestments = investments.filter(
    (i) => new Date(i.maturityDate) > new Date(todaysDate)
  );

  const investmentsInsitutionNames = upcomingInvestments.map((i) => i.institutionName);
  const institutionNames = Array.from(new Set(investmentsInsitutionNames));
  const colors = getColors(institutionNames.length);

  const investmentsByInstitution = institutionNames.map((institution) => ({
    institution,
    investments: upcomingInvestments.filter((i) => i.institutionName === institution)
  }));

  const datasets: BarChartDataset[] = investmentsByInstitution.map((i, index) => ({
    label: i.institution,
    data: generateXAxisDataForDataset(weeks, i.investments),
    backgroundColor: colors[index]
  }));

  return {
    labels: formatWeeks(weeks),
    datasets
  };
};

interface InvestmentTimelineChartProps {
  investments: AccountsInvestment[];
}

const InvestmentTimelineChart = ({ investments }: InvestmentTimelineChartProps) => {
  const { t } = useTranslation();

  const options: ChartOptions<'bar'> = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false
        },
        ticks: {
          autoSkip: false,
          callback: function (val, index) {
            const label = this.getLabelForValue(Number(val));
            return index % 5 === 2 ? label.split(' - ')[1] : '';
          }
        }
      },
      y: {
        stacked: true,
        position: 'right'
      }
    }
  };
  const data = getDatasets(investments, formatDate(new Date()));

  return <Bar options={options} data={data} />;
};

export default InvestmentTimelineChart;
