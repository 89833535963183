import React, { useContext } from 'react';
import TPOPaper from '../../../../components/TPOPaper/TPOPaper';
import { Box } from '@mui/material';
import BalanceSummaryTable from './BalanceSummaryTable';
import { useTranslation } from 'react-i18next';
import { ReportContext } from '../../../../common/reportContext';
import { AverageBalanceDetail } from '../../../../networking/analyses/report/CashBoard.types';

interface BalanceSummaryProps {
  selectedAccountGroupIndex: number;
}

const getDateRangeString = (averageBalanceDetail: AverageBalanceDetail) => {
  const start = new Date(averageBalanceDetail.startDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC'
  });
  const end = new Date(averageBalanceDetail.endDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timeZone: 'UTC'
  });
  return `${start} - ${end}`;
};

const BalanceSummary = (props: BalanceSummaryProps) => {
  const { t } = useTranslation();
  const { report } = useContext(ReportContext);

  const averageBalances =
    report.cashBoard.yearOverYearChange[props.selectedAccountGroupIndex].averageBalances;

  return (
    <TPOPaper color="rgba(70, 0, 116, 0.3)">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: 3
        }}
      >
        <BalanceSummaryTable
          title={t('cashVestAnalyses.cashBoard.ttmBalanceTitle', {
            reportDate: getDateRangeString(averageBalances.trailingTwelveMonthsAverageBalance)
          })}
          previousYear={averageBalances.trailingTwelveMonthsAverageBalance.previous}
          currentYear={averageBalances.trailingTwelveMonthsAverageBalance.current}
          difference={averageBalances.trailingTwelveMonthsAverageBalance.difference}
        />

        <BalanceSummaryTable
          title={t('cashVestAnalyses.cashBoard.averageBalanceTitle', {
            reportDate: getDateRangeString(averageBalances.periodAverageBalance)
          })}
          previousYear={averageBalances.periodAverageBalance.previous}
          currentYear={averageBalances.periodAverageBalance.current}
          difference={averageBalances.periodAverageBalance.difference}
        />
      </Box>
    </TPOPaper>
  );
};

export default BalanceSummary;
