import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import TPOPaper from '../TPOPaper/TPOPaper';

interface SummaryWidgetProps {
  figure: string;
  explanation: string;
  color: string;
}

const SummaryWidget = (props: SummaryWidgetProps) => {
  return (
    <TPOPaper color={props.color}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: 3
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '32px',
            flexGrow: 1,
            color: 'text.primary'
          }}
        >
          {props.figure}
        </Typography>

        <Box
          sx={{
            minWidth: '20px'
          }}
        />

        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '16px',
            width: '240px',
            color: 'text.primary'
          }}
        >
          {props.explanation}
        </Typography>
      </Box>
    </TPOPaper>
  );
};

export default SummaryWidget;
