import React, { useContext } from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';
import { ChartOptions, Tick } from 'chart.js';
import { numberFormatting } from '../../../../common/helpers';
import TPOPaper from '../../../../components/TPOPaper/TPOPaper';
import TPOLabel from '../../../../components/TPOLabel/TPOLabel';
import ToolTip from '../../../../components/ToolTip/ToolTip';
import LiquidityForecastImage from './liquidityForecastModel.png';
import { ReportContext } from '../../../../common/reportContext';

const options: ChartOptions<'line'> = {
  scales: {
    y: {
      beginAtZero: true,
      suggestedMax: 20,
      position: 'right',
      ticks: {
        callback: (tickValue: string | number, index: number, ticks: Tick[]) =>
          numberFormatting(+tickValue)
      }
    },
    x: {
      grid: {
        display: false
      },
      ticks: {
        autoSkip: false
      }
    }
  },
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: (tooltipItem) => {
          return tooltipItem.dataset.label
            ? tooltipItem.dataset.label + `: $${tooltipItem.formattedValue}`
            : `$${tooltipItem.formattedValue}`;
        },
        labelColor: (context) => {
          return {
            borderColor: String(context.dataset.borderColor),
            backgroundColor: String(context.dataset.borderColor)
          };
        }
      }
    }
  },
  elements: {
    point: {
      radius: 0
    }
  },
  interaction: {
    mode: 'index'
  }
};

interface LiquidityForecastChartProps {
  selectedAccountGroupIndex: number;
}

const LiquidityForecastChart = (props: LiquidityForecastChartProps) => {
  const { t } = useTranslation();

  const { report } = useContext(ReportContext);

  const nearTermLiquidity =
    report.nearTermLiquidity.nearTermLiquidity[props.selectedAccountGroupIndex];

  const repeatValue = (val: number) => [...Array(366)].map((_val) => val);

  const getLabels = (): string[] => {
    let rollingMonth = -1;
    return nearTermLiquidity.forecastModelDaily.forecast.map((obj) => {
      let [year, month, day] = obj.day.split('-').map((x) => +x);
      month = month - 1;
      if (month != rollingMonth) {
        const dateObject = new Date(year, month, day);
        rollingMonth = month;
        return dateObject.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short'
        });
      }
      return '';
    });
  };

  const forecast = {
    label: 'Forecast',
    data: nearTermLiquidity.forecastModelDaily.forecast.map((obj) => Math.floor(obj.amount)),
    borderColor: '#015545'
  };

  const lowForecast = {
    label: 'Low forecast',
    data: nearTermLiquidity.forecastModelDaily.lowForecast.map((obj) => Math.floor(obj.amount)),
    borderColor: '#f1b310'
  };

  const yearPrior = {
    label: 'Year Prior',
    data: nearTermLiquidity.forecastModelDaily.yearPriorActual.map((obj) => Math.floor(obj.amount)),
    borderColor: '#460074'
  };

  const strategicLiquidity = {
    label: 'Strategic Liquidity',
    data: repeatValue(nearTermLiquidity.forecastModelDaily.strategicLiquidity),
    borderColor: '#333333'
  };

  const chartData = {
    labels: getLabels(),
    datasets: [forecast, lowForecast, yearPrior, strategicLiquidity]
  };

  return (
    <TPOPaper color="rgba(70, 0, 116, 0.3)">
      <Stack spacing={3} padding={3}>
        <Typography
          align="right"
          sx={{
            fontSize: '18px',
            color: 'text.primary',
            fontWeight: 'bold'
          }}
        >
          Short-term Cash Position Forecast
        </Typography>

        <Line data={chartData} options={options} redraw />

        <Stack direction={'row'} spacing={2}>
          <TPOLabel text="Forecast" color="#015545" />
          <TPOLabel text="Low forecast" color="#f1b310" />
          <TPOLabel text="Year prior actual" color="#460074" />
          <TPOLabel text="Strategic Liquidity" color="#333333" />
        </Stack>
        <Stack direction={'row'} spacing={70}>
          <ToolTip
            title={t('cashVestAnalyses.shortTermLiquidity.shortTermForecastChartToolTipTitle')}
            content={t('cashVestAnalyses.shortTermLiquidity.shortTermForecastChartToolTipContent')}
            label={t('common.explainChart')}
          />
          <Box>
            <img src={LiquidityForecastImage} width={209} height={47} />
          </Box>
        </Stack>
      </Stack>
    </TPOPaper>
  );
};

export default React.memo(LiquidityForecastChart);
