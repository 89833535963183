import { Dayjs } from 'dayjs';

import { FormInvestment, Investment } from '../AddInvestmentDrawer.types';
import { AccountNames } from './InvestmentForm';

export const PRODUCTION_INVESTMENT_TYPES_GROUP_2 = [
  1, // CD
  6, // Money_Market_Mutual_Fund
  7, // Repo
  8, // CDARs
  12 // ECR
];

export const PRODUCTION_INVESTMENT_TYPES_GROUP_3 = [
  2, // Treasury_Note
  10, // US_Agency_Bonds
];
/*
 * Transform values retrieved from the investment form to match the api contract
 */
const transformValues = (
  {
    accountNumber,
    investmentTypeId,
    purchaseDate,
    maturityDate,
    callDate,
    couponRate,
    interestRate,
    purchaseAmount,
    faceValue,
    ...otherValues
  }: FormInvestment,
  accountNames: AccountNames
): Investment => {
  const toDate = (date: Dayjs) => date.toISOString().slice(0, 10);

  return {
    accountNumber: accountNames[+accountNumber],
    investmentTypeId: investmentTypeId,
    interestRate: +interestRate,
    purchaseDate: toDate(purchaseDate as Dayjs),
    maturityDate: toDate(maturityDate as Dayjs),
    callDate: callDate ? toDate(callDate) : null,
    couponRate: +couponRate,
    purchaseAmount: +purchaseAmount,
    faceValue: +faceValue,
    ...otherValues
  };
};

export default transformValues;
