import React, { useContext } from 'react';
import { Stack, Box } from '@mui/material';
import FinancialSummary from './FinancialSummary/FinancialSummary';
import LiquidityBreakdown from './LiquidityBreakdown/LiquidityBreakdown';
import CurrentStatus from './CurrentStatus/CurrentStatus';
import BaseSummary from '../BaseSummary/BaseSummary';
import { useTranslation } from 'react-i18next';
import { CommentsContext } from '../../../common/commentsContext';

interface CashBoardProps {
  reportDate: string;
}

const CashBoard = (props: CashBoardProps) => {
  const { t } = useTranslation();

  const { comments, setComments } = useContext(CommentsContext);

  return (
    <Stack spacing={3}>
      <BaseSummary
        title={t('cashVestAnalyses.cashBoard.title', { reportDate: props.reportDate })}
        content={t('cashVestAnalyses.cashBoard.summary')}
        // content={comments.cashBoard.summary}
        setContent={(content) => {
          const newComments = { ...comments };
          newComments.cashBoard.summary = content;
          setComments(newComments);
        }}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <FinancialSummary />
        <Box
          sx={{
            width: '30px'
          }}
        />
        <LiquidityBreakdown />
      </Box>

      <CurrentStatus />
    </Stack>
  );
};

export default CashBoard;
