import React from 'react';
import { Typography } from '@mui/material';

interface SecondaryTabButtonProps {
  text: string;
  onClick: () => void;
  isActive: boolean;
}

const SecondaryTabButton = (props: SecondaryTabButtonProps) => {
  return (
    <Typography
      onClick={props.onClick}
      sx={{
        flexGrow: 1,
        backgroundColor: props.isActive ? 'primary.main' : '#ffffff',
        color: props.isActive ? '#ffffff' : 'text.primary',
        cursor: 'pointer',
        padding: 2,
        textAlign: 'center',
        fontSize: '16px'
      }}
    >
      {props.text}
    </Typography>
  );
};

export default SecondaryTabButton;
