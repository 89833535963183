import React from 'react';
import { Box, Stack } from '@mui/material';

import TPOTable from '../../TPOTable/TPOTable';

import styles from './Components.module.scss';
import { Investment } from '../AddInvestmentDrawer.types';
import { DrawerCancelButton, DrawerFilledSubmitButton, DrawerOutlinedButton } from './UIElements';
import { useTranslation } from 'react-i18next';
import TPODialogConfirmation from './TPODialogConfirmation';
import TPOEmptyTableTypography from '../../TPOEmptyTableTypography/TPOEmptyTableTypography';
import { hasContent } from '../../../common/helpers';
import { InvestmentType } from '../../../networking/accounts/investments/InvestmentType.types';

export const SUBMIT_ALL_SUCCESSFUL_TPO_DIALOG_DATA_TESTID =
  'SUBMIT_ALL_SUCCESSFUL_TPO_DIALOG_DATA_TESTID';
export const SUBMIT_ALL_UNSUCCESSFUL_TPO_DIALOG_DATA_TESTID =
  'SUBMIT_ALL_UNSUCCESSFUL_TPO_DIALOG_DATA_TESTID';

interface AddedPageProps {
  investments: Investment[];
  investmentTypes: InvestmentType[];
  handleAddAnotherInvestment: () => void;
  handleSubmit: () => void;
  handleOpenEditPage: (investmentIndex: number) => void;
  handleCancel: () => void;
  isSuccessfullySubmitted: boolean;
  isUnsuccessfullySubmitted: boolean;
  isSubmitAllLoading: boolean;
  handlePrimaryButtonSuccessfulTPODialogConfirmation: () => void;
  handlePrimaryButtonUnsuccessfulTPODialogConfirmation: () => void;
}

const AddedPage = ({
  investments,
  investmentTypes,
  handleAddAnotherInvestment,
  handleSubmit,
  handleOpenEditPage,
  handleCancel,
  isSuccessfullySubmitted,
  isUnsuccessfullySubmitted,
  isSubmitAllLoading,
  handlePrimaryButtonSuccessfulTPODialogConfirmation,
  handlePrimaryButtonUnsuccessfulTPODialogConfirmation
}: AddedPageProps) => {
  const { t } = useTranslation();

  const HEADERS = [
    {
      label: t('investments.addInvestment.investmentForm.investmentType'),
      id: 'type',
      showToolTip: false
    },
    {
      label: t('investments.addInvestment.investmentForm.purchaseDate'),
      id: 'purchaseDate',
      showToolTip: false
    },
    {
      label: t('investments.addInvestment.investmentForm.maturityDate'),
      id: 'maturityDate',
      showToolTip: false
    },
    {
      label: t('investments.addInvestment.investmentForm.faceValue'),
      id: 'faceValue',
      showToolTip: false
    },
    {
      label: t('investments.addInvestment.investmentForm.interestRate'),
      id: 'interestRate',
      showToolTip: false
    },
    {
      label: '',
      id: 'edit',
      showToolTip: false
    }
  ];

  const mapIdToString = (id: number) => {
    const item = investmentTypes.find((item) => item.typeId == id);
    return item ? item.typeName : '';
  }

  const content = investments.map(
    ({ investmentTypeId, purchaseDate, maturityDate, faceValue, interestRate }) => [
      mapIdToString(investmentTypeId),
      purchaseDate,
      maturityDate,
      `${faceValue}`,
      `${interestRate}`
    ]
  );

  return (
    <Box className={styles.TableBox}>
      <TPODialogConfirmation
        title={t('investments.addInvestment.addedPage.submitAllUnsuccessfulTPODialog.title')}
        content={t('investments.addInvestment.addedPage.submitAllUnsuccessfulTPODialog.content')}
        isOpen={isUnsuccessfullySubmitted}
        handlePrimaryButton={handlePrimaryButtonUnsuccessfulTPODialogConfirmation}
        textPrimaryButton={t(
          'investments.addInvestment.addedPage.submitAllUnsuccessfulTPODialog.goBackButton'
        )}
        datatestid={SUBMIT_ALL_UNSUCCESSFUL_TPO_DIALOG_DATA_TESTID}
      />
      <TPODialogConfirmation
        title={t('investments.addInvestment.addedPage.submitAllSuccessfulTPODialog.title')}
        content={t('investments.addInvestment.addedPage.submitAllSuccessfulTPODialog.content')}
        isOpen={isSuccessfullySubmitted}
        handlePrimaryButton={handlePrimaryButtonSuccessfulTPODialogConfirmation}
        textPrimaryButton={t(
          'investments.addInvestment.addedPage.submitAllSuccessfulTPODialog.okayButton'
        )}
        datatestid={SUBMIT_ALL_SUCCESSFUL_TPO_DIALOG_DATA_TESTID}
      />
      <TPOTable headers={HEADERS} content={content} handleOpenEditPage={handleOpenEditPage} />
      {!hasContent(content) && (
        <TPOEmptyTableTypography
          text={t('investments.addInvestment.addedPage.emptyAddedListMessage')}
        />
      )}
      <Stack spacing={2} sx={{ paddingTop: '54px' }}>
        <DrawerFilledSubmitButton
          onClick={handleAddAnotherInvestment}
          disabled={content.length === 0 || isSubmitAllLoading}
        >
          {t('investments.addInvestment.addedPage.addAnotherInvestmentButton') as string}
        </DrawerFilledSubmitButton>
        <DrawerOutlinedButton
          onClick={handleSubmit}
          disabled={content.length === 0 || isSubmitAllLoading}
          isLoading={isSubmitAllLoading}
          text={t('investments.addInvestment.addedPage.submitAllButton')}
        />
        <DrawerCancelButton text="Cancel" onClick={handleCancel} disabled={isSubmitAllLoading} />
      </Stack>
    </Box>
  );
};

export default AddedPage;
