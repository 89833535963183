import React, { ReactElement, useState } from 'react';
import { Typography, SelectChangeEvent, Select, MenuItem, Box } from '@mui/material';

export interface DropdownSummaryProps {
  children: ReactElement;
  title: string;
  options: string[];
  index: number;
  setIndex: (index: number) => void;
}

const DropdownSummary = (props: DropdownSummaryProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    props.setIndex(+event.target.value);
  };

  return (
    <Box>
      {props.children}
      <Typography
        sx={{
          fontSize: '18px',
          color: 'text.primary',
          marginTop: '30px',
          marginBottom: '10px',
          fontWeight: 650
        }}
      >
        {props.title}
      </Typography>
      <Select
        value={String(props.index)}
        onChange={handleChange}
        sx={{
          backgroundColor: '#f2f2f2',
          width: '350px',
          height: '40px'
        }}
      >
        {props.options.map((option, i) => (
          <MenuItem value={i} key={i}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default DropdownSummary;
