import React, { useContext } from 'react';
import { Stack, Typography, Box, Grid } from '@mui/material';
import TPOPaper from '../../../components/TPOPaper/TPOPaper';
import ScorecardTabSection from './ScorecardTabSection';
import ScorecardChart from './ScorecardChart';
import Star from './star.svg';
import Edit from './edit.png';
import Circle from './circle.svg';
import BaseSummary from '../BaseSummary/BaseSummary';
import { CommentsContext } from '../../../common/commentsContext';
import { ReportContext } from '../../../common/reportContext';
import EditTextField from '../../../components/EditTextField/EditTextField';
import { currencyFormatting } from '../../../common/helpers';

const Scorecard = () => {
  const { comments, setComments } = useContext(CommentsContext);
  const { report, setReport } = useContext(ReportContext);
  const periodStats = report.scorecard.periodStats[0];

  return (
    <Stack spacing={3}>
      <BaseSummary
        editable={true}
        title="Scorecard"
        content={comments.scoreCard.summary}
        setContent={(content) => {
          const newComments = { ...comments };
          newComments.scoreCard.summary = content;
          setComments(newComments);
        }}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            marginRight: '25px'
          }}
        >
          <ScorecardChart />
        </Box>

        <TPOPaper color="rgba(70, 0, 116, 0.3)">
          <Box
            sx={{
              padding: 3
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                padding: 3
              }}
            >
              <img src={Number(periodStats.value) >= 90 ? Star : Circle} />
              <Typography
                sx={{
                  fontSize: '28px',
                  color: 'text.primary',
                  position: 'absolute',
                  marginTop: Number(periodStats.value) >= 90 ? '28px' : '18px'
                }}
              >
                {periodStats.value}
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: '32px',
                color: 'text.primary',
                textAlign: 'center',
                marginBottom: '20px'
              }}
            >
              cashVest Score
            </Typography>

            <Grid container spacing="20px">
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: '20px',
                    color: 'text.primary',
                    fontWeight: 550,
                    textAlign: 'center'
                  }}
                >
                  {currencyFormatting(periodStats.periodInterest)}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    color: 'text.primary',
                    textAlign: 'left'
                  }}
                >
                  Period Interest
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: '20px',
                    color: 'text.primary',
                    fontWeight: 550,
                    textAlign: 'center'
                  }}
                >
                  {currencyFormatting(periodStats.interestInLastYear)}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    color: 'text.primary',
                    textAlign: 'left'
                  }}
                >
                  Last 12 months
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  data-test="interestSinceAccountOpened"
                  sx={{
                    fontSize: '20px',
                    color: 'text.primary',
                    fontWeight: 550,
                    textAlign: 'center'
                  }}
                >
                  {currencyFormatting(periodStats.interestSinceAccountOpened)}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    color: 'text.primary',
                    textAlign: 'left'
                  }}
                >
                  Cumulative Interest
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </TPOPaper>
      </Box>

      <ScorecardTabSection />

      <TPOPaper color="rgba(70, 0, 116, 0.3)">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <Box
            sx={{
              width: '5px',
              backgroundColor: '#460074'
            }}
          />

          <Stack
            spacing={2}
            sx={{
              padding: 3,
              width: '100%'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <img src={Edit} width="50px" />
            </Box>
            <Typography
              sx={{
                fontSize: '32px',
                color: 'text.primary',
                textAlign: 'center'
              }}
            >
              Activities to help improve your cashVest Score
            </Typography>
            {/* TODO: refactor the improvement text boxes */}
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'auto auto',
                gap: '16px'
              }}
            >
              <Stack
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%'
                }}
                spacing={2}
              >
                <Box
                  sx={{
                    height: '10px',
                    width: '10px',
                    backgroundColor: '#015545',
                    borderRadius: '50%'
                  }}
                />
                <EditTextField
                  text={comments.scoreCard.improvement1}
                  setText={(text) => {
                    const newComments = { ...comments };
                    newComments.scoreCard.improvement1 = text;
                    setComments(newComments);
                  }}
                />
              </Stack>
              <Stack
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexGrow: 1
                }}
                spacing={2}
              >
                <Box
                  sx={{
                    height: '10px',
                    width: '10px',
                    backgroundColor: '#015545',
                    borderRadius: '50%'
                  }}
                />
                <EditTextField
                  text={comments.scoreCard.improvement2}
                  setText={(text) => {
                    const newComments = { ...comments };
                    newComments.scoreCard.improvement2 = text;
                    setComments(newComments);
                  }}
                />
              </Stack>
              <Stack
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexGrow: 1
                }}
                spacing={2}
              >
                <Box
                  sx={{
                    height: '10px',
                    width: '10px',
                    backgroundColor: '#015545',
                    borderRadius: '50%'
                  }}
                />
                <EditTextField
                  text={comments.scoreCard.improvement3}
                  setText={(text) => {
                    const newComments = { ...comments };
                    newComments.scoreCard.improvement3 = text;
                    setComments(newComments);
                  }}
                />
              </Stack>
              <Stack
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexGrow: 1
                }}
                spacing={2}
              >
                <Box
                  sx={{
                    height: '10px',
                    width: '10px',
                    backgroundColor: '#015545',
                    borderRadius: '50%'
                  }}
                />
                <EditTextField
                  text={comments.scoreCard.improvement4}
                  setText={(text) => {
                    const newComments = { ...comments };
                    newComments.scoreCard.improvement4 = text;
                    setComments(newComments);
                  }}
                />
              </Stack>
              <Stack
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexGrow: 1
                }}
                spacing={2}
              >
                <Box
                  sx={{
                    height: '10px',
                    width: '10px',
                    backgroundColor: '#015545',
                    borderRadius: '50%'
                  }}
                />
                <EditTextField
                  text={comments.scoreCard.improvement5}
                  setText={(text) => {
                    const newComments = { ...comments };
                    newComments.scoreCard.improvement5 = text;
                    setComments(newComments);
                  }}
                />
              </Stack>
              <Stack
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexGrow: 1
                }}
                spacing={2}
              >
                <Box
                  sx={{
                    height: '10px',
                    width: '10px',
                    backgroundColor: '#015545',
                    borderRadius: '50%'
                  }}
                />
                <EditTextField
                  text={comments.scoreCard.improvement6}
                  setText={(text) => {
                    const newComments = { ...comments };
                    newComments.scoreCard.improvement6 = text;
                    setComments(newComments);
                  }}
                />
              </Stack>
            </Box>
          </Stack>
        </Box>
      </TPOPaper>
    </Stack>
  );
};

export default Scorecard;
