import React, { useState, useContext } from 'react';
import PaperSection from '../../../../components/PaperSection/PaperSection';
import { Stack, Typography, SelectChangeEvent, Box, Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LiquidityForecastChart from './LiquidityForecastChart';
import ToolTip from '../../../../components/ToolTip/ToolTip';
import ShortTermForecastTable from './ShortTermForecastTable/ShortTermForecastTable';
import EditTextField from '../../../../components/EditTextField/EditTextField';
import { CommentsContext } from '../../../../common/commentsContext';
import { ReportContext } from '../../../../common/reportContext';

interface LiquidityForecastProps {
  selectedAccountGroupIndex: number;
}

const LiquidityForecast = (props: LiquidityForecastProps) => {
  const { t } = useTranslation();

  const [index, setIndex] = useState(0);
  const { report } = useContext(ReportContext);

  const handleChange = (event: SelectChangeEvent) => {
    setIndex(+event.target.value);
  };

  const options = ['Chart', 'Table'];

  const { comments, setComments } = useContext(CommentsContext);

  return (
    <PaperSection title={t('cashVestAnalyses.shortTermLiquidity.liquidityForecastTitle')}>
      <Stack spacing={2}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '700px auto',
            alignItems: 'end',
            justifyContent: 'space-between'
          }}
        >
          <Stack spacing={2}>
            <Typography
              sx={{
                color: 'text.primary',
                fontSize: '18px',
                fontWeight: 650
              }}
            >
              {t('common.cashVestAnalysisHeading')}
            </Typography>
            <EditTextField
              text={comments.nearTermLiquidity.forecast}
              setText={(text) => {
                const newComments = { ...comments };
                newComments.nearTermLiquidity.forecast = text;
                setComments(newComments);
              }}
            />
          </Stack>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: '18px',
              color: 'text.primary',
              fontWeight: 650,
              marginBottom: '10px'
            }}
          >
            {t('common.selectView')}
          </Typography>

          <Box>
            <Select
              value={String(index)}
              onChange={handleChange}
              sx={{
                backgroundColor: '#f2f2f2',
                width: '350px',
                height: '40px'
              }}
            >
              {options.map((option, i) => (
                <MenuItem value={i} key={i}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        {index == 0 && (
          <LiquidityForecastChart selectedAccountGroupIndex={props.selectedAccountGroupIndex} />
        )}

        {index == 1 && (
          <>
            <ShortTermForecastTable selectedAccountGroupIndex={props.selectedAccountGroupIndex} />
            <ToolTip
              title={t('cashVestAnalyses.shortTermLiquidity.shortTermForecastTableToolTipTitle')}
              content={t(
                'cashVestAnalyses.shortTermLiquidity.shortTermForecastTableToolTipContent'
              )}
              label={t('common.explainTable')}
            />
          </>
        )}
      </Stack>
    </PaperSection>
  );
};

export default LiquidityForecast;
