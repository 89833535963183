import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import FileSaver from 'file-saver';
import XLSX, { BookType } from 'sheetjs-style';
import TPOButton from '../TPOButton';

export const DROP_DOWN_BUTTON_TESTID = 'DROP_DOWN_BUTTON_TESTID';
export const EXPORT_POPPER_TESTID = 'EXPORT_POPPER_TESTID';

interface TPODropdownProps {
  sheetContent: unknown[];
  sheetHeaders: string[];
  fileName: string;
  styles?: CSSProperties;
}

const TPOExportDropdown = ({ sheetContent, sheetHeaders, fileName }: TPODropdownProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const exportTable = async (exportType: BookType) => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.' + exportType;

    const worksheet = XLSX.utils.json_to_sheet(sheetContent);
    XLSX.utils.sheet_add_aoa(worksheet, [sheetHeaders], { origin: 'A1' });
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: exportType, type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const handleExportCSV = () => {
    exportTable('csv');
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Box>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        style={{ display: 'flex', boxShadow: 'none' }}
      >
        <TPOButton
          styles={{
            width: '110px',
            borderColor: '#076b58 !important'
          }}
        >
          {[t('common.exportTable') as string]}
        </TPOButton>
        <TPOButton
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon data-testid={DROP_DOWN_BUTTON_TESTID} />
        </TPOButton>
      </ButtonGroup>
      <Popper
        data-testid={EXPORT_POPPER_TESTID}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{
          zIndex: 1,
          translate: '37px',
          width: '90px'
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem onClick={handleExportCSV}> .csv</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default TPOExportDropdown;
