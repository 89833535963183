import React, { useContext } from 'react';
import { Stack, Typography } from '@mui/material';
import { ChartOptions, Tick } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ReportContext } from '../../../../common/reportContext';
import { numberFormatting } from '../../../../common/helpers';

const chartOptions: ChartOptions<'line'> = {
  scales: {
    y: {
      beginAtZero: true,
      suggestedMax: 5,
      suggestedMin: -5,
      position: 'right',
      ticks: {
        callback: (tickValue: string | number, index: number, ticks: Tick[]) =>
          numberFormatting(+tickValue)
      }
    },
    x: {
      grid: {
        display: false
      },
      ticks: {
        minRotation: 50,
        autoSkip: false
      }
    }
  },
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: (tooltipItem) => {
          return tooltipItem.dataset.label
            ? tooltipItem.dataset.label + `: $${tooltipItem.formattedValue}`
            : `$${tooltipItem.formattedValue}`;
        },
        labelColor: (context) => {
          return {
            borderColor: String(context.dataset.borderColor),
            backgroundColor: String(context.dataset.borderColor)
          };
        }
      }
    }
  },
  elements: {
    point: {
      radius: 0
    }
  }
};

interface VarianceChartProps {
  selectedAccountGroupIndex: number;
  chartIndex: number;
}

const VarianceChart = (props: VarianceChartProps) => {
  const { report } = useContext(ReportContext);
  const variance =
    report.nearTermLiquidity.nearTermLiquidity[props.selectedAccountGroupIndex].variance;

  const graphHeaders = ['Day-to-day Variance', 'Weekly Variance', 'Monthly Variance'];

  const graphColors = ['#460074', '#015545', '#f1b310'];

  const chartDataset = () => {
    let amounts: number[] = [];
    if (graphHeaders[props.chartIndex] === 'Monthly Variance') {
      amounts = variance.monthly.map((obj) => Math.floor(obj.amount));
    } else if (graphHeaders[props.chartIndex] === 'Weekly Variance') {
      amounts = variance.weekly.map((obj) => Math.floor(obj.amount));
    } else if (graphHeaders[props.chartIndex] === 'Day-to-day Variance') {
      amounts = variance.daily.map((obj) => Math.floor(obj.amount));
    }
    return amounts;
  };

  const extractLabels = () => {
    let labels: string[] = [];
    if (graphHeaders[props.chartIndex] === 'Monthly Variance') {
      labels = variance.monthly.map((obj) =>
        new Date(obj.date).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          timeZone: 'UTC'
        })
      );
    }

    if (graphHeaders[props.chartIndex] === 'Weekly Variance') {
      let prevMonth = NaN;
      labels = variance.weekly.map((obj) => {
        let [year, month, day] = obj.date.split('-').map((x) => +x);
        month -= 1;
        if (month != prevMonth) {
          prevMonth = month;
          return new Date(year, month, day).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short'
          });
        }
        return '';
      });
    }

    if (graphHeaders[props.chartIndex] === 'Day-to-day Variance') {
      labels = variance.daily.map((obj) => {
        const [year, month, day] = obj.date.split('-').map((x) => +x);
        const dateObject = new Date(year, month - 1, day);
        if (dateObject.getDate() === 1) {
          return dateObject.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short'
          });
        }
        return '';
      });
    }
    return labels;
  };

  const chartData = {
    labels: extractLabels(),
    datasets: [
      {
        data: chartDataset(),
        borderColor: graphColors[props.chartIndex]
      }
    ]
  };

  return (
    <Stack spacing={1}>
      <Typography
        sx={{
          fontSize: '18px',
          color: 'text.primary',
          fontWeight: 500,
          textAlign: 'end'
        }}
      >
        {graphHeaders[props.chartIndex]}
      </Typography>

      <Line options={chartOptions} data={chartData} redraw />
    </Stack>
  );
};

export default React.memo(VarianceChart);
