export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validatePassword = (password: string): boolean => {
  return password.length > 11;
};

export const validatePasswordSetup = (password: string): boolean => {
  // "Your password must be at least 12 characters long and it should contain
  // at least one of each of the following: a lower case letter, an upper case letter, a number and a symbol."
  const passwordRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/;
  return passwordRegex.test(password) && password.length > 11;
};

export const isValidAuthCredentials = (code: string): boolean => {
  let isValid = true;
  if (
    String(code).includes('UserNotFoundException') ||
    String(code).includes('NotAuthorizedException')
  ) {
    isValid = false;
  }
  return isValid;
};

export const isValidPhoneNumber = (phoneNumber: string): boolean => {
  const phoneNumberRegex = /^[0-9]{7,}$/;
  return phoneNumberRegex.test(phoneNumber);
};

export const isValidOneTimePasscode = (otp: string): boolean => {
  const otpRegex = /^[0-9]+$/;
  if (otp.length < 6 || otp.length > 6) {
    return false;
  }
  return otpRegex.test(otp);
};

export const isAuthorizedOneTimePasscode = (otp: string): boolean => {
  return !String(otp).includes('CodeMismatchException');
};
