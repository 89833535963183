import React from 'react';
import { useTranslation } from 'react-i18next';
import PageSetupSuccess from '../../components/PageSetupSuccess/PageSetupSuccess';

const SetupMFASuccess = () => {
  const { t } = useTranslation();

  return (
    <PageSetupSuccess
      successTitle={t('login.mfaSetupSuccessTitle')}
      successMessage={t('login.mfaSetupSuccessMessage')}
    />
  );
};

export default SetupMFASuccess;
