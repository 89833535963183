import React, { useState, useContext } from 'react';
import BaseSummary from '../BaseSummary/BaseSummary';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import TreasuryServicesTabSection from './TreasuryServicesTabSection';
import { CommentsContext } from '../../../common/commentsContext';
import { ReportContext } from '../../../common/reportContext';
import DropdownSummary from '../DropdownSummary/DropdownSummary';
import { TreasuryService } from '../../../networking/analyses/report/TreasuryServices.types';

const TreasuryServices = () => {
  const { t } = useTranslation();
  const { report } = useContext(ReportContext);
  const [index, setIndex] = useState(0);

  const options: string[] = report.treasuryServices.treasuryServices.map(
    (treasuryService: TreasuryService) => treasuryService.accountGroup
  );

  const { comments, setComments } = useContext(CommentsContext);

  return (
    <Stack spacing={3}>
      <DropdownSummary
        title="Select Treasury Analysis Group"
        options={options}
        index={index}
        setIndex={setIndex}
      >
        <BaseSummary
          editable={true}
          title={t('cashVestAnalyses.treasuryServices.title')}
          content={comments.treasuryServices.summary}
          setContent={(content) => {
            const newComments = { ...comments };
            newComments.treasuryServices.summary = content;
            setComments(newComments);
          }}
        />
      </DropdownSummary>
      <TreasuryServicesTabSection selectedAccountGroupIndex={index} />
    </Stack>
  );
};

export default TreasuryServices;
