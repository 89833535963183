import React, { useContext } from 'react';
import {
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody
} from '@mui/material';
import { currencyFormatting, rateFormatting } from '../../../../../common/helpers';
import { useTranslation } from 'react-i18next';
import { ReportContext } from '../../../../../common/reportContext';

interface BankBalancesTableProps {
  selectedAccountGroupIndex: number;
  isAnnual: boolean;
}

const BankBalancesTable = (props: BankBalancesTableProps) => {
  const { t } = useTranslation();
  const { report } = useContext(ReportContext);

  const headers = [
    t('cashVestAnalyses.accountOptimization.balancesFirstColumn'),
    t('cashVestAnalyses.accountOptimization.balancesSecondColumn'),
    t('cashVestAnalyses.accountOptimization.balancesThirdColumn'),
    t('cashVestAnalyses.accountOptimization.balancesFourthColumn'),
    t('cashVestAnalyses.accountOptimization.balancesFifthColumn')
  ];

  const accountGroup = report.accountOptimization.groups[props.selectedAccountGroupIndex];

  const bankByBankBalances = props.isAnnual
    ? report.accountOptimization.balanceByBank.annualBalances
    : report.accountOptimization.balanceByBank.periodicBalances;

  const groupBalances = props.isAnnual
    ? accountGroup.annualBalances
    : accountGroup.periodicBalances;

  const getValues = () => {
    return accountGroup.accountGroup === 'All Accounts'
      ? bankByBankBalances.balances
      : groupBalances.balances.reduce<any>((previousValue, currentValue) => {
          return previousValue.concat(currentValue.account);
        }, []);
  };

  const getTotalValues = () => {
    return accountGroup.accountGroup === 'All Accounts'
      ? bankByBankBalances.grandTotal[0]
      : groupBalances.grandTotal[0];
  };

  const values = getValues();
  const totalValues = getTotalValues();

  function getName(annualBalance: any): string {
    return annualBalance.bankName
      ? annualBalance.bankName
      : annualBalance.accountNumber
      ? `${annualBalance.accountName} - ${annualBalance.accountNumber}`
      : annualBalance.accountName;
  }

  return (
    <Stack spacing={2}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: 'None' }} />
              {headers.map((item, i) => (
                <TableCell key={i}>
                  <Stack spacing={0}>
                    <Typography fontWeight="bold" fontSize="20px" noWrap={true}>
                      {item}
                    </Typography>
                  </Stack>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {values.map((annualBalance: any, i: number) => (
              <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell
                  align="center"
                  sx={{
                    color: 'text.primary',
                    fontSize: '16px'
                  }}
                >
                  {getName(annualBalance)}
                </TableCell>

                <TableCell
                  align="right"
                  sx={{
                    color: 'text.primary',
                    fontSize: '16px'
                  }}
                >
                  {currencyFormatting(annualBalance.endingBalance)}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: 'text.primary',
                    fontSize: '16px'
                  }}
                >
                  {currencyFormatting(annualBalance.avgBalance)}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: 'text.primary',
                    fontSize: '16px'
                  }}
                >
                  {currencyFormatting(annualBalance.minBalance)}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: 'text.primary',
                    fontSize: '16px'
                  }}
                >
                  {rateFormatting(annualBalance.interestRate * 100)}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: 'text.primary',
                    fontSize: '16px'
                  }}
                >
                  {currencyFormatting(annualBalance.interestSum)}
                </TableCell>
              </TableRow>
            ))}
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell
                align="center"
                sx={{
                  color: 'text.primary',
                  fontSize: '16px'
                }}
              >
                <Typography fontWeight="bold">Grand Total</Typography>
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  color: 'text.primary',
                  fontSize: '16px'
                }}
              >
                {currencyFormatting(totalValues.endingBalance)}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  color: 'text.primary',
                  fontSize: '16px'
                }}
              >
                {currencyFormatting(totalValues.avgBalance)}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  color: 'text.primary',
                  fontSize: '16px'
                }}
              >
                {currencyFormatting(totalValues.minBalance)}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  color: 'text.primary',
                  fontSize: '16px'
                }}
              >
                {rateFormatting(totalValues.interestRate * 100)}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  color: 'text.primary',
                  fontSize: '16px'
                }}
              >
                {currencyFormatting(totalValues.interestSum)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default BankBalancesTable;
