export interface ReportItem {
  reportId: number;
  financialReportKey: string;
  commentsReportKey: string;
  publishedDate: string;
  reportPeriodFrom: string;
  reportPeriodTo: string;
  published: boolean;
  updatedAt: string;
}

export interface AnalysesReport {
  reports: ReportItem[];
}

export const emptyAnalyses = {
  reports: []
};

export interface PublishReportResponse {
  reportId: number;
  publishedDate: string;
  published: boolean;
}
