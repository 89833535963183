import React, { useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { Order } from '../../../../components/TPOTable/TPOTableFunctions';
import TPOTable, { Header } from '../../../../components/TPOTable/TPOTable';
import { hasContent } from '../../../../common/helpers';
import TPOEmptyTableTypography from '../../../../components/TPOEmptyTableTypography';
import { useTranslation } from 'react-i18next';

export interface InvestmentTableRow {
  maturityDate: string;
  faceValue: number;
  interestRate: number;
  daysUntilMaturity: number;
  purchaseDate: string;
  account: string;
  financialInstitution: string;
  type: string;
  fund: string;
}
interface InvestmentTableProps {
  formattedInvestmentRows: string[][];
  order: Order;
  setOrder: React.Dispatch<React.SetStateAction<Order>>;
  orderBy: keyof InvestmentTableRow;
  setOrderBy: React.Dispatch<React.SetStateAction<keyof InvestmentTableRow>>;
  investmentTableHeaders: Header[];
}

const InvestmentTable = ({
  formattedInvestmentRows,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  investmentTableHeaders
}: InvestmentTableProps) => {
  const [showAll, setShowAll] = useState(false);

  const { t } = useTranslation();

  return (
    <Stack
      spacing={2}
      sx={{
        flexDirection: 'row',
        justifyContent: 'flex-end',
        flexWrap: 'wrap'
      }}
    >
      <TPOTable
        headers={investmentTableHeaders}
        content={showAll ? formattedInvestmentRows : formattedInvestmentRows.slice(0, 10)}
        sortable={{
          order: order,
          setOrder: setOrder,
          orderBy: orderBy,
          setOrderBy: setOrderBy
        }}
      />
      {!hasContent(formattedInvestmentRows) && (
        <TPOEmptyTableTypography
          text={t('investments.addInvestment.addedPage.emptyAddedListMessage')}
        />
      )}
      {!showAll && hasContent(formattedInvestmentRows) && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Button
            variant="outlined"
            onClick={() => setShowAll(true)}
            sx={{
              borderColor: 'primary.main',
              color: 'primary.main',
              backgroundColor: '#ffffff',
              textTransform: 'none',
              fontSize: '16px'
            }}
          >
            Show all
          </Button>
        </Box>
      )}
    </Stack>
  );
};

export default InvestmentTable;
