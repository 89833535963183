import {
  currencyFormatting,
  rateFormatting,
  daysUntilMaturityFormatting
} from '../../../../common/helpers';
import {
  stableSort,
  getComparator,
  Order
} from '../../../../components/TPOTable/TPOTableFunctions';
import { AccountsInvestment } from '../../../../networking/accounts/investments/AccountsInvestment.types';
import { InvestmentTableRow } from './InvestmentsTable';

const createData = (investments: AccountsInvestment[]) => {
  return investments.map((investment) => {
    return {
      account: `${investment.accountName} - ${investment.accountNumber}`,
      financialInstitution: investment.institutionName,
      type: investment.accountType,
      purchaseDate: investment.purchaseDate,
      maturityDate: investment.maturityDate,
      faceValue: investment.faceValue,
      interestRate: investment.interestRate,
      daysUntilMaturity: isNaN(investment.daysUntilMaturity) ? -1 : +investment.daysUntilMaturity,
      fund: investment.fund
    };
  });
};

const getDataPoints = (investment: InvestmentTableRow) => {
  return [
    investment.account,
    investment.financialInstitution,
    investment.type,
    new Date(investment.purchaseDate).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      timeZone: 'UTC'
    }),
    new Date(investment.maturityDate).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      timeZone: 'UTC'
    }),
    currencyFormatting(investment.faceValue),
    rateFormatting(investment.interestRate),
    daysUntilMaturityFormatting(investment.daysUntilMaturity),
    investment.fund
  ];
};

export const formatInvestmentTableRows = (
  investments: AccountsInvestment[],
  order: Order,
  orderBy: keyof InvestmentTableRow
) => {
  const rows = createData(investments);

  const sortedRows = stableSort(rows, getComparator(order, orderBy));
  const formattedTableRows = sortedRows.map((row: InvestmentTableRow) => getDataPoints(row));
  return formattedTableRows;
};
