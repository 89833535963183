import React, { useContext } from 'react';
import { ChartOptions, Tick } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { numberFormatting } from '../../../../common/helpers';
import { ReportContext } from '../../../../common/reportContext';
import { Box } from '@mui/material';
import TPOLabel from '../../../../components/TPOLabel/TPOLabel';

interface CurrentStatusChartProps {
  selectedAccountGroupIndex: number;
}

const chartOptions: ChartOptions<'line'> = {
  scales: {
    y: {
      beginAtZero: true,
      suggestedMax: 20,
      position: 'right',
      ticks: {
        callback: (tickValue: string | number, index: number, ticks: Tick[]) =>
          numberFormatting(+tickValue)
      }
    },
    x: {
      grid: {
        display: false
      },
      ticks: {
        autoSkip: false,
        callback: function (val, index) {
          // Only display ticks that are the first of the month
          const label = this.getLabelForValue(Number(val));
          return new Date(label).getDate() === 1 ? this.getLabelForValue(Number(val)) : '';
        }
      }
    }
  },
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: (tooltipItem) => {
          return tooltipItem.dataset.label
            ? tooltipItem.dataset.label + `: $${tooltipItem.formattedValue}`
            : `$${tooltipItem.formattedValue}`;
        },
        labelColor: (context) => {
          return {
            borderColor: String(context.dataset.borderColor),
            backgroundColor: String(context.dataset.borderColor)
          };
        }
      }
    }
  },
  elements: {
    point: {
      radius: 0
    }
  },
  interaction: {
    mode: 'index'
  }
};

const CurrentStatusChart = (props: CurrentStatusChartProps) => {
  const { report } = useContext(ReportContext);

  const yearOverYearChange =
    report.cashBoard.yearOverYearChange[props.selectedAccountGroupIndex].accountGroupBalances;

  const getLabels = (): string[] => {
    return yearOverYearChange.currentYear.map((obj) => {
      const [year, month, day] = obj.date.split('-').map((x) => +x);
      const dateObject = new Date(year, month - 1, day);
      return dateObject.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      });
    });
  };

  const previousYearData = {
    label: 'Previous Year',
    data: yearOverYearChange.previousYear.map((YearData) => YearData.amount),
    borderColor: '#f1b310'
  };

  const twoYearsPriorData = {
    label: 'Two Years Prior',
    data: yearOverYearChange.beforeLastYear.map((YearData) => YearData.amount),
    borderColor: '#460074'
  };

  const currentYearData = {
    label: 'Current Year',
    data: yearOverYearChange.currentYear.map((YearData) => YearData.amount),
    fill: true,
    borderColor: '#015545',
    backgroundColor: '#015545'
  };

  const addBufferData: any = (numberOfVoids: number) => {
    twoYearsPriorData.data = [...new Array(numberOfVoids).fill(null)].concat(
      twoYearsPriorData.data
    );
  };

  const rangeDifference =
    yearOverYearChange.previousYear.length - yearOverYearChange.beforeLastYear.length;

  rangeDifference > 0 && addBufferData(rangeDifference - 1);

  const chartData = {
    labels: getLabels(),
    datasets: [twoYearsPriorData, previousYearData, currentYearData]
  };

  return (
    <>
      <Line data={chartData} options={chartOptions} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <TPOLabel text="2 years prior" color={twoYearsPriorData.borderColor} />
        <TPOLabel text="Previous year" color={previousYearData.borderColor} />
        <TPOLabel text="Current year" color={currentYearData.borderColor} />
      </Box>
    </>
  );
};

export default React.memo(CurrentStatusChart);
