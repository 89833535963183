import React from 'react';
import { Stack, Typography } from '@mui/material';
import EditTextField from '../../../components/EditTextField/EditTextField';
import { useTranslation } from 'react-i18next';

interface BaseSummaryProps {
  title: string;
  content: string;
  setContent: (content: string) => void;
  editable?: boolean;
}

const BaseSummary = (props: BaseSummaryProps) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={1} width="700px">
      <Typography
        sx={{
          fontSize: '36px',
          color: 'text.primary'
        }}
      >
        {props.title}
      </Typography>
      {props.title === 'Treasury Services' && (
        <Typography
          sx={{
            color: 'text.primary',
            fontSize: '18px',
            fontWeight: 650
          }}
        >
          {t('common.cashVestAnalysisHeading')}
        </Typography>
      )}
      {props.editable != undefined ? (
        <EditTextField text={props.content} setText={props.setContent} />
      ) : (
        <Typography
          sx={{
            fontSize: '18px',
            color: 'text.primary'
          }}
        >
          {props.content}
        </Typography>
      )}
    </Stack>
  );
};

export default BaseSummary;
