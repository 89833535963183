import React from 'react';
import { Box, FormControlLabel, Checkbox, Button } from '@mui/material';
import StringFilter, { StringFilterState } from '../StringFilter/StringFilter';
import { useTranslation } from 'react-i18next';

export interface FiltersBarProps {
  dropdownFilters: {
    title: string;
    allSelectedTitle: string;
    state: StringFilterState;
    setState: React.Dispatch<React.SetStateAction<StringFilterState>>;
  }[];
  checkbox: {
    isChecked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    name: string;
    label: string;
  };
  applyFilters: () => void;
  clearFilters: () => void;
}

const FiltersBar: React.FC<FiltersBarProps> = ({
  dropdownFilters,
  checkbox,
  applyFilters,
  clearFilters
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gridGap: '20px',
        paddingBottom: '20px'
      }}
    >
      {dropdownFilters.map((filter, index) => (
        <StringFilter
          key={index}
          title={filter.title}
          allSelectedTitle={filter.allSelectedTitle}
          state={filter.state}
          setState={filter.setState}
        />
      ))}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'end'
        }}
      >
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={checkbox.isChecked}
                onChange={checkbox.onChange}
                name={checkbox.name}
                sx={{
                  color: 'rgb(51, 51, 51)'
                }}
              />
            }
            label={checkbox.label}
            sx={{ whiteSpace: 'nowrap' }}
          />
          <Button
            onClick={applyFilters}
            variant="contained"
            sx={{
              textTransform: 'none',
              width: '205px'
            }}
          >
            {t('common.applyFilters')}
          </Button>

          <Button
            onClick={clearFilters}
            sx={{
              textTransform: 'none',
              color: 'blue',
              textDecoration: 'underline',
              textUnderlineOffset: '3px',
              background: 'transparent',
              marginTop: '5px'
            }}
          >
            {t('common.clearFilters')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FiltersBar;
