import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../routing/ROUTES';
import { useNavigate } from 'react-router-dom';

interface CheckYourEmailProps {
  email: string;
}

const CheckYourEmail = (props: CheckYourEmailProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Stack
      spacing={1}
      sx={{
        width: '750px',
        marginTop: '10px',
        marginLeft: '45px',
        marginBottom: '120px'
      }}
    >
      <Typography
        sx={{
          fontSize: '45px',
          color: 'primary.main'
        }}
      >
        {t('login.checkYourEmailTitle')}
      </Typography>

      <Typography
        sx={{
          fontSize: '18px',
          color: 'text.primary'
        }}
      >
        {t('login.checkYourEmailSubitle')}
      </Typography>

      <Typography
        sx={{
          fontSize: '18px',
          color: 'text.primary',
          paddingTop: '20px'
        }}
      >
        {t('login.checkYourEmailMessage', { emailAddress: props.email })}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          paddingTop: '35px',
          alignItems: 'center'
        }}
      >
        <Button
          variant="contained"
          onClick={() => navigate(ROUTES.Logout)}
          sx={{
            textTransform: 'none',
            borderColor: 'primary.main',
            color: '#ffffff',
            backgroundColor: 'primary.main',
            fontSize: '16px',
            paddingLeft: '30px',
            paddingRight: '30px'
          }}
        >
          {t('navigation.backToLogin')}
        </Button>
      </Box>
    </Stack>
  );
};

export default CheckYourEmail;
