import React from 'react';
import { IconButton } from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import TPOTooltip from '../TPOTooltip/TPOTooltip';

interface TPOToolTipIconProps {
  toolTipHeader: string;
  toolTipText: string;
}

const TPOToolTipIcon = (props: TPOToolTipIconProps) => {
  return (
    <TPOTooltip title={props.toolTipHeader} content={props.toolTipText}>
      <IconButton
        sx={{
          color: 'common.white',
          backgroundColor: 'primary.main',
          width: '16px',
          height: '16px'
        }}
      >
        <QuestionMarkIcon sx={{ width: '16px', height: '16px' }} />
      </IconButton>
    </TPOTooltip>
  );
};

export default TPOToolTipIcon;
