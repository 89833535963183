import { Button, CircularProgress } from '@mui/material';
import React from 'react';

export interface TPOFormButtonProps {
  text: string;
  onClick: () => void;
  isLoading?: boolean;
  isSubmitType?: boolean;
}

const TPOFormButton = ({ text, onClick, isLoading, isSubmitType = false }: TPOFormButtonProps) => {
  return (
    <Button
      type={isSubmitType ? 'submit' : 'button'}
      variant="contained"
      onClick={() => onClick()}
      sx={{
        textTransform: 'none',
        borderColor: 'primary.main',
        color: '#ffffff',
        backgroundColor: 'primary.main',
        fontSize: '16px',
        paddingLeft: '30px',
        paddingRight: '30px',
        minWidth: '110px'
      }}
    >
      {isLoading ? (
        <CircularProgress
          size={28}
          sx={{
            color: 'inherit'
          }}
        />
      ) : (
        text
      )}
    </Button>
  );
};

export default TPOFormButton;
