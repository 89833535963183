import React, { useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { useTranslation } from 'react-i18next';
import { currencyFormatting } from '../../../../../common/helpers';
import { ReportContext } from '../../../../../common/reportContext';
import { ForecastModelWeekly } from '../../../../../networking/analyses/report/NearTermLiquidity.types';

interface ShortTermForecastTableProps {
  selectedAccountGroupIndex: number;
}
const getForecastModelDataPoints = (obj: ForecastModelWeekly) => {
  return [
    new Date(obj.weekEnding).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      timeZone: 'UTC'
    }),
    currencyFormatting(obj.totalForecast),
    currencyFormatting(obj.netForecast),
    currencyFormatting(obj.lowForecast),
    currencyFormatting(obj.yearPriorActual)
  ];
};

const ShortTermForecastTable = (props: ShortTermForecastTableProps) => {
  const { t } = useTranslation();
  const { report } = useContext(ReportContext);

  const headers = [
    {
      label: t('cashVestAnalyses.shortTermLiquidity.shortTermForecastTableHeader1'),
      showToolTip: false
    },
    {
      label: t('cashVestAnalyses.shortTermLiquidity.shortTermForecastTableHeader2'),
      showToolTip: false
    },
    {
      label: t('cashVestAnalyses.shortTermLiquidity.shortTermForecastTableHeader3'),
      showToolTip: false
    },
    {
      label: t('cashVestAnalyses.shortTermLiquidity.shortTermForecastTableHeader4'),
      showToolTip: false
    },
    {
      label: t('cashVestAnalyses.shortTermLiquidity.shortTermForecastTableHeader5'),
      showToolTip: false
    }
  ];

  const content = report.nearTermLiquidity.nearTermLiquidity[
    props.selectedAccountGroupIndex
  ].forecastModelWeekly.map((obj: ForecastModelWeekly) => getForecastModelDataPoints(obj));

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((item, i) => (
              <TableCell
                key={i}
                sx={{
                  backgroundColor: 'primary.main',
                  color: 'common.white',
                  fontSize: '16px',
                  textAlign: 'center'
                }}
              >
                {!item.showToolTip && item.label}
                {item.showToolTip && (
                  <Stack direction="row" alignItems="right" spacing={0}>
                    {item.label}
                    <IconButton
                      sx={{
                        color: 'common.white',
                        backgroundColor: 'primary.main'
                      }}
                    >
                      <QuestionMarkIcon sx={{ width: '16px', height: '16px' }} />
                    </IconButton>
                  </Stack>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {content.map((row, i) => (
            <TableRow
              key={i}
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0
                }
              }}
            >
              {row.map((item, i) => (
                <TableCell
                  key={i}
                  sx={{
                    fontSize: '16px',
                    backgroundColor: i == 1 ? 'primary.light' : 'common.white',
                    color: 'text.primary',
                    textAlign: 'center'
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ShortTermForecastTable;
