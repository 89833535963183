import ApiConfig from '../../../apiConfig';
import { getToken } from '../../../auth/authenticator';
import { AuthState } from '../../../common/AuthContext.types';
import { Comments } from './Comments.types';

export async function postComments(authState: AuthState, comments: Comments, reportId: number) {
  const apiUrl =
    ApiConfig.apiBaseUrl + `/analysis/comments?c_id=${authState.clientId}&r_id=${reportId}`;
  await fetch(apiUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getToken()}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ commentsReport: comments })
  });
}
