import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import white_footer_divider from './white_footer_divider.svg';
import grey_footer_divider from './grey_footer_divider.svg';
import { useLocation } from 'react-router-dom';
import styles from './Footer.module.scss';

const Footer = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Box className={styles.footerBox} data-testid="footer">
      <img
        src={location.pathname === '/analyses' ? grey_footer_divider : white_footer_divider}
        width="100%"
      />
      <Typography
        data-testid="footer-brand-name"
        sx={{
          fontSize: '20px',
          color: 'text.secondary',
          position: 'absolute',
          marginTop: '100px',
          marginLeft: '20px'
        }}
      >
        {t('tpoBrand.footer')}
      </Typography>

      <Typography
        data-testid="footer-disclaimer-bold"
        sx={{
          fontSize: '9px',
          fontWeight: 600,
          color: 'text.secondary',
          position: 'absolute',
          marginTop: '148px',
          width: '85%',
          textAlign: 'center'
        }}
      >
        {t('tpoBrand.disclaimerBold')}
      </Typography>

      <Typography
        data-testid="footer-disclaimer"
        sx={{
          fontSize: '9px',
          fontWeight: 200,
          color: 'text.secondary',
          position: 'absolute',
          marginTop: '165px',
          width: '85%'
        }}
      >
        {t('tpoBrand.disclaimer')}
      </Typography>
    </Box>
  );
};

export default Footer;
