import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { currencyFormatting, rateFormatting } from '../../../common/helpers';
import { ReportContext } from '../../../common/reportContext';
import ToolTip from '../../../components/ToolTip/ToolTip';
import TPOTableHeader from '../../../components/TPOTable/TPOTableHeader';
import { ContentRow } from './LineItemFees/LineItemFees.types';
import { Header } from './TreasuryServices.types';

interface TreasuryServicesTableProps {
  selectedAccountGroupIndex: number;
  tabState: number;
}

const TreasuryServicesTable = (props: TreasuryServicesTableProps) => {
  const { t } = useTranslation();
  const { report } = useContext(ReportContext);

  const treasuryService = report.treasuryServices.treasuryServices[props.selectedAccountGroupIndex];

  const createTableContent = (tabState: number): ContentRow[] => {
    if (tabState === 0) {
      return [
        {
          rowTitle: 'Balance Available to Offset Fees',
          rowValue: currencyFormatting(treasuryService.summaryBalance.availableBalancesAmount),
          showToolTip: true,
          toolTipContent: t(
            'cashVestAnalyses.treasuryServices.balanceAvailableToOffsetFeesToolTipContent'
          )
        },
        {
          rowTitle: 'Balance Needed to Offset Fees',
          rowValue: currencyFormatting(treasuryService.summaryBalance.balanceNeededAmount),
          showToolTip: true,
          toolTipContent: t(
            'cashVestAnalyses.treasuryServices.balanceNeededToOffsetFeesToolTipContent'
          )
        },
        {
          rowTitle: 'Excess/(Deficit) Balances',
          rowValue: currencyFormatting(treasuryService.summaryBalance.excessDeficitAmount),
          showToolTip: false,
          toolTipContent: null
        }
      ];
    }
    if (tabState === 1) {
      return [
        {
          rowTitle: 'Average Earnings Credit Rate (ECR)',
          rowValue: rateFormatting(
            treasuryService.earningsCreditRate.averageEarningCreditRateAmount * 100
          ),
          showToolTip: false,
          toolTipContent: null
        },
        {
          rowTitle: 'FDIC Fee',
          rowValue: rateFormatting(treasuryService.earningsCreditRate.fdicFeeAmount * 100),
          showToolTip: true,
          toolTipContent: t('cashVestAnalyses.treasuryServices.FDICToolTipContent')
        },
        {
          rowTitle: 'net Earnings Credit Rate (nECR)',
          rowValue: rateFormatting(
            treasuryService.earningsCreditRate.netEarningsCreditRateAmount * 100
          ),
          showToolTip: false,
          toolTipContent: null
        }
      ];
    }
    if (tabState === 2) {
      return [
        {
          rowTitle: 'Total Line Item Fees',
          rowValue: currencyFormatting(
            treasuryService.feesCompensatingOffset.totalLineItemFeesAmount
          ),
          showToolTip: false,
          toolTipContent: null
        },
        {
          rowTitle: 'Fees Offset Through Compensating Balances',
          rowValue: currencyFormatting(
            treasuryService.feesCompensatingOffset.feesCompensatingOffsetAmount
          ),
          showToolTip: false,
          toolTipContent: null
        },
        {
          rowTitle: 'Excess Credit / (Hard Fees)',
          rowValue: currencyFormatting(treasuryService.feesCompensatingOffset.excessCreditAmount),
          showToolTip: false,
          toolTipContent: null
        }
      ];
    }
    return [];
  };

  const selectTableHeaders = (tabState: number): Header[] => {
    if (tabState === 0) {
      return [
        { label: 'Balances', showToolTip: false },
        { label: 'Amount', showToolTip: false }
      ];
    }
    if (tabState === 1) {
      return [
        { label: 'Rate name', showToolTip: false },
        { label: 'Percentage', showToolTip: false }
      ];
    }
    if (tabState === 2) {
      return [
        { label: 'Fees', showToolTip: false },
        { label: '', showToolTip: false }
      ];
    }
    return [];
  };

  const headers = selectTableHeaders(props.tabState);

  const content = createTableContent(props.tabState);

  return (
    <Stack spacing={2} padding={3}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header, i) => (
                <TableCell
                  align={i ? 'right' : 'left'}
                  key={i}
                  sx={{
                    backgroundColor: 'primary.main',
                    color: 'common.white',
                    fontSize: '16px'
                  }}
                >
                  <TPOTableHeader
                    label={header.label}
                    showToolTip={header.showToolTip}
                    toolTipHeader={header.toolTipHeader}
                    toolTipText={header.toolTipText}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {content.map((row: ContentRow, i: number) => (
              <TableRow
                key={i}
                sx={{
                  '&:last-child td, &:last-child th': {
                    borderTop: 2,
                    borderBottom: 0
                  }
                }}
              >
                <TableCell
                  align="left"
                  sx={{
                    fontSize: '16px'
                  }}
                >
                  {!row.showToolTip && <Typography>{row.rowTitle}</Typography>}
                  {row.showToolTip && (
                    <Stack direction="row" spacing={1}>
                      <Typography>{row.rowTitle}</Typography>
                      <ToolTip title={row.rowTitle} content={row.toolTipContent} label="" />
                    </Stack>
                  )}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    fontSize: '16px'
                  }}
                >
                  {row.rowValue}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default TreasuryServicesTable;
