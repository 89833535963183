import { Box, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hasContent } from '../../common/helpers';
import { tpoErrorColor } from '../../styles/constants';
import FormValidation, { IFormError } from '../../common/validation/formValidation';
import TPOToolTipIcon from '../../components/TPOToolTipIcon/TPOToolTipIcon';
import TPOFormButton from '../../components/TPOFormButton/TPOFormButton';
import ICallbackProps from '../../common/interfaces/ICallbackProps';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const SetupPhoneNumberMFA = (props: ICallbackProps) => {
  const { t } = useTranslation();

  const [formErrors, setFormErrors] = useState<IFormError<string>>({});
  const [phoneNumber, setPhoneNumber] = useState('');

  const formValidation = FormValidation(t);

  const handleOnSubmit = (): void => {
    formValidation.validate('phoneNumber', phoneNumber);
    if (formValidation.isErrorsFree()) {
      props.onComplete(`+${phoneNumber}`);
    } else {
      setFormErrors({ ...formErrors, ...formValidation.errors() });
    }
  };

  const handleChange = (value: string) => {
    setFormErrors({});
    props.errorMessage?.setErrorMessage('');
    setPhoneNumber(value);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#ffffff'
      }}
    >
      <Stack spacing={1}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Typography
            sx={{
              fontSize: '18px',
              color: 'text.primary',
              paddingTop: '35px',
              marginRight: '10px'
            }}
          >
            {t('mfa.phoneNumberLabel')}
          </Typography>

          <TPOToolTipIcon
            toolTipHeader={t('mfa.phoneNumberLabel')}
            toolTipText={t('mfa.phoneNumberToolTip')}
          />
        </Box>

        <PhoneInput
          inputStyle={{
            width: '540px',
            height: '56px'
          }}
          dropdownStyle={{ width: '540px' }}
          country={'us'}
          preferredCountries={['us', 'ca', 'gb']}
          value={phoneNumber}
          onChange={handleChange}
          inputProps={{
            name: 'phone',
            required: true,
            autoFocus: true
          }}
        />

        {(hasContent(formErrors.phoneNumber) || props.errorMessage?.errorMessage) && (
          <Typography
            sx={{
              fontSize: '18px',
              color: tpoErrorColor,
              paddingTop: '10px'
            }}
          >
            {formErrors.phoneNumber || props.errorMessage?.errorMessage}
          </Typography>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '35px',
            alignItems: 'center'
          }}
        >
          <TPOFormButton text="Get one time code" onClick={() => handleOnSubmit()}></TPOFormButton>
        </Box>
      </Stack>
    </Box>
  );
};

export default SetupPhoneNumberMFA;
