import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import TPOPaper from '../../../../components/TPOPaper/TPOPaper';
import SecondaryTabButton from '../../TabButton/SecondaryTabButton';
import { ReportContext } from '../../../../common/reportContext';
import BankBalancesTable from './BalancesTables/BankBalancesTable';

enum AccountAnalysisTabState {
  AnnualBalances,
  PeriodicBalances
}

interface AnnualBalancesTableProps {
  selectedAccountGroupIndex: number;
}

const AnnualBalancesTable = (props: AnnualBalancesTableProps) => {
  const { t } = useTranslation();
  const { report } = useContext(ReportContext);
  const [tabState, setTabState] = useState(AccountAnalysisTabState.AnnualBalances);
  const accountGroup = report.accountOptimization.groups[props.selectedAccountGroupIndex];

  const getBankGroupStartDate = (): string => {
    const date = new Date(accountGroup.dailyAccountBalances[0].daily[0].date);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', timeZone: 'UTC' });
  };

  const getBankGroupEndDate = (): string => {
    const index = accountGroup.dailyAccountBalances[0].daily.length - 1;
    const date = new Date(accountGroup.dailyAccountBalances[0].daily[index].date);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', timeZone: 'UTC' });
  };

  const getDate = () => {
    return `(${getBankGroupStartDate()} - ${getBankGroupEndDate()})`;
  };

  const getAccountGroupAnnualBalanceDateRange = (): string => {
    if (accountGroup.annualBalances.dateRange) {
      const startDate = new Date(
        accountGroup.annualBalances.dateRange.startDate
      ).toLocaleDateString('en-US', { year: 'numeric', month: 'short', timeZone: 'UTC' });
      const endDate = new Date(accountGroup.annualBalances.dateRange.endDate).toLocaleDateString(
        'en-US',
        { year: 'numeric', month: 'short', timeZone: 'UTC' }
      );
      return `(${startDate} - ${endDate})`;
    }
    return getDate();
  };

  const getAccountGroupPeriodicBalanceDateRange = (): string => {
    if (accountGroup.annualBalances.dateRange) {
      const startDate = new Date(
        accountGroup.periodicBalances.dateRange.startDate
      ).toLocaleDateString('en-US', { year: 'numeric', month: 'short', timeZone: 'UTC' });
      const endDate = new Date(accountGroup.periodicBalances.dateRange.endDate).toLocaleDateString(
        'en-US',
        { year: 'numeric', month: 'short', timeZone: 'UTC' }
      );
      return `(${startDate} - ${endDate})`;
    }
    return getDate();
  };

  return (
    <TPOPaper color="rgba(70, 0, 116, 0.3)">
      <Box paddingBottom={'20px'}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
          marginBottom={'20px'}
        >
          <SecondaryTabButton
            isActive={tabState == AccountAnalysisTabState.AnnualBalances}
            onClick={() => setTabState(AccountAnalysisTabState.AnnualBalances)}
            text={t('cashVestAnalyses.accountOptimization.bankByBankAnnualBalancesTitle', {
              date: getAccountGroupAnnualBalanceDateRange()
            })}
          />
          <SecondaryTabButton
            isActive={tabState == AccountAnalysisTabState.PeriodicBalances}
            onClick={() => setTabState(AccountAnalysisTabState.PeriodicBalances)}
            text={t('cashVestAnalyses.accountOptimization.bankByBankPeriodBalancesTitle', {
              date: getAccountGroupPeriodicBalanceDateRange()
            })}
          />
        </Box>

        {tabState == AccountAnalysisTabState.AnnualBalances && (
          <BankBalancesTable
            selectedAccountGroupIndex={props.selectedAccountGroupIndex}
            isAnnual={true}
          />
        )}
        {tabState == AccountAnalysisTabState.PeriodicBalances && (
          <BankBalancesTable
            selectedAccountGroupIndex={props.selectedAccountGroupIndex}
            isAnnual={false}
          />
        )}
      </Box>
    </TPOPaper>
  );
};

export default AnnualBalancesTable;
