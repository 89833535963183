import React, { useContext, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import TreasuryServicesTable from './TreasuryServicesTable';
import LineItemFees from './LineItemFees/LineItemFees';
import { CommentsContext } from '../../../common/commentsContext';
import EditTextField from '../../../components/EditTextField/EditTextField';

interface TreasuryServicesTabProps {
  selectedAccountGroupIndex: number;
  tabState: number;
  children?: ReactElement;
}

const TreasuryServicesTab = (props: TreasuryServicesTabProps) => {
  const { t } = useTranslation();
  const { comments, setComments } = useContext(CommentsContext);

  const getTabSummary = () => {
    let summary = '';
    let setSummary = (text: string) => {};
    if (props.tabState === 0) {
      summary = comments.treasuryServices.balanceSummary;
      setSummary = (text: string) => {
        const newComments = { ...comments };
        newComments.treasuryServices.balanceSummary = text;
        setComments(newComments);
      };
    }
    if (props.tabState === 1) {
      summary = comments.treasuryServices.compensatingOffset;
      setSummary = (text: string) => {
        const newComments = { ...comments };
        newComments.treasuryServices.compensatingOffset = text;
        setComments(newComments);
      };
    }
    if (props.tabState === 2) {
      summary = comments.treasuryServices.ecr;
      setSummary = (text: string) => {
        const newComments = { ...comments };
        newComments.treasuryServices.ecr = text;
        setComments(newComments);
      };
    }
    if (props.tabState === 3) {
      summary = comments.treasuryServices.lineItem;
      setSummary = (text: string) => {
        const newComments = { ...comments };
        newComments.treasuryServices.lineItem = text;
        setComments(newComments);
      };
    }
    return { summary, setSummary };
  };

  const { summary, setSummary } = getTabSummary();

  return (
    <Stack spacing={2} padding={3}>
      <Stack spacing={2}>
        <Typography
          sx={{
            color: 'text.primary',
            fontSize: '18px',
            fontWeight: 650
          }}
        >
          {t('common.cashVestAnalysisHeading')}
        </Typography>
        <EditTextField text={summary} setText={setSummary} />
      </Stack>
      {props.tabState === 3 ? (
        <LineItemFees selectedAccountGroupIndex={props.selectedAccountGroupIndex} />
      ) : (
        <TreasuryServicesTable
          tabState={props.tabState}
          selectedAccountGroupIndex={props.selectedAccountGroupIndex}
        />
      )}
      {props.children}
    </Stack>
  );
};

export default TreasuryServicesTab;
