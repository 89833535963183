import { Button } from '@mui/material';
import React from 'react';

interface NavbarSecondaryButtonProps {
  text: string;
  onClick: () => void;
}

const NavbarSecondaryButton = (props: NavbarSecondaryButtonProps) => {
  return (
    <Button
      onClick={props.onClick}
      sx={{
        textTransform: 'none',
        color: 'primary.main',
        borderRadius: 0,
        marginLeft: 1,
        fontSize: '18px',
        borderBottom: '3px solid #00000000'
      }}
    >
      {props.text}
    </Button>
  );
};

export default NavbarSecondaryButton;
