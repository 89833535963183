import { InvestmentType } from '../../../networking/accounts/investments/InvestmentType.types';
import { FormInvestment, Investment } from '../AddInvestmentDrawer.types';
import dayjs, { Dayjs } from 'dayjs';

export const MOCK_DATE = '10/10/2020';
export const MOCK_DATE_MATURITY = '11/11/2030';
export const MOCK_INVALID_DATE_MATURITY = '09/09/2020';
export const MOCK_INVALID_INTEREST_RATE = '-130';

export const MOCK_ACCOUNT_NAMES = ['accountNumber 1', 'accountNumber 2'];
export const MOCK_INVESTMENT_TYPES: InvestmentType[] =  [
  {typeId: 1, typeName: 'Investment type 1' },
  {typeId: 2, typeName: 'Investment type 2' },
  {typeId: 3, typeName: 'Investment type 2' }
];
export const MOCK_INVESTMENT_TYPE_IDS = [1,2,3];
export const MOCK_INVESTMENT_TYPES_GROUP2 = [+MOCK_INVESTMENT_TYPE_IDS[1]];
export const MOCK_INVESTMENT_TYPES_GROUP3 = [+MOCK_INVESTMENT_TYPE_IDS[2]];

export const MOCK_FORM_INVESTMENT: FormInvestment = {
  accountNumber: MOCK_ACCOUNT_NAMES[0],
  investmentTypeId: MOCK_INVESTMENT_TYPE_IDS[0],
  purchaseDate: dayjs(MOCK_DATE),
  purchaseAmount: '100',
  faceValue: '200',
  maturityDate: dayjs(MOCK_DATE_MATURITY),
  couponRate: '',
  callDate: dayjs(MOCK_DATE),
  interestRate: '2',
  investmentId: '',
  fund: ''
};

export const MOCK_FORM_INVESTMENT_INTEREST_OVER_20: FormInvestment = {
  accountNumber: MOCK_ACCOUNT_NAMES[0],
  investmentTypeId: MOCK_INVESTMENT_TYPE_IDS[1],
  purchaseDate: dayjs(MOCK_DATE),
  purchaseAmount: '100',
  faceValue: '200',
  maturityDate: dayjs(MOCK_DATE_MATURITY),
  couponRate: '',
  callDate: dayjs(MOCK_DATE),
  interestRate: '55',
  investmentId: '',
  fund: ''
};

export const MOCK_FORM_INVESTMENT_NEGATIVE_INTEREST_RATE: FormInvestment = {
  accountNumber: MOCK_ACCOUNT_NAMES[0],
  investmentTypeId: MOCK_INVESTMENT_TYPE_IDS[1],
  purchaseDate: dayjs(MOCK_DATE),
  purchaseAmount: '100',
  faceValue: '200',
  maturityDate: dayjs(MOCK_DATE_MATURITY),
  couponRate: '',
  callDate: dayjs(MOCK_DATE),
  interestRate: '-60',
  investmentId: '',
  fund: ''
};

/*
 * Transform values retrieved from the investment form to match the api contract
 */
const transformValues = ({
  purchaseDate,
  maturityDate,
  callDate,
  couponRate,
  interestRate,
  purchaseAmount,
  faceValue,
  investmentTypeId,
  ...otherValues
}: FormInvestment): Investment => {
  const toDate = (date: Dayjs) => date.toISOString().slice(0, 10);

  return {
    interestRate: +interestRate,
    purchaseDate: toDate(purchaseDate as Dayjs),
    maturityDate: toDate(maturityDate as Dayjs),
    callDate: callDate ? toDate(callDate) : null,
    couponRate: +couponRate,
    purchaseAmount: +purchaseAmount,
    faceValue: +faceValue,
    investmentTypeId: +investmentTypeId,
    ...otherValues
  };
};

const MOCK_INVESTMENTS = [transformValues(MOCK_FORM_INVESTMENT)];

export default MOCK_INVESTMENTS;
