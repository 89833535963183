import React, { useContext } from 'react';
import TPOPaper from '../../../components/TPOPaper/TPOPaper';
import { Typography, Box } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { ReportContext } from '../../../common/reportContext';
import { repeatValue } from '../../../common/helpers';
import { pointRadius, pointStyle } from './scorecardChartPoint';

const options = {
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      usePointStyle: true,
      boxPadding: 5
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      grid: {
        display: false
      },
      suggestedMax: 100,
      suggestedMin: 75
    }
  }
};

const ScorecardChart = () => {
  const { t } = useTranslation();
  const { report } = useContext(ReportContext);

  const data = {
    labels: report.scorecard.scoreHistory.map((scoreHistory) =>
      new Date(scoreHistory.date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'UTC'
      })
    ),
    datasets: [
      {
        data: report.scorecard.scoreHistory.map((scoreHistory) => scoreHistory.score),
        showLine: false,
        pointBackgroundColor: '#460074',
        pointRadius: pointRadius(report.scorecard.scoreHistory.length),
        pointStyle: pointStyle(report.scorecard.scoreHistory)
      },
      {
        data: repeatValue(report.scorecard.scoreHistory.length, 90),
        borderColor: 'black',
        pointRadius: 0,
        hitRadius: 0
      }
    ]
  };

  return (
    <TPOPaper color="rgba(70, 0, 116, 0.3)">
      <Box
        sx={{
          padding: 3,
          width: '600px'
        }}
      >
        <Typography
          sx={{
            fontSize: '32px',
            color: 'text.primary',
            textAlign: 'center'
          }}
        >
          cashVest Score History
        </Typography>

        <Line data={data} options={options} redraw />

        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '12px',
            fontWeight: 550
          }}
        >
          {t('cashVestAnalyses.scoreCard.chartXLegend')}
        </Typography>
      </Box>
    </TPOPaper>
  );
};

export default React.memo(ScorecardChart);
