import { currencyFormatting, numberFormatting, rateFormatting } from '../../../../common/helpers';
import { LineItem, FormattedLineItem, LineItemSubcategory } from './LineItemFees.types';

const totalCostFormatting = (item: LineItem, sumAllCosts: number): string => {
  let categoryTotal = 0;
  item.subcategory.forEach((subcategory) => {
    categoryTotal += subcategory.cost;
  });
  const percentage = rateFormatting((categoryTotal / sumAllCosts) * 100, 1, 1);

  return `${currencyFormatting(categoryTotal)} (${percentage})`;
};

export const createLineItemFeesContent = (lineItemFees: LineItem[]): FormattedLineItem[] => {
  const sumAllCosts = lineItemFees.reduce((total: number, lineItem: LineItem) => {
    lineItem.subcategory.forEach((subcategory) => {
      total += subcategory.cost;
    });
    return total;
  }, 0);

  return lineItemFees.map((item: LineItem) => ({
    category: item.category,
    totalCosts: totalCostFormatting(item, sumAllCosts),
    subcategory: item.subcategory.map((subcategory) => [
      subcategory.subcategory,
      subcategory.priceType === 'REPORTED' ? currencyFormatting(subcategory.unitPrice, 2) : '',
      subcategory.priceType === 'REPORTED' ? numberFormatting(subcategory.volume) : '',
      subcategory.costType === 'CHARGED' ? currencyFormatting(subcategory.cost) : 'WAIVED'
    ]),
    showToolTip:
      item.category === 'Reporting & Maintenance' || item.category === 'Balance Related Fees',
    // TODO: change the way this tooltip content is handled.
    toolTipContent:
      item.category === 'Reporting & Maintenance'
        ? 'Reporting and maintenance fees are overhead fees not related to a single specific service. They include things like online reporting access, fraud protection, paper documents, etc.'
        : 'Balance related fees include FDIC charges, interest expense, and negative balance charges.  It should be noted that FDIC charges, which are not related to any additional service provided, complicate the calculation of balances required to offset fees as these fees are directly impacted by the level of balances on deposit.'
  }));
};
